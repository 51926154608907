/* eslint-disable */
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import Grid from "@mui/material/Grid";
// import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SanxuLogo from "../../../../../assets/images/logos/sanxu-logo-fc.png";
import moment from "moment";
import "moment/locale/id"; // without this line it didn't work

moment.locale("id");

const printingPageStyle = `
  @page {
    size: 8in 11in;
    margin: 0.n;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }
  
  @media print {
    .pagebreak {
      page-break-before: always;
    }

    @page {
      size: 8in 11in;
      margin: 0.25in;
    }
  }
`;

const ReturnToSupplierReceiptPrint = React.forwardRef(({ dataContent }, ref) => (
  <div style={{ width: "100%" }} ref={ref}>
    <style>{`
    table.print-table { 
        border-spacing: 0;
        border-collapse: collapse;
        font-size: 0.5em;
    }

    td.print-table{
     border:1px solid black;
     margin: 0.5rem;
     padding: 0.5rem;
    }
  `}</style>
    <div style={{ width: "100%" }}>
      <MDBox
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", flexWrap: "wrap" }}
        mb={1}
      >
        <MDBox disply="flex" justifyContent="center" alignItems="center" sx={{ flex: "0 0 100%", marginButtom: "1rem" }}>
          <MDTypography align="center" variant="h5" fontWeight="medium">
            NOTA RETUR
          </MDTypography>
        </MDBox>
        <MDBox disply="flex" justifyContent="center" alignItems="center" sx={{ flex: "0 0 50%" }}>
          <img style={{ marginLeft: "10%" }} width="35%" src={SanxuLogo} alt="logo" />
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="center"
          alignSelf="stretch"
          alignItems="flex-end"
          alignContent="flex-end"
          sx={{ flex: "0 0 50%", flexWrap: "wrap", flexDirection: "row", fontSize: "0.7em" }}
        >
          <MDBox display="flex" alignItems="center" sx={{ flex: "0 0 100%" }}>
            <MDTypography
              variant="span"
              fontWeight="medium"
              flexDirection="column"
              sx={{ flex: "0 0 25%" }}
            >
              {`${"No. Nota".padEnd(20)}`}
            </MDTypography>
            <MDTypography
              variant="span"
              fontWeight="medium"
              flexDirection="column"
              sx={{ flex: "0 0 3%" }}
              textAlign="center"
            >
              :
            </MDTypography>
            <MDTypography variant="span" fontWeight="medium" flexDirection="column" flexGrow={1}>
              {dataContent?.transactionId}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" alignItems="center" sx={{ flex: "0 0 100%" }}>
            <MDTypography
              variant="span"
              fontWeight="medium"
              flexDirection="column"
              sx={{ flex: "0 0 25%" }}
            >
              {`${"Hari/Tanggal".padEnd(20)}`}
            </MDTypography>
            <MDTypography
              variant="span"
              fontWeight="medium"
              flexDirection="column"
              sx={{ flex: "0 0 3%" }}
              textAlign="center"
            >
              :
            </MDTypography>
            <MDTypography variant="span" fontWeight="medium" flexDirection="column" flexGrow={1}>
              {moment().format("dddd, DD MMMM YYYY HH:mm:ss")}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" alignItems="center" sx={{ flex: "0 0 100%" }}>
            <MDTypography
              variant="span"
              fontWeight="medium"
              flexDirection="column"
              sx={{ flex: "0 0 25%" }}
            >
              {`${"Kepada".padEnd(20)}`}
            </MDTypography>
            <MDTypography
              variant="span"
              fontWeight="medium"
              flexDirection="column"
              sx={{ flex: "0 0 3%" }}
              textAlign="center"
            >
              :
            </MDTypography>
            <MDTypography variant="span" fontWeight="medium" flexDirection="column" flexGrow={1}>
              {dataContent?.supplier?.supplier_name || ""}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" alignItems="center" sx={{ flex: "0 0 100%" }}>
            <MDTypography
              variant="span"
              fontWeight="medium"
              flexDirection="column"
              sx={{ flex: "0 0 25%" }}
            >
              {`${"Dibuat Oleh".padEnd(20)}`}
            </MDTypography>
            <MDTypography
              variant="span"
              fontWeight="medium"
              flexDirection="column"
              sx={{ flex: "0 0 3%" }}
              textAlign="center"
            >
              :
            </MDTypography>
            <MDTypography variant="span" fontWeight="medium" flexDirection="column" flexGrow={1}>
              {dataContent?.operator}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" alignItems="center" sx={{ flex: "0 0 100%" }}>
            <MDTypography
              variant="span"
              fontWeight="medium"
              flexDirection="column"
              sx={{ flex: "0 0 25%" }}
            >
              {`${"Jumlah".padEnd(20)}`}
            </MDTypography>
            <MDTypography
              variant="span"
              fontWeight="medium"
              flexDirection="column"
              sx={{ flex: "0 0 3%" }}
              textAlign="center"
            >
              :
            </MDTypography>
            <MDTypography variant="span" fontWeight="medium" flexDirection="column" flexGrow={1}>
              {`${dataContent?.items?.reduce((a, b) => a + b.qty_to_return, 0)} pcs (${dataContent?.items?.length} jenis)`}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" sx={{ flex: "0 0 100%", marginTop: "10px" }}>
          <table className="print-table" style={{ width: "100%" }}>
            <thead>
              <tr>
                <td className="print-table" colSpan="1">
                  No.
                </td>
                <td className="print-table" colSpan="2">
                  Nota Pembelian
                </td>
                <td className="print-table" colSpan="6">
                  Product Name
                </td>
                <td className="print-table" colSpan="3">
                  Variant
                </td>
                <td className="print-table" colSpan="2">
                  Qty
                </td>
              </tr>
            </thead>
            <tbody>
              {dataContent.items.map((x, i) => (
                <tr key={`item-${x?.item_id}`}>
                  <td className="print-table" colSpan={1}>
                    {i + 1}
                  </td>
                  <td className="print-table" colSpan={2}>
                    {x?.receipt_id}
                  </td>
                  <td className="print-table" colSpan={6}>
                    {x?.product_name}
                  </td>
                  <td className="print-table" colSpan={3}>
                    {x?.stock_tags}
                  </td>
                  <td className="print-table" colSpan={2}>{`${x?.qty_to_return} pcs`}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </MDBox>
      </MDBox>
    </div>
  </div>
));

const useReturnToSupplierPrint = (dataContent) => {
  const toBePrintedRef = useRef();
  const handlePrint = useReactToPrint({
    pageStyle: printingPageStyle,
    content: () => toBePrintedRef.current,
  });
  const ThingToPrint = (
    <ReturnToSupplierReceiptPrint dataContent={dataContent} ref={toBePrintedRef} />
  );

  return {
    ThingToPrint,
    handlePrint,
  };
};

useReturnToSupplierPrint.propTypes = {
  dataContent: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.string, PropTypes.number, PropTypes.objectOf(PropTypes.any)])
  ).isRequired,
};

ReturnToSupplierReceiptPrint.propTypes = {
  dataContent: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.string, PropTypes.number, PropTypes.objectOf(PropTypes.any)])
  ).isRequired,
};

export default useReturnToSupplierPrint;

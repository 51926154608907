/* eslint-disable react/prop-types */

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { NumericFormat } from "react-number-format";
// import moment from "moment";

export const ReceiptDataRow = ({
  // eslint-disable-next-line no-unused-vars
  id,
  receipt_id: receiptProductName,
  supplier_name: supplierName,
  date: receiptDate,
  payment_status: paymentStatus,
  receipt_note: receiptNote,
  total_amount: totalAmount,
  onRowDelete,
  // updated_at: updatedAt,
}) => ({
  id: {
    value: id,
    hidden: true,
  },
  receipt_name: {
    value: receiptProductName,
    hidden: true,
  },
  receiptTotalAmount: {
    value: totalAmount,
    hidden: true,
  },
  receipt_id: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        {receiptProductName}
      </MDTypography>
    </MDBox>
  ),
  supplier_name: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        {supplierName}
      </MDTypography>
    </MDBox>
  ),
  date: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        {receiptDate}
      </MDTypography>
    </MDBox>
  ),
  payment_status: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        {paymentStatus}
      </MDTypography>
    </MDBox>
  ),
  total_amount: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        <NumericFormat
          displayType="text"
          value={totalAmount}
          thousandSeparator="."
          decimalSeparator=","
          prefix="Rp "
        />
      </MDTypography>
    </MDBox>
  ),
  receipt_note: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        {receiptNote}
      </MDTypography>
    </MDBox>
  ),
  actions: (
    <MDButton
      iconOnly
      color="error"
      onClick={
        onRowDelete &&
        ((e) => {
          e.stopPropagation();
          onRowDelete(id, receiptProductName);
        })
      }
      variant="outlined"
    >
      <Icon fontSize="small" component="i" color="error">
        delete
      </Icon>
    </MDButton>
  ),
});

export default function data() {
  const Item = ({ name }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Receipt ID", accessor: "receipt_id", align: "left", width: "20%" },
      {
        Header: "Supplier",
        accessor: "supplier_name",
        align: "left",
        width: "15%",
        disableSortBy: true,
      },
      { Header: "Receipt Date", accessor: "date", align: "left", width: "15%" },
      {
        Header: "Total Amount",
        accessor: "total_amount",
        align: "left",
        width: "20%",
        disableSortBy: true,
      },
      {
        Header: "Payment Status",
        accessor: "payment_status",
        align: "left",
        width: "15%",
        disableSortBy: true,
      },
      {
        Header: "Notes",
        accessor: "receipt_note",
        align: "left",
        width: "15%",
        disableSortBy: true,
      },
      {
        Header: "Actions",
        accessor: "actions",
        align: "left",
        width: "15%",
        disableSortBy: true,
      },
      // { Header: "Status", accessor: "status", align: "left" },
      // { Header: "Updated At", accessor: "updated_at", align: "left" },
      // { Header: "Action", accessor: "action", align: "left" },
    ],

    rows: [
      {
        item_name: <Item name="Item1" />,
        category: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Category1
          </MDTypography>
        ),
        supplier_code: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            SPL1
          </MDTypography>
        ),
        sku_code: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            SKU1
          </MDTypography>
        ),
        supplier_name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            100000
          </MDTypography>
        ),
        date: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            100000
          </MDTypography>
        ),
        status: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            active
          </MDTypography>
        ),
        updated_at: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            11-01-2022 13:37:50
          </MDTypography>
        ),
        action: (
          <MDBox>
            <MDButton iconOnly color="info" href="./warehouse/warehouse-edit" variant="outlined">
              <Icon fontSize="small" component="a" color="info">
                edit
              </Icon>
            </MDButton>
            <MDButton iconOnly color="error" href="./warehouse/warehouse-edit" variant="outlined">
              <Icon fontSize="small" component="a" color="error">
                delete
              </Icon>
            </MDButton>
          </MDBox>
        ),
      },
      {
        item_name: <Item name="Item2" />,
        category: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Category2
          </MDTypography>
        ),
        supplier_code: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            SPL2
          </MDTypography>
        ),
        sku_code: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            SKU2
          </MDTypography>
        ),
        supplier_name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            120000
          </MDTypography>
        ),
        date: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            120000
          </MDTypography>
        ),
        status: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            active
          </MDTypography>
        ),
        updated_at: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            12-01-2022 09:11:03
          </MDTypography>
        ),
        action: (
          <MDBox>
            <MDButton iconOnly color="info" href="./warehouse/warehouse-edit" variant="outlined">
              <Icon fontSize="small" component="a" color="info">
                edit
              </Icon>
            </MDButton>
            <MDButton iconOnly color="error" href="./warehouse/warehouse-edit" variant="outlined">
              <Icon fontSize="small" component="a" color="error">
                delete
              </Icon>
            </MDButton>
          </MDBox>
        ),
      },
    ],
  };
}

/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Date
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// snackbar
import { Alert, Snackbar, OutlinedInput, DialogTitle } from "@mui/material";

// Dialog/Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Icon from "@mui/material/Icon";

import IconButton from "@mui/material/IconButton";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import Modal from "@mui/material/Modal";
import _ from "lodash";

// Data
import DataTable from "examples/Tables/DataTable";
import ReceiptData from "layouts/warehouse/receipt/data/receiptData";
import RemoteTable from "components/RemoteTable";
import useAuthorizedRequest from "components/AuthorizedRequest";
import FullScreenSpinner from "components/FullScreenSpinner";
import MDAlertCloseIcon from "components/MDAlert/MDAlertCloseIcon";
import { ReceiptDataRow } from "./data/receiptData";
import "../../../assets/styles/style.css";
import useStockQRPrint from "../../stock/qr-print";

function Receipt() {
  const abortController = new AbortController();
  const navigate = useNavigate();
  const { columns: pColumns } = ReceiptData();
  const authorizedReq = useAuthorizedRequest();
  const [refetchToken, setRefetchToken] = useState(null);

  const [receiptData, setReceiptData] = useState([]);
  const [receiptShow, setReceiptShow] = useState([]);
  const [receiptDetails, setReceiptDetails] = useState([]);
  const [onRowClickCount, setOnRowClickCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // for excel export report
  const receiptColumnData = [
    { Header: "Stock Name", accessor: "product_name", width: "30%" },
    { Header: "Stock Tags", accessor: "stock_tags", width: "10%" },
    { Header: "Quantity Bought", accessor: "qty_bought", width: "20%" },
    { Header: "Quantity Left", accessor: "qty_left", width: "20%" },
    { Header: "Buy Price", accessor: "buy_price", width: "10%" },
    { Header: "Action", accessor: "action", width: "10%" },
  ];

  const [qrPrintData, setQrPrintData] = useState({});
  const { ThingToPrint, handlePrint } = useStockQRPrint(qrPrintData);

  const printQR = (itemId, prodName, prodTags, receiptId) => {
    setQrPrintData({
      item_id: itemId,
      product_name: prodName,
      tags: prodTags,
      receipt_id: receiptId,
    });
  };

  const [openReceiptDialog, setOpenReceiptDialog] = useState(false);
  const receiptDialogTheme = useTheme();
  const receiptDialogFullScreen = useMediaQuery(receiptDialogTheme.breakpoints.down("md"));

  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [inputStockValue, setInputStockValue] = useState("");
  const [fetchedStock, setFetchedStock] = useState([]);
  const [selectedStock, setSelectedStock] = useState({
    stock_id: 0,
  });
  const [stockBuyPrice, setStockBuyPrice] = useState("");

  const [itemId, setItemId] = useState("");
  const [amendData, setAmendData] = useState({
    stock_id: 0,
    buy_price: 0,
  });

  const [openEditReceiptInfoDialog, setOpenEditReceiptInfoDialog] = useState(false);
  const [selectedEditReceiptDate, setSelectedEditReceiptDate] = useState("");

  const [inputSupplier, setInputSupplier] = useState("");
  const [fetchedSupplier, setFetchedSupplier] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState({
    supplier_id: 0,
  });

  const [selectedEditReceipt, setSelectedEditReceipt] = useState({
    supplier_id: 0,
    supplier_name: "",
    receipt_id: 0,
    receipt_code: "",
    receipt_date: "",
    payment_status: 0,
  });

  const [receiptChosedToDelete, setReceiptChosedToDelete] = useState({
    id: null,
    receiptId: null,
  });

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleOpenEditDialog = (
    stockIdChoosed,
    productNameChoosed,
    itemIdChoosed,
    stockBuyPriceChoosed
  ) => {
    setOpenEditDialog(true);
    setSelectedStock({
      stock_id: stockIdChoosed,
      product_name: productNameChoosed,
    });
    setStockBuyPrice(stockBuyPriceChoosed);
    setItemId(itemIdChoosed);
  };

  const getSupplierId = (supplierName) => {
    let active = true;
    let errMessage = "";
    if (supplierName === "") return undefined;
    _.debounce(() => {
      authorizedReq
        .get(`/supplier?search=${supplierName}`, {
          signal: abortController.signal,
        })
        .then((res) => {
          if (active) {
            setSelectedSupplier({
              supplier_id: res.data.data[0].id,
              supplier_name: res.data.data[0].supplier_name,
            });
          }
        })
        .catch((err) => {
          errMessage = err.cancel;
          if (errMessage);
        });
    }, 100)();
    return () => {
      abortController.abort();
      active = false;
    };
  };

  const handleCloseEditReceiptInfoDialog = () => {
    setOpenEditReceiptInfoDialog(false);
    setSelectedSupplier({
      supplier_id: 0,
    });
  };

  const handleOpenEditReceiptInfoDialog = (
    receiptIdChoosed,
    receiptCodeChoosed,
    receiptDateChoosed,
    supplierNameChoosed,
    paymentStatusChoosed
  ) => {
    setOpenEditReceiptInfoDialog(true);
    setSelectedEditReceipt({
      ...selectedEditReceipt,
      receipt_id: receiptIdChoosed,
      receipt_code: receiptCodeChoosed,
      payment_status: paymentStatusChoosed,
    });
    getSupplierId(supplierNameChoosed);
    setSelectedEditReceiptDate(receiptDateChoosed);
  };

  const [stateSnackbar, setStateSnackbar] = useState({
    openSnackbar: false,
    vertical: "top",
    horizontal: "right",
    severity: "info",
    message: "message",
  });
  const { vertical, horizontal, openSnackbar, severity, message } = stateSnackbar;

  const handleCloseSnackbar = () => {
    setStateSnackbar({ ...stateSnackbar, openSnackbar: false });
  };

  const handleRowEdit = (i) => {
    navigate(`/receipt/receipt-edit?id=${i}`);
  };

  const handleRowDelete = (i) => {
    setReceiptChosedToDelete({
      id: null,
      receiptId: null,
    });
    setIsLoading(true);

    authorizedReq
      .delete(`${process.env.REACT_APP_API_BASE_URL}/receipt/${i}`)
      .then(() => {
        setStateSnackbar({
          ...stateSnackbar,
          openSnackbar: true,
          severity: "success",
          message: "Receipt Deleted!",
        });
        setRefetchToken(Date.now());
      })
      .catch(() => {
        setIsLoading(false);
        setStateSnackbar({
          ...stateSnackbar,
          openSnackbar: true,
          severity: "error",
          message: "Delete Error",
        });
      });
    setIsLoading(false);
  };

  const handleCloseReceiptDialog = () => {
    setOpenReceiptDialog(false);
  };

  const handleOpenReceipt = (row) => {
    setOpenReceiptDialog(true);
    if (receiptShow.id !== row.original.id.value) {
      setReceiptData([]);
      setReceiptDetails([]);
    }
    setReceiptShow({
      id: row.original.id.value,
      receipt_id: row.original.receipt_name.value,
    });
  };

  const getReceipt = () => {
    if (receiptShow.receipt_id === undefined && receiptShow.id === undefined) {
      return;
    }
    authorizedReq
      .get(`${process.env.REACT_APP_API_BASE_URL}/receipt/${receiptShow.id}`)
      .then((res) => {
        setReceiptData(res.data.items);
        setReceiptDetails(res.data);
      })
      .catch(() => {
        setStateSnackbar({
          ...stateSnackbar,
          openSnackbar: true,
          severity: "error",
          message: "Get Receipt Error",
        });
      });
  };

  const queryStockReceipt = (input) => {
    authorizedReq
      .get(`/stock?search=${input}`, {
        signal: abortController.signal,
      })
      .then((res) => {
        setFetchedStock(
          res.data.data.map((x) => ({
            stock_id: x.id,
            product_name: `${x.product_name}, ${x.tags}`,
          }))
        );
      })
      .catch((err) => {
        setStateSnackbar({
          ...stateSnackbar,
          openSnackbar: false,
          severity: "error",
          message: err.cancel,
        });
      });
  };

  const handleAmendItem = () => {
    authorizedReq
      .put(`${process.env.REACT_APP_API_BASE_URL}/receipt/item/${itemId}`, amendData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(() => {
        setStateSnackbar({
          openSnackbar: true,
          severity: "success",
          message: "Item amended successfully",
          vertical: "top",
          horizontal: "right",
        });
        setRefetchToken(Date.now());
        handleCloseEditDialog();
        setOnRowClickCount(onRowClickCount + 1);
      })
      .catch((errStock) => {
        setStateSnackbar({
          openSnackbar: true,
          message: `${errStock.response.data.message}`,
          severity: "error",
          vertical: "top",
          horizontal: "right",
        });
      });
  };

  const querySupplier = () => {
    _.debounce(() => {
      authorizedReq
        .get(`/supplier?search=${inputSupplier}`, {
          signal: abortController.signal,
        })
        .then((res) => {
          setFetchedSupplier(
            res.data.data.map((x) => ({
              supplier_id: x.id,
              supplier_name: x.supplier_name,
            }))
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }, 100)();
    return () => {
      abortController.abort();
    };
  };

  const handleUpdateReceiptInfo = () => {
    authorizedReq
      .put(`${process.env.REACT_APP_API_BASE_URL}/receipt/${receiptShow.id}`, selectedEditReceipt, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(() => {
        setStateSnackbar({
          openSnackbar: true,
          severity: "success",
          message: "Receipt info updated successfully",
          vertical: "top",
          horizontal: "right",
        });
        setRefetchToken(Date.now());
        handleCloseEditDialog();
        setOnRowClickCount(onRowClickCount + 1);
      })
      .catch((errStock) => {
        setStateSnackbar({
          openSnackbar: true,
          message: `${errStock.response.data.message}`,
          severity: "error",
          vertical: "top",
          horizontal: "right",
        });
      });
  };

  const handleChangeStockBuyPrice = ({ value }) => {
    setStockBuyPrice(value);
  };

  // print stock if product name is exist
  useEffect(() => {
    if (qrPrintData.product_name) {
      handlePrint();
      setQrPrintData({});
    }
  }, [qrPrintData, ThingToPrint]);

  // query stock receipt whenever stock name is changed
  useEffect(() => {
    if (inputStockValue === "") {
      setFetchedStock(selectedStock ? [selectedStock] : [{ stock_id: 0 }]);
      return undefined;
    }
    _.debounce(() => {
      queryStockReceipt(inputStockValue);
    }, 100)();

    return () => abortController.abort();
  }, [selectedStock, inputStockValue]);

  // set Amend data props
  useEffect(() => {
    if (
      typeof selectedStock === "undefined" ||
      selectedStock?.stock_id === 0 ||
      typeof selectedStock?.stock_id === "undefined"
    ) {
      return;
    }
    setAmendData({
      stock_id: selectedStock.stock_id,
      buy_price: Number(stockBuyPrice),
    });
  }, [stockBuyPrice, selectedStock]);

  // Get Receipt whenever remote table row is clicked
  useEffect(() => {
    getReceipt();
  }, [receiptShow.receipt_id, receiptShow.id, onRowClickCount]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (inputSupplier === "") return undefined;
    querySupplier();
  }, [selectedSupplier, inputSupplier]);

  useEffect(() => {
    setSelectedEditReceipt({
      ...selectedEditReceipt,
      receipt_date: selectedEditReceiptDate,
      supplier_id: selectedSupplier?.supplier_id || 0,
      supplier_name: selectedSupplier?.supplier_name || "",
    });
  }, [selectedSupplier, inputSupplier, selectedEditReceiptDate, setSelectedEditReceipt]);

  return (
    <DashboardLayout>
      <FullScreenSpinner showOverlay={isLoading} />
      <div>
        <Modal
          open={openEditDialog}
          onClose={handleCloseEditDialog}
          aria-labelledby="modal-modal-title"
          align-self="center"
        >
          <Grid container justifyContent="center" mt={35}>
            <Grid item xs={8} lg={6}>
              <Card>
                <MDBox p={2}>
                  <MDTypography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    // color="light"
                    align="center"
                    // mb={2}
                  >
                    Edit Stock Receipt
                  </MDTypography>
                  <hr color="gray" />
                  <MDBox mb={2} mt={2}>
                    <MDTypography variant="text" align="left" fontSize="small">
                      Stock Name
                    </MDTypography>
                    <Autocomplete
                      freeSolo
                      id="combo-box-demo"
                      value={selectedStock}
                      onChange={(e, d) => setSelectedStock(d)}
                      options={fetchedStock}
                      getOptionLabel={(option) => option.product_name || ""}
                      filterSelectedOptions
                      filterOptions={(x) => x}
                      onInputChange={(event, newInputStockValue) => {
                        setInputStockValue(newInputStockValue);
                      }}
                      renderInput={(params) => <MDInput {...params} placeholder="stock name" />}
                    />
                  </MDBox>
                  <MDBox mt={2}>
                    <MDTypography variant="text" align="left" fontSize="small">
                      Buy Price
                    </MDTypography>
                    <NumericFormat
                      value={stockBuyPrice}
                      thousandSeparator="."
                      decimalSeparator=","
                      onValueChange={handleChangeStockBuyPrice}
                      customInput={OutlinedInput}
                      fullWidth
                      startAdornment={<InputAdornment position="start">Rp</InputAdornment>}
                      inputProps={{
                        maxLength: 15,
                        step: "500",
                      }}
                      // notched
                      placeholder="sell price"
                    />
                  </MDBox>
                  <Grid container justifyContent="center">
                    <Grid item xs={12} lg={12} mt={2}>
                      <MDButton variant="gradient" color="info" onClick={handleAmendItem} fullWidth>
                        Edit Stock Receipt
                      </MDButton>
                    </Grid>
                    <Grid item xs={12} lg={12} mt={2}>
                      <MDButton
                        variant="gradient"
                        color="error"
                        onClick={handleCloseEditDialog}
                        fullWidth
                      >
                        Cancel
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </Modal>
      </div>
      <div>
        <Modal
          open={openEditReceiptInfoDialog}
          onClose={handleCloseEditReceiptInfoDialog}
          aria-labelledby="modal-modal-title"
          align-self="center"
        >
          <Grid container justifyContent="center" mt={35}>
            <Grid item xs={8} lg={6}>
              <Card>
                <MDBox p={2}>
                  <MDTypography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    // color="light"
                    align="center"
                    // mb={2}
                  >
                    Edit Receipt Info
                  </MDTypography>
                  <hr color="gray" />
                  <MDBox mb={2} mt={2}>
                    <MDTypography variant="text" align="left" fontSize="small">
                      Receipt Code
                    </MDTypography>
                    <MDInput
                      value={selectedEditReceipt.receipt_code}
                      onChange={(e) =>
                        setSelectedEditReceipt({
                          ...selectedEditReceipt,
                          receipt_code: e.target.value,
                        })
                      }
                      type="text"
                      fullWidth
                      // notched
                      placeholder="receipt code"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="text" align="left" fontSize="small">
                      Date
                    </MDTypography>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        mask="__-__-____"
                        inputFormat="DD-MM-yyyy"
                        value={selectedEditReceiptDate}
                        onChange={(newValue) => {
                          setSelectedEditReceiptDate(moment(newValue).format("yyyy-MM-DD"));
                        }}
                        disableFuture
                        renderInput={(params) => (
                          <MDInput fullWidth placeholder="select date" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </MDBox>
                  <MDBox mb={2} mt={2}>
                    <MDTypography variant="text" align="left" fontSize="small">
                      Supplier Name
                    </MDTypography>
                    <Autocomplete
                      freeSolo
                      id="combo-box-demo1"
                      value={selectedSupplier}
                      onChange={(e, d) => setSelectedSupplier(d)}
                      options={fetchedSupplier}
                      getOptionLabel={(option) => option.supplier_name || ""}
                      filterSelectedOptions
                      filterOptions={(x) => x}
                      onInputChange={(event, newInputSupplierName) => {
                        setInputSupplier(newInputSupplierName);
                      }}
                      renderInput={(params) => <MDInput {...params} placeholder="stock name" />}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="text" align="left" fontSize="small">
                      Payment Status
                    </MDTypography>
                    <Select
                      labelId="status"
                      id="status"
                      value={selectedEditReceipt.payment_status}
                      label="status"
                      variant="standard"
                      defaultValue=""
                      onChange={(e) =>
                        setSelectedEditReceipt({
                          ...selectedEditReceipt,
                          payment_status: e.target.value,
                        })
                      }
                      fullWidth
                    >
                      <MenuItem value="paid">Paid</MenuItem>
                      <MenuItem value="unpaid">Unpaid</MenuItem>
                    </Select>
                  </MDBox>
                  <Grid container justifyContent="center">
                    <Grid item xs={12} lg={12} mt={2}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={handleUpdateReceiptInfo}
                        fullWidth
                        // disabled
                      >
                        Update Receipt Info
                      </MDButton>
                    </Grid>
                    <Grid item xs={12} lg={12} mt={2}>
                      <MDButton
                        variant="gradient"
                        color="error"
                        onClick={handleCloseEditReceiptInfoDialog}
                        fullWidth
                      >
                        Cancel
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </Modal>
      </div>
      <div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={openSnackbar}
          autoHideDuration={5000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </div>
      <Dialog
        fullScreen={receiptDialogFullScreen}
        open={receiptChosedToDelete?.receiptId !== null}
        onClose={() => {
          setReceiptChosedToDelete({ ...receiptChosedToDelete, receiptId: null });
          setRefetchToken(Date.now());
        }}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle id="responsive-dialog-title">
          Receipt Delete {receiptChosedToDelete?.receiptId}
          <IconButton
            style={{ position: "absolute", right: 8, top: 8 }}
            aria-label="close"
            onClick={() => {
              setReceiptChosedToDelete(null);
              setRefetchToken(Date.now());
            }}
          >
            <MDAlertCloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <span>Are you sure you want to delete this receipt?</span>
        </DialogContent>
        <DialogActions>
          <MDButton
            onClick={() => handleRowDelete(receiptChosedToDelete?.id)}
            variant="outlined"
            color="error"
          >
            Yes
          </MDButton>
          <MDButton
            autoFocus
            onClick={() => {
              setReceiptChosedToDelete({ ...receiptChosedToDelete, receiptId: null });
              setRefetchToken(Date.now());
            }}
            variant="outlined"
            color="secondary"
          >
            No
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={receiptDialogFullScreen}
        open={openReceiptDialog}
        onClose={handleCloseReceiptDialog}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
        fullWidth
      >
        {/* <DialogTitle id="responsive-dialog-title">{receiptShow.receipt_id}</DialogTitle> */}
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12} align="center">
              <MDBox mt={4} mb={1}>
                <MDBox display="flex" alignItems="center">
                  <MDTypography display="block" variant="button" fontWeight="regular">
                    Receipt Code : {receiptDetails.receipt_code}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItems="center">
                  <MDTypography display="block" variant="button" fontWeight="regular">
                    Receipt Date : {receiptDetails.date}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItems="center">
                  <MDTypography display="block" variant="button" fontWeight="regular">
                    Supplier Name : {receiptDetails.supplier_name}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItems="center">
                  <MDTypography display="block" variant="button" fontWeight="regular">
                    Payment Status : {receiptDetails.payment_status}
                  </MDTypography>
                </MDBox>
                <br />
                <DataTable
                  table={{
                    columns: receiptColumnData,
                    rows: receiptData.map((x) => ({
                      product_name: (
                        <MDBox display="flex" alignItems="center">
                          <MDTypography display="block" variant="text" fontWeight="regular">
                            {x.product_name}
                          </MDTypography>
                        </MDBox>
                      ),
                      stock_tags: (
                        <MDBox display="flex" alignItems="center">
                          <MDTypography display="block" variant="text" fontWeight="regular">
                            {x.stock_tags}
                          </MDTypography>
                        </MDBox>
                      ),
                      qty_bought: (
                        <MDBox display="flex" alignItems="center">
                          <NumericFormat
                            displayType="text"
                            value={x.qty_bought}
                            thousandSeparator="."
                            decimalSeparator=","
                            suffix=" pcs"
                          />
                        </MDBox>
                      ),
                      qty_left: (
                        <MDBox display="flex" alignItems="center">
                          <NumericFormat
                            displayType="text"
                            value={x.qty_left}
                            thousandSeparator="."
                            decimalSeparator=","
                            suffix=" pcs"
                          />
                        </MDBox>
                      ),
                      buy_price: (
                        <MDBox display="flex" alignItems="center">
                          <NumericFormat
                            displayType="text"
                            value={x.buy_price}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="Rp "
                          />
                        </MDBox>
                      ),
                      action: (
                        <MDBox>
                          <MDButton
                            iconOnly
                            color="info"
                            onClick={() =>
                              handleOpenEditDialog(
                                x.stock_id,
                                `${x.product_name}, ${x.stock_tags}`,
                                x.item_id,
                                x.buy_price
                              )
                            }
                            variant="outlined"
                            disabled={x.qty_left === 0}
                          >
                            <Icon fontSize="small" component="i" color="info">
                              edit
                            </Icon>
                          </MDButton>
                          <MDButton
                            iconOnly
                            color="error"
                            onClick={() =>
                              printQR(
                                x.item_id,
                                x.product_name,
                                x.stock_tags,
                                receiptDetails.receipt_code
                              )
                            }
                            variant="outlined"
                            disabled={x.qty_left === 0}
                          >
                            <Icon fontSize="small" component="i" color="error">
                              print
                            </Icon>
                          </MDButton>
                        </MDBox>
                      ),
                    })),
                  }}
                  // showTotalAmount
                  // amountTotal={receiptAmountTotal}
                  isSorted={false}
                />
              </MDBox>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton
            autoFocus
            onClick={() =>
              handleOpenEditReceiptInfoDialog(
                receiptShow.id,
                receiptDetails.receipt_code,
                receiptDetails.date,
                receiptDetails.supplier_name,
                receiptDetails.payment_status
              )
            }
            variant="outlined"
            color="info"
          >
            Edit Receipt Info
          </MDButton>
          <MDButton autoFocus onClick={handleCloseReceiptDialog} variant="outlined" color="error">
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
      <DashboardNavbar />
      {/* This is hidden on purpose } */}
      <div style={{ display: "none" }}>{ThingToPrint}</div>
      {/* {TODO this button is to be removed} */}
      {/* <MDButton onClick={() => printQR("test", "test1, test2")}>Print</MDButton> */}
      <MDBox pt={4} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item xs={12} lg={8} mt={2}>
                    <MDTypography variant="h6" color="white">
                      Receipt
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} lg={2} mb={2} mt={2} align="right">
                    <MDButton
                      component="a"
                      // color="success"
                      onClick={() => navigate("/warehouse/receipt/return/")}
                      sx={{ display: { xs: "block", lg: "inline" } }}
                    >
                      Return Item
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} lg={2} mb={2} mt={2} align="right">
                    <MDButton
                      component="a"
                      // color="success"
                      onClick={() => navigate("/warehouse/receipt/input-receipt")}
                      sx={{ display: { xs: "block", lg: "inline" } }}
                    >
                      Input Receipt
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <RemoteTable
                  columnData={pColumns}
                  // isSorted
                  queryArgs={{
                    queryKey: "/receipt",
                    defaultSortKey: "date",
                    sortDir: "desc",
                  }}
                  refetchToken={refetchToken}
                  onRowDelete={(id, receiptId) =>
                    setReceiptChosedToDelete({
                      id,
                      receiptId,
                    })
                  }
                  onRowEdit={handleRowEdit}
                  pagination={{ variant: "contained", color: "primary" }}
                  rowComponent={ReceiptDataRow}
                  entriesPerPage={{ entries: [10, 20, 40] }}
                  onRowClick={handleOpenReceipt}
                  showTotalEntries
                  noEndBorder
                  canSearch
                  totalAmountColumn="total_amount"
                  canFilterDate
                  exportData
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Receipt;

import axios from "axios";
import { useNavigate } from "react-router-dom";

require("dotenv").config();

const tokenRefresher = async (req, cb) => {
  // console.log(req.signal);
  axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/auth/refresh`, {
      headers: {
        common: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "69420",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      },
      signal: req.signal,
      cancelToken: req.cancelToken || false,
    })
    .then((res) => {
      if (res.data.access_token) {
        localStorage.setItem("access_token", res.data.access_token);
      }
      // console.log(res);
    })
    .catch((err) => {
      // console.log(err.response);
      if (err.response?.status === 403 || err.response?.status === 401) cb();
    });
  // return req;
};

const useAuthorizedRequest = () => {
  const navigate = useNavigate();
  // console.log(`Base URL: ${process.env.REACT_APP_API_BASE_URL}`);
  if (!localStorage.getItem("access_token")) {
    navigate("authentication/sign-in");
  }
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      common: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  });

  axiosClient.interceptors.request.use((req) => {
    tokenRefresher(req, () => navigate("/authentication/sign-in"));
    return req;
  });

  axiosClient.interceptors.response.use(
    (res) => res,
    (err) => {
      if (err.response?.status === 401 || err.response?.status === 403) {
        navigate("/authentication/sign-in");
      }

      if (err.response?.status === 404) {
        throw Error("Record Not Found!");
      }

      throw err;
    }
  );

  return axiosClient;
};

export default useAuthorizedRequest;

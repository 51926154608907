/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import { NumericFormat } from "react-number-format";
import FullScreenSpinner from "components/FullScreenSpinner";

import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Date
import moment from "moment";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// snackbar
import { Alert, MenuItem, Select, Snackbar } from "@mui/material";

// Dialog/Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// Data
import DataTable from "examples/Tables/DataTable";
// import ReceiptData from "layouts/warehouse/receipt/data/receiptData";
import RemoteTable from "components/RemoteTable";
import useAuthorizedRequest from "components/AuthorizedRequest";
// import { ReceiptDataRow } from "./data/receiptData";
import "../../../assets/styles/style.css";
import { useParams } from "react-router";
import jwtDecode from "jwt-decode";
import MDInput from "components/MDInput";

const salesColumnData = [
  { Header: "Sales ID", accessor: "transaction_id", width: "20%" },
  { Header: "Cashier", accessor: "operator", width: "20%" },
  { Header: "Payment Channel", accessor: "payment_channel", width: "20%" },
  { Header: "Total Amount", accessor: "total", width: "20%" },
  { Header: "Total Items", accessor: "total_item_count", width: "20%" },
  { Header: "Types", accessor: "total_item_types", width: "10%" },
  { Header: "Date", accessor: "created_at", width: "10%" },
];

const salesInfoColumnData = [
  { Header: "Product Name", accessor: "product_name", width: "30%" },
  { Header: "Stock Tags", accessor: "stock_tags", width: "10%" },
  { Header: "Qty", accessor: "qty", width: "20%" },
  { Header: "Sell Price", accessor: "sell_price", width: "20%" },
  { Header: "Discount", accessor: "discount", width: "20%" },
];

const SalesHistoryFilter = ({ multiple, title, onSelect, value, selections }) => (
  <>
    <MDBox display="inline-block" alignItems="center" mb={2} mr={2}>
      <MDTypography
        variant="h6"
        fontWeight="light"
        display="inline-block"
        style={{ lineHeight: "2.5rem", verticalAlign: "bottom" }}
      >
        {title} {":  "}
      </MDTypography>
      <Select
        size="small"
        multiple={multiple}
        variant="outlined"
        value={value}
        sx={{
          width: "15rem",
          height: "2.5rem",
          ml: 1,
          display: "inline-block",
          verticalAlign: "bottom",
          "& > div": {
            marginTop: "0.8em",
          },
        }}
        inputProps={{
          classes: { root: "select-input-styles" },
        }}
        onChange={(e) => onSelect(e.target.value)}
      >
        <MenuItem value="" disabled>
          Pilih
        </MenuItem>
        {selections?.map((selection) => (
          <MenuItem key={selection.value} value={selection.value}>
            {selection.label}
          </MenuItem>
        ))}
      </Select>
    </MDBox>
  </>
);
SalesHistoryFilter.propTypes = {
  multiple: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  selections: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

const salesRow = ({
  id,
  transaction_id: transactionId,
  operator,
  payment_channel: paymentChannel,
  total: totalAmount,
  total_item_count: totalItems,
  total_item_types: totalTypes,
  created_at: createdAt,
}) => ({
  id: {
    value: id,
    hidden: true,
  },
  transaction_id: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="text" fontWeight="regular">
        {transactionId}
      </MDTypography>
    </MDBox>
  ),
  operator: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="text" fontWeight="regular">
        {operator}
      </MDTypography>
    </MDBox>
  ),
  payment_channel: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="text" fontWeight="regular">
        {paymentChannel?.replace("_", " ")}
      </MDTypography>
    </MDBox>
  ),
  total: (
    <MDBox display="flex" alignItems="center">
      <NumericFormat
        value={totalAmount}
        displayType="text"
        thousandSeparator="."
        decimalSeparator=","
        prefix="Rp"
        suffix=""
      />
    </MDBox>
  ),
  total_item_count: (
    <MDBox display="flex" alignItems="center">
      <NumericFormat
        value={totalItems}
        displayType="text"
        thousandSeparator="."
        decimalSeparator=","
        // prefix="Rp"
        suffix=" pcs"
      />
    </MDBox>
  ),
  total_item_types: (
    <MDBox display="flex" alignItems="center">
      <NumericFormat
        value={totalTypes}
        displayType="text"
        thousandSeparator="."
        decimalSeparator=","
        // prefix="Rp"
        suffix=" types"
      />
    </MDBox>
  ),
  created_at: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="text" fontWeight="regular">
        {moment(createdAt).format("DD-MM-YYYY HH:mm:ss")}
      </MDTypography>
    </MDBox>
  ),
});

const HistoryExtraComponent = ({ ...props }) => {
  const isLargeScreen = useMediaQuery("(min-width:768px)");
  const accessToken = localStorage.getItem("access_token");
  const { roles } = jwtDecode(accessToken);
  console.log(roles);
  // const isXLargeScreen = useMediaQuery("(min-width:1024px)");
  return (
    <>
      <SalesHistoryFilter
        multiple
        title="Payment Channel"
        onSelect={props.setFilterPaymentChannel || (() => {})}
        value={props.filterPaymentChannel || []}
        selections={[
          { label: "Cash", value: "CASH" },
          { label: "Bank BCA", value: "BANK_BCA" },
          { label: "BANK BRI", value: "BANK_BRI" },
          { label: "EDC BCA", value: "EDC_BCA" },
        ]}
      />

      <MDBox display="inline-block" alignItems="center" mb={2} position="absolute">
        <MDTypography
          sx={{ lineHeight: "2.5rem " }}
          display="inline-block"
          variant="h6"
          fontWeight="light"
        >
          {"Nama Barang : "}
          {"  "}
        </MDTypography>
        <MDInput
          sx={{ ml: 1, display: "inline-block", "& input": { height: "1.2em", width: "15rem" } }}
          value={props.searchProduct || ""}
          onChange={(e) => props.setSearchProduct(e.target.value)}
        />
      </MDBox>

      {/* <SalesHistoryFilter
      multiple={false}
      title="Group Month"
      onSelect={props.setGroupMonth || (() => {})}
      value={props.groupMonth || []}
      selections={[
        { label: "January", value: 1 },
        { label: "February", value: 2 },
        { label: "March", value: 3 },
        { label: "April", value: 4 },
        { label: "May", value: 5 },
        { label: "June", value: 6 },
        { label: "July", value: 7 },
        { label: "August", value: 8 },
        { label: "September", value: 9 },
        { label: "October", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 },
      ]}
    />

    <SalesHistoryFilter
      multiple={false}
      title="Group Year"
      onSelect={props.setGroupYear || (() => {})}
      value={props.groupYear || []}
      selections={(() => {
        const arr = [];
        for (let i = 2019; i <= new Date().getFullYear(); i += 1) {
          arr.push({ label: i.toString(), value: i });
        }
        return arr;
      })()}
    /> */}
      <div style={{ display: "block", width: "100%" }}>
        <MDTypography
          style={{ display: "block" }}
          fontWeight="bold"
          display="block"
          variant="button"
          color="dark"
        >
          Sales Summary
        </MDTypography>
      </div>
      <hr style={{ marginBottom: "10px" }} />
      {/* show if is superadmin */}
      {roles?.includes("admin") ? (
        <MDBox display="flex" flexDirection="row" flexWrap="wrap">
          {Object.keys(props?.queryData?.sales_by_month || {})?.map((month) => (
            <div
              style={{
                width: isLargeScreen ? "20%" : "30%",
                border: "1px solid #ccc",
                display: "flex",
              }}
              key={month}
            >
              <MDBox style={{ display: "block", width: "100%" }}>
                <div>
                  <MDTypography
                    align="center"
                    style={{ display: "block" }}
                    fontWeight="bold"
                    display="block"
                    variant="button"
                    color="dark"
                  >
                    {month}
                  </MDTypography>
                </div>
                <hr style={{ border: "1px solid #ccc" }} />
                <MDTypography
                  align="center"
                  display="block"
                  variant="button"
                  fontWeight="regular"
                  color="dark"
                >
                  <NumericFormat
                    value={props?.queryData?.sales_by_month?.[month]?.total}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="Rp"
                    suffix=""
                  />{" "}
                  {` (${props?.queryData?.sales_by_month?.[month]?.total_entries} sales)`}
                </MDTypography>
              </MDBox>
            </div>
          ))}
        </MDBox>
      ) : null}

      {/* summary */}
      <MDBox display="block">
        <MDTypography display="block" variant="button" fontWeight="regular" color="dark">
          {props?.queryData?.sales_by_payment
            ? Object.keys(props?.queryData?.sales_by_payment)?.map((item) => (
                <div key={item} style={{ display: "block" }}>
                  <span style={{ display: "inline-block", width: "10rem", lineHeight: "1.5rem" }}>
                    {item.replace(/_/g, " ")}
                  </span>
                  <NumericFormat
                    value={props?.queryData?.sales_by_payment?.[item].total}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="Rp. "
                    suffix=""
                  />
                  <span>
                    {"   ( "} {props?.queryData?.sales_by_payment?.[item].total_entries}{" "}
                    {" sales )"}
                  </span>
                </div>
              ))
            : ""}
        </MDTypography>
      </MDBox>
    </>
  );
};

HistoryExtraComponent.defaultProps = {
  queryData: {},
  props: {},
};

HistoryExtraComponent.propTypes = {
  props: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
  ),
  queryData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
      PropTypes.objectOf(
        PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.object,
            PropTypes.objectOf(
              PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
            ),
          ])
        )
      ),
    ])
  ),
  // setGroupMonth: PropTypes.func.isRequired,
  // groupMonth: PropTypes.arrayOf(PropTypes.string).isRequired,
  // setGroupYear: PropTypes.func.isRequired,
  // groupYear: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSearchProduct: PropTypes.func.isRequired,
  searchProduct: PropTypes.arrayOf(PropTypes.string).isRequired,
  setFilterPaymentChannel: PropTypes.func.isRequired,
  filterPaymentChannel: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function SalesHistory() {
  const { transactionMedia } = useParams();
  const authorizedReq = useAuthorizedRequest();
  const [isLoading, setIsLoading] = useState(false);

  const [receiptDetails, setReceiptDetails] = useState(null);

  const receiptDialogTheme = useTheme();
  const receiptDialogFullScreen = useMediaQuery(receiptDialogTheme.breakpoints.down("md"));
  const [filterPaymentChannel, setFilterPaymentChannel] = useState([]);
  const [searchProduct, setSearchProduct] = useState([]);
  // const [groupYear, setGroupYear] = useState(null);
  // const [groupMonth, setGroupMonth] = useState(null);

  const [stateSnackbar, setStateSnackbar] = useState({
    openSnackbar: false,
    vertical: "top",
    horizontal: "right",
    severity: "info",
    message: "message",
  });
  const { vertical, horizontal, openSnackbar, severity, message } = stateSnackbar;

  const handleCloseSnackbar = () => {
    setStateSnackbar({ ...stateSnackbar, openSnackbar: false });
  };

  const handleOpenReceipt = async (row) => {
    if (!row) return;
    try {
      setIsLoading(true);

      const receiptDetail = await authorizedReq.get(`/sales/${row.original.id.value}`);
      // setOpenReceiptDialog(true);
      // if (receiptShow.id !== row.original.id.value) {
      //   setReceiptData([]);
      setIsLoading(false);
      setReceiptDetails(receiptDetail?.data);
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
    // }
    // setReceiptShow({
    //   id: row.original.id.value,
    //   receipt_id: row.original.receipt_name.value,
    // });
  };

  return (
    <DashboardLayout>
      <div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={openSnackbar}
          autoHideDuration={5000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </div>
      <FullScreenSpinner showOverlay={isLoading} />
      <Dialog
        fullScreen={receiptDialogFullScreen}
        open={receiptDetails !== null}
        onO
        // onClose={handleCloseReceiptDialog}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
        fullWidth
      >
        {/* <DialogTitle id="responsive-dialog-title">{receiptShow.receipt_id}</DialogTitle> */}
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12} align="center">
              <MDBox mt={4} mb={1}>
                <MDBox display="flex" alignItems="center">
                  <MDTypography display="block" variant="button" fontWeight="regular">
                    Receipt Code : {receiptDetails?.transaction_id}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItems="center">
                  <MDTypography display="block" variant="button" fontWeight="regular">
                    Receipt Date : {receiptDetails?.created_at}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItems="center">
                  <MDTypography display="block" variant="button" fontWeight="regular">
                    Cashier : {receiptDetails?.operator}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItems="center">
                  <MDTypography display="block" variant="button" fontWeight="regular">
                    Total Spending :{" "}
                    <NumericFormat
                      displayType="text"
                      value={receiptDetails?.items?.reduce(
                        (a, b) => a + b.sell_price * b.qty - b.discount * b.qty,
                        0
                      )}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix="Rp "
                    />
                  </MDTypography>
                </MDBox>
                <br />
                <DataTable
                  table={{
                    columns: salesInfoColumnData,
                    rows: receiptDetails
                      ? receiptDetails?.items?.map((x) => ({
                          product_name: (
                            <MDBox display="flex" alignItems="center">
                              <MDTypography display="block" variant="text" fontWeight="regular">
                                {x.product_name}
                              </MDTypography>
                            </MDBox>
                          ),
                          stock_tags: (
                            <MDBox display="flex" alignItems="center">
                              <MDTypography display="block" variant="text" fontWeight="regular">
                                {x.tags}
                              </MDTypography>
                            </MDBox>
                          ),
                          qty: (
                            <MDBox display="flex" alignItems="center">
                              <NumericFormat
                                displayType="text"
                                value={x.qty}
                                thousandSeparator="."
                                decimalSeparator=","
                                suffix=" pcs"
                              />
                            </MDBox>
                          ),
                          sell_price: (
                            <MDBox display="flex" alignItems="center">
                              <NumericFormat
                                displayType="text"
                                value={x.sell_price}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="Rp "
                              />
                            </MDBox>
                          ),
                          discount: (
                            <MDBox display="flex" alignItems="center">
                              <NumericFormat
                                displayType="text"
                                value={x.discount}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="Rp "
                              />
                            </MDBox>
                          ),
                        }))
                      : [],
                  }}
                  // showTotalAmount
                  // amountTotal={receiptAmountTotal}
                  isSorted={false}
                />
              </MDBox>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton
            autoFocus
            onClick={() => setReceiptDetails(null)}
            variant="outlined"
            color="error"
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item xs={12} lg={8} mt={2}>
                    <MDTypography variant="h6" color="white">
                      {`O${transactionMedia.substring(1, 10)}`} Sales History
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <RemoteTable
                  columnData={salesColumnData}
                  extraFilter={{
                    params: {
                      transaction_media: `O${transactionMedia.substring(1, 10)}`,
                      /* eslint-disable-next-line */
                      ...(filterPaymentChannel.length && { payment_channel: filterPaymentChannel }),
                      ...(searchProduct && { product_name: searchProduct }),
                      // ...(groupYear && { year: groupYear }),
                      // ...(groupMonth && { month: groupMonth }),
                    },
                  }}
                  extraComponent={
                    `O${transactionMedia.substring(1, 10)}` === "Offline" &&
                    ((props) => (
                      <HistoryExtraComponent
                        setFilterPaymentChannel={setFilterPaymentChannel}
                        filterPaymentChannel={filterPaymentChannel}
                        setSearchProduct={setSearchProduct}
                        searchProduct={searchProduct}
                        // setGroupYear={setGroupYear}
                        // groupYear={groupYear}
                        // setGroupMonth={setGroupMonth}
                        // groupMonth={groupMonth}
                        {...props}
                      />
                    ))
                  }
                  // isSorted
                  queryArgs={{
                    queryKey: "/sales",
                    defaultSortKey: "created_at",
                    sortDir: "desc",
                  }}
                  pagination={{ variant: "contained", color: "primary" }}
                  rowComponent={salesRow}
                  entriesPerPage={{ entries: [10, 20, 40] }}
                  onRowClick={handleOpenReceipt}
                  showTotalEntries
                  noEndBorder
                  canSearch
                  canFilterDate
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SalesHistory;

/* eslint-disable no-undef */
/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
// import axios from "axios";
import { NumericFormat } from "react-number-format";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

// Material Dashboard 2 React components
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Alert, Snackbar, OutlinedInput } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Modal from "@mui/material/Modal";
import useAuthorizedRequest from "components/AuthorizedRequest";
import _ from "lodash";
import useStockQRPrint from "../../../stock/qr-print";

function InputReceipt() {
  const abortController = new AbortController();
  const authorizedReq = useAuthorizedRequest();

  const [inputStockValue, setInputStockValue] = useState("");
  const [fetchedStock, setFetchedStock] = useState([]);
  const [selectedStock, setSelectedStock] = useState({
    id: 0,
  });
  const [stockRowData, setStockRowData] = useState([]);
  const [stockInput, setStockInput] = useState({});

  const [stockNamePrint, setStockNamePrint] = useState("");
  const [stockTagsPrint, setStockTagsPrint] = useState("");

  const [inputSupplierValue, setInputSupplierValue] = useState("");
  const [fetchedSupplier, setFetchedSupplier] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState({
    id: 0,
  });

  const [stockBuyPrice, setStockBuyPrice] = useState("");
  const [stockQty, setStockQty] = useState("");

  const [receiptStatus, setReceiptStatus] = useState("unpaid");
  const [dateSelected, setDateSelected] = useState(null);
  const [receiptId, setReceiptId] = useState("");
  const [receiptNotes, setReceiptNotes] = useState("");

  const [disablePrintButton, setDisablePrintButton] = useState(true);
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);
  const [disableReceiptId, setDisableReceiptId] = useState(false);

  const [snackProps, setSnackProps] = useState({
    message: "",
    severity: "info",
    isOpen: false,
  });
  const [modalOpen, setModalOpen] = useState(false);

  const [qrPrintData, setQrPrintData] = useState({});
  const { ThingToPrint, handlePrint } = useStockQRPrint(qrPrintData);

  const printQR = (itemId, prodName, prodTags, idReceipt) => {
    setQrPrintData({
      item_id: itemId,
      product_name: prodName,
      tags: prodTags,
      receipt_id: idReceipt,
    });
  };

  const getFetchedStock = (input) => {
    authorizedReq
      .get(`/stock?search=${input}`, {
        signal: abortController.signal,
      })
      .then((res) => {
        setFetchedStock(
          res.data.data.map((x) => ({
            id: x.id,
            product_name: `${x.product_name}, ${x.tags}`,
          }))
        );
      })
      .catch((err) => {
        setSnackProps({
          isOpen: false,
          severity: "error",
          message: err.cancel,
        });
      });
  };

  const getSelectedStock = () => {
    if (
      typeof selectedStock === "undefined" ||
      selectedStock?.id === 0 ||
      typeof selectedStock?.id === "undefined"
    ) {
      return;
    }
    // eslint-disable-next-line no-console
    authorizedReq
      .get(`${process.env.REACT_APP_API_BASE_URL}/stock/${selectedStock.id}`)
      .then((resStock) => {
        setStockNamePrint(resStock.data.product_name);
        setStockTagsPrint(resStock.data.tags);
      })
      .catch((errStock) => {
        setSnackProps({
          isOpen: true,
          message: `${errStock.response.data.message}`,
          severity: "error",
        });
      });
  };

  const arrangeStockInputData = () => {
    if (
      typeof selectedSupplier === "undefined" ||
      selectedSupplier?.id === 0 ||
      typeof selectedSupplier?.id === "undefined"
    ) {
      return;
    }
    setStockInput({
      receipt_id: receiptId,
      date: moment(dateSelected).format("DD-MM-yyyy"),
      receipt_note: "default notes",
      items: stockRowData.map((x) => ({
        qty: Number(x.stock_qty),
        stock_id: x.id,
        buy_price: Number(x.price),
      })),
      payment: receiptStatus,
      supplier_id: selectedSupplier.id,
    });
  };

  const getSupplier = (input) => {
    authorizedReq
      .get(`/supplier?search=${input}`, {
        signal: abortController.signal,
      })
      .then((res) => {
        setFetchedSupplier(
          res.data.data.map((x) => ({
            id: x.id,
            supplier_name: x.supplier_name,
          }))
        );
      })
      .catch((err) => {
        setSnackProps({
          isOpen: false,
          severity: "error",
          message: err.cancel,
        });
      });
  };

  const handleSubmitStock = () => {
    authorizedReq
      .post("/receipt", stockInput, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(() => {
        setSnackProps({
          isOpen: true,
          message: "Input Stock Saved",
          severity: "success",
        });
        setInputStockValue("");
        setSelectedStock([]);
        setStockQty("");
        setDisableReceiptId(true);
      })
      .catch((err) => {
        setSnackProps({
          isOpen: true,
          message: `${err.response.data.message}`,
          severity: "error",
        });
      });
  };

  const handleSnackClose = () => {
    setSnackProps({ ...snackProps, isOpen: false });
  };

  const handleDeleteRow = (i) => {
    setStockRowData(stockRowData.filter((item) => item.id !== i));
  };

  const handleResetInputReceipt = () => {
    setStockRowData([]);
    setStockInput({});
    setStockBuyPrice("");
    setReceiptStatus("unpaid");
    setDateSelected(null);
    setInputStockValue("");
    setFetchedStock([]);
    setSelectedStock({
      id: 0,
    });
    setInputSupplierValue("");
    setFetchedSupplier([]);
    setSelectedSupplier({
      id: 0,
    });
    setStockQty("");
    setReceiptId("");
    setReceiptNotes("default notes");
    setDisableReceiptId(false);
    setDisableDeleteButton(false);
    setDisablePrintButton(true);
  };

  const handleAddStock = () => {
    const compare = stockRowData.findIndex((stock) => stock.id === selectedStock.id);

    if (compare === -1) {
      setStockRowData([
        ...stockRowData,
        {
          id: selectedStock.id,
          stock_name: selectedStock.product_name,
          stock_qty: Number(stockQty),
          price: Number(stockBuyPrice),
          // receipt_id: receiptId,
          stock_name_print: stockNamePrint,
          stock_tags_print: stockTagsPrint,
        },
      ]);
    } else if (compare === 0) {
      setSnackProps({
        isOpen: true,
        message: "stock is already added",
        severity: "error",
      });
      setStockBuyPrice("");
      setFetchedStock([]);
      setSelectedStock({
        id: 0,
      });
      setStockQty("");
      setModalOpen(false);
    }
  };

  const handleChangeStockBuyPrice = ({ value }) => {
    setStockBuyPrice(value);
  };

  const handleChangeStockQty = ({ value }) => {
    setStockQty(value);
  };

  useEffect(() => {
    if (snackProps.severity === "success") {
      setDisableDeleteButton(true);
      setDisablePrintButton(false);
    }
  }, [snackProps]);

  useEffect(() => {
    if (qrPrintData.product_name) {
      handlePrint();
      setQrPrintData({});
    }
  }, [qrPrintData, ThingToPrint]);

  useEffect(() => {
    if (inputStockValue === "") {
      setFetchedStock(selectedStock ? [selectedStock] : [{ id: 0 }]);
      return undefined;
    }
    _.debounce(() => {
      getFetchedStock(inputStockValue);
    }, 100)();

    return () => abortController.abort();
  }, [selectedStock, inputStockValue]);

  useEffect(() => {
    getSelectedStock();
  }, [selectedStock]);

  useEffect(() => {
    setSelectedStock([]);
    setInputStockValue("");
    setFetchedStock([]);
    setStockQty("");
    setModalOpen(false);
    setStockBuyPrice("");
  }, [stockRowData]);

  useEffect(() => {
    if (inputSupplierValue === "") {
      setFetchedSupplier(selectedSupplier ? [selectedSupplier] : [{ id: 0 }]);
      return undefined;
    }

    _.debounce(() => {
      getSupplier(inputSupplierValue);
    }, 100)();

    return () => abortController.abort();
  }, [selectedSupplier, inputSupplierValue]);

  useEffect(() => {
    arrangeStockInputData();
  }, [stockRowData, receiptId, receiptNotes, dateSelected, receiptStatus, selectedSupplier]);

  return (
    <DashboardLayout>
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackProps.isOpen}
          onClose={handleSnackClose}
          autoHideDuration={6000}
        >
          <Alert onClose={handleSnackClose} severity={snackProps.severity} sx={{ width: "100%" }}>
            {snackProps.message}
          </Alert>
        </Snackbar>
      </div>
      <div>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="modal-modal-title"
          // aria-describedby="modal-modal-description"
          align-self="center"
          // align="center"
        >
          <Grid container justifyContent="center" mt={25}>
            <Grid item xs={6} lg={3} align="center">
              <Card>
                <MDBox p={2} align="center">
                  <MDTypography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    // color="light"
                    align="center"
                    // mb={2}
                  >
                    Insert Quantity and Buy Price
                  </MDTypography>
                  <hr color="gray" />
                  <MDBox mt={2}>
                    <NumericFormat
                      value={stockQty}
                      thousandSeparator="."
                      decimalSeparator=","
                      onValueChange={handleChangeStockQty}
                      customInput={OutlinedInput}
                      fullWidth
                      // startAdornment={<InputAdornment position="start">Rp</InputAdornment>}
                      inputProps={{
                        maxLength: 15,
                        step: "500",
                      }}
                      // notched
                      placeholder="stock quantity"
                    />
                  </MDBox>
                  <MDBox mt={2}>
                    <NumericFormat
                      value={stockBuyPrice}
                      thousandSeparator="."
                      decimalSeparator=","
                      onValueChange={handleChangeStockBuyPrice}
                      customInput={OutlinedInput}
                      fullWidth
                      startAdornment={<InputAdornment position="start">Rp</InputAdornment>}
                      inputProps={{
                        maxLength: 15,
                        step: "500",
                      }}
                      // notched
                      placeholder="stock buy price"
                    />
                  </MDBox>
                  <Grid container justifyContent="center">
                    <Grid item xs={12} lg={12} mt={2}>
                      <MDButton variant="gradient" color="info" onClick={handleAddStock} fullWidth>
                        Add
                      </MDButton>
                    </Grid>
                    <Grid item xs={12} lg={12} mt={2}>
                      <MDButton
                        variant="gradient"
                        color="error"
                        onClick={() => setModalOpen(false)}
                        fullWidth
                      >
                        Cancel
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </Modal>
      </div>
      <DashboardNavbar />
      {/* This is hidden on purpose } */}
      <div style={{ display: "none" }}>{ThingToPrint}</div>
      {/* {TODO this button is to be removed} */}
      {/* <MDButton onClick={() => printQR("test", "test1, test2")}>Print</MDButton> */}
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox
                  component="form"
                  role="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmitStock();
                  }}
                >
                  <MDBox mb={2}>
                    <MDTypography variant="text">Receipt ID</MDTypography>
                    <MDInput
                      value={receiptId}
                      onChange={(e) => setReceiptId(e.target.value)}
                      type="text"
                      fullWidth
                      placeholder="receipt ID"
                      disabled={disableReceiptId}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="text">Date</MDTypography>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        // label="Responsive"
                        mask="__-__-____"
                        // inputFormat={moment().format("dd-MM-yyyy")}
                        inputFormat="DD-MM-yyyy"
                        // disableMaskedInput
                        value={dateSelected}
                        onChange={(newValue) => {
                          // setDateSelected(moment(newValue).format("DD-MM-yyyy"));
                          setDateSelected(newValue);
                        }}
                        disableFuture
                        renderInput={(params) => (
                          <MDInput fullWidth placeholder="select date" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </MDBox>
                  <MDBox mb={2} mt={2}>
                    <MDTypography variant="text">Supplier Name</MDTypography>
                    <Autocomplete
                      freeSolo
                      id="combo-box-demo"
                      value={selectedSupplier}
                      onChange={(e, d) => setSelectedSupplier(d)}
                      options={fetchedSupplier}
                      getOptionLabel={(option) => option.supplier_name || ""}
                      filterSelectedOptions
                      filterOptions={(x) => x}
                      onInputChange={(event, newInputSupplierValue) => {
                        setInputSupplierValue(newInputSupplierValue);
                      }}
                      renderInput={(params) => <MDInput {...params} placeholder="supplier name" />}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="text">Receipt Status</MDTypography>
                    <Select
                      labelId="status"
                      id="status"
                      value={receiptStatus}
                      label="status"
                      variant="standard"
                      defaultValue=""
                      onChange={(e) => setReceiptStatus(e.target.value)}
                      fullWidth
                    >
                      <MenuItem value="paid">Paid</MenuItem>
                      <MenuItem value="unpaid">Unpaid</MenuItem>
                    </Select>
                  </MDBox>
                  <hr />
                  <MDBox mb={2} mt={2}>
                    <MDTypography variant="text">Stock Name</MDTypography>
                    <Autocomplete
                      freeSolo
                      id="combo-box-demo"
                      value={selectedStock}
                      onChange={(e, d) => setSelectedStock(d)}
                      options={fetchedStock}
                      getOptionLabel={(option) => option.product_name || ""}
                      filterSelectedOptions
                      filterOptions={(x) => x}
                      onInputChange={(event, newInputStockValue) => {
                        setInputStockValue(newInputStockValue);
                      }}
                      renderInput={(params) => <MDInput {...params} placeholder="stock name" />}
                    />
                  </MDBox>
                  <MDBox mt={4} mb={4}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={() => setModalOpen(true)}
                    >
                      Add Selected Stock
                    </MDButton>
                  </MDBox>
                  <hr />
                  <MDBox mt={4} mb={1}>
                    <DataTable
                      extraFooterSlot={
                        stockRowData && (
                          <>
                            <MDBox
                              display="flex"
                              alignContent="flex-end"
                              flexDirection="row"
                              flexWrap="wrap"
                              alignItems="flex-end"
                              sx={{ paddingY: ".5em" }}
                            >
                              <MDTypography
                                sx={{ marginLeft: "60%", fontSize: "0.7em", flex: "0 0 100%" }}
                                display="flex"
                                variant="text"
                                fontWeight="regular"
                              >
                                Total Items&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                                <NumericFormat
                                  value={stockRowData?.reduce((a, b) => a + b.stock_qty, 0)}
                                  displayType="text"
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix=" &nbsp;"
                                  suffix=" pcs"
                                />
                              </MDTypography>
                              <MDTypography
                                sx={{ marginLeft: "60%", fontSize: "0.7em", flex: "0 0 100%" }}
                                display="flex"
                                variant="text"
                                fontWeight="regular"
                              >
                                Total Amount&nbsp;&nbsp;&nbsp;:
                                <NumericFormat
                                  value={stockRowData?.reduce(
                                    (a, b) => a + b.price * b.stock_qty,
                                    0
                                  )}
                                  displayType="text"
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix=" &nbsp; Rp. "
                                  // suffix=" pcs"
                                />
                              </MDTypography>
                            </MDBox>
                          </>
                        )
                      }
                      table={{
                        columns: [
                          { Header: "Stock Name", accessor: "stock_name", width: "50%" },
                          { Header: "Quantity", accessor: "stock_qty", width: "20%" },
                          { Header: "Buy Price", accessor: "buy_price", width: "20%" },
                          { Header: "Action", accessor: "action", width: "10%" },
                        ],
                        // eslint-disable-next-line no-return-assign
                        rows: stockRowData.map((x) => ({
                          stock_name: (
                            <MDBox display="flex" alignItems="center">
                              <MDTypography display="block" variant="text" fontWeight="regular">
                                {x.stock_name}
                              </MDTypography>
                            </MDBox>
                          ),
                          stock_qty: (
                            <MDBox display="flex" alignItems="center">
                              <NumericFormat
                                value={x.stock_qty}
                                displayType="text"
                                thousandSeparator="."
                                decimalSeparator=","
                                // prefix="Rp"
                                suffix=" pcs"
                              />
                            </MDBox>
                          ),
                          buy_price: (
                            <MDBox display="flex" alignItems="center">
                              <NumericFormat
                                value={x.price}
                                displayType="text"
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="Rp "
                                // suffix=" pcs"
                              />
                            </MDBox>
                          ),
                          action: (
                            <MDBox>
                              <MDButton
                                iconOnly
                                color="error"
                                onClick={() => handleDeleteRow(x.id)}
                                variant="outlined"
                                disabled={disableDeleteButton}
                              >
                                <Icon fontSize="small" component="i" color="error">
                                  delete
                                </Icon>
                              </MDButton>
                              <MDButton
                                iconOnly
                                color="info"
                                onClick={() =>
                                  printQR(
                                    x.item_id,
                                    x.stock_name_print,
                                    x.stock_tags_print,
                                    receiptId
                                  )
                                }
                                variant="outlined"
                                ml={2}
                                disabled={disablePrintButton}
                              >
                                <Icon fontSize="small" component="i" color="info">
                                  print
                                </Icon>
                              </MDButton>
                            </MDBox>
                          ),
                        })),
                      }}
                      isSorted={false}
                    />
                  </MDBox>
                  <hr />
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="error"
                      fullWidth
                      onClick={handleResetInputReceipt}
                    >
                      Reset Receipt Field
                    </MDButton>
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="success"
                      fullWidth
                      onClick={handleSubmitStock}
                    >
                      Submit Receipt
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default InputReceipt;

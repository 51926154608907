/* eslint-disable */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import moment from "moment";
import "moment/locale/id";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Footer from "examples/Footer";
import {
  Alert,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  Snackbar,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  Autocomplete,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import PrintIcon from "@mui/icons-material/Print";
import ClearIcon from "@mui/icons-material/Clear";
import BluetoothConnectedIcon from "@mui/icons-material/BluetoothConnected";
import beepAudio from "assets/sounds/beep.mp3";
import { Howl } from "howler";
import _ from "lodash";
import useAuthorizedRequest from "components/AuthorizedRequest";
import Button from "@mui/material/Button";
import FullScreenSpinner from "components/FullScreenSpinner";
import ReceiptReturnTable from "../receipt-return-table";
import useReturnToSupplierPrint from "./print";

function ReturnToSupplier() {
  const abortController = new AbortController();
  const authorizedReq = useAuthorizedRequest();
  const navigate = useNavigate();
  moment.locale("id");
  const beep = new Howl({
    src: [beepAudio],
  });
  const authorizedRequester = useAuthorizedRequest();
  const [BTDevice, setBTDevice] = useState("");
  const [qrData, setQrData] = useState("");
  const [errorMessage] = useState("");
  const [returnData, setReturnData] = useState([]);
  const [selectedScanner, setSelectedScanner] = useState("External Scanner");
  const [scannerRemountToken, setScannerRemountToken] = useState(Date.now());
  // const [scannerInput, setScannerInput] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [confirmSubmit, setConfirmSubmit] = useState({
    showDialog: false,
    isSubmitting: false,
    snackMessage: "",
    alertSeverity: "info",
    showAlert: false,
  });
  const [actionConfirmation, setActionConfirmation] = useState({
    action: "",
    delStockId: 0,
    delIndex: -1,
    showDialog: false,
  });
  const tempQRData = useRef();
  const [showPrintButton, setShowPrintButton] = useState(false);
  const [returnOperator, setReturnOperator] = useState("");

  const [fetchedSupplier, setFetchedSupplier] = useState([]);
  const [inputSupplierValue, setInputSupplierValue] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState({
    id: 0,
  });
  const { ThingToPrint, handlePrint } = useReturnToSupplierPrint({
    transactionId,
    operator: returnOperator,
    supplier: selectedSupplier,
    items: returnData,
  });

  const handleBluetoothClick = async () => {
    console.log("Requesting Bluetooth Device...");
    const gattServer = await navigator.bluetooth
      .requestDevice({
        filters: [
          {
            services: [
              "0000fff0-0000-1000-8000-00805f9b34fb",
              "0000feea-0000-1000-8000-00805f9b34fb",
            ],
          },
          { namePrefix: "BarCode" },
        ],
        optionalServices: [
          "0000fff0-0000-1000-8000-00805f9b34fb",
          "0000feea-0000-1000-8000-00805f9b34fb",
        ],
      })
      .then((device) => {
        console.log("Connecting to GATT Server...");
        setBTDevice(device.name);
        return device.gatt.connect();
      })
      .catch((error) => {
        setBTDevice("");
        console.log("Argh! ", error);
      });

    let targetCharacteristic = "";
    let primaryService = await gattServer
      .getPrimaryService("0000fff0-0000-1000-8000-00805f9b34fb")
      .catch((e) => console.log(e));
    targetCharacteristic = "0000fff1-0000-1000-8000-00805f9b34fb";
    if (!primaryService?.uuid) {
      primaryService = await gattServer.getPrimaryService("0000feea-0000-1000-8000-00805f9b34fb");
      targetCharacteristic = "00002aa1-0000-1000-8000-00805f9b34fb";
    }
    primaryService
      .getCharacteristic(targetCharacteristic)
      .then((characteristic) => {
        characteristic.addEventListener("gattserverdisconnected", () => setBTDevice(""));
        characteristic.addEventListener("characteristicvaluechanged", (e) => {
          const dec = new TextDecoder("utf-8");
          const decodedData = dec.decode(e.target.value.buffer);
          tempQRData.current = tempQRData.current
            ? (tempQRData.current += decodedData)
            : decodedData;
          if (/\r|\n/.exec(decodedData)) {
            // Do something, the string contains a line break
            setQrData(tempQRData.current.slice(0, -1));
            tempQRData.current = "";
          }
        });
        characteristic.startNotifications();
      })
      .catch((error) => {
        setBTDevice("");
        console.log("Argh! ", error);
      });
  };

  const handleQtyChange = (itemId, qty) => {
    const saleDataIdx = _.findIndex(returnData, (x) => x.item_id === itemId);
    const newReturnData = [...returnData];
    newReturnData[saleDataIdx].qty_to_return = qty;
    setReturnData(newReturnData);
  };

  const handleConfirmDelete = (itemId) => {
    const saleDataIdx = _.findIndex(returnData, (x) => x.item_id === itemId);
    setActionConfirmation({
      delStockId: itemId,
      action: "delete",
      delIndex: saleDataIdx,
      showDialog: true,
    });
  };

  const handleCancelAction = () => {
    setActionConfirmation({
      delStockId: 0,
      delIndex: -1,
      showDialog: false,
    });
  };

  const handleDoDelete = () => {
    setActionConfirmation({ ...actionConfirmation, showDialog: false });
    setReturnData(_.reject(returnData, (x) => x.item_id === actionConfirmation.delStockId));
  };

  const handleQRScanResult = async (qrErr, qrRes) => {
    if (qrRes) {
      beep.play();
      setQrData(qrRes?.text);
    }

    if (qrErr) {
      // setIsError(true);
      // handleSetErrorMessage(qrErr);
      // console.info(qrErr);
      setQrData("");
    }
  };

  const resetReturnData = () => {
    setTransactionId("");
    setSelectedSupplier({});
    setInputSupplierValue("");
    setReturnData([]);
    setShowPrintButton(false);
    setActionConfirmation({
      showDialog: false,
    });
  };

  const printReceipt = async () => {
    console.log("Print Receipt");
    handlePrint();
  };

  const proceedTransaction = async (e) => {
    e.preventDefault();

    setConfirmSubmit({ ...confirmSubmit, isSubmitting: true, showDialog: true });

    // Check Qty Exceed
    const returnQtyExceed = _.filter(returnData, (x) => x.qty_to_return > x.qty_left);
    console.log(returnQtyExceed);
    if (returnQtyExceed.length) {
      setConfirmSubmit({
        ...confirmSubmit,
        isValidating: false,
        showDialog: false,
        showAlert: true,
        snackMessage: "Return Qty Exceed, Check Data!",
        alertSeverity: "error",
      });
      return;
    }

    authorizedRequester
      .post("receipt-return", {
        supplier_id: selectedSupplier.id,

        items: returnData.map((x) => ({
          id: x.item_id,
          return_qty: x.qty_to_return,
        })),
      })
      .then((resp) => {
        setConfirmSubmit({
          ...confirmSubmit,
          showAlert: true,
          snackMessage: "Transaction Saved",
          alertSeverity: "success",
        });
        setReturnOperator(resp?.data?.data?.operator);
        setTransactionId(resp?.data?.data?.transaction_id);
        setShowPrintButton(true);
      })
      .catch((err) => {
        setConfirmSubmit({
          ...confirmSubmit,
          showAlert: true,
          snackMessage: JSON.stringify(err.response.data),
          alertSeverity: "error",
        });
      });
    // TODO set alerts
    setConfirmSubmit({ ...confirmSubmit, isSubmitting: false, showDialog: false });
  };

  const getSupplier = (input) => {
    if (!input) return;
    authorizedReq
      .get(`/supplier?search=${input}`, {
        signal: abortController.signal,
      })
      .then((res) => {
        setFetchedSupplier(
          res.data.data.map((x) => ({
            id: x.id,
            supplier_name: x.supplier_name,
          }))
        );
      })
      .catch((err) => {
        setConfirmSubmit({
          ...confirmSubmit,
          showAlert: true,
          snackMessage: "Failed to get supplier",
          alertSeverity: "error",
        });
      });
  };

  useEffect(() => {
    if (inputSupplierValue === "") {
      setFetchedSupplier(selectedSupplier ? [selectedSupplier] : [{ id: 0 }]);
      return undefined;
    }

    _.debounce(() => {
      getSupplier(inputSupplierValue);
    }, 100)();

    return () => abortController.abort();
  }, [selectedSupplier, inputSupplierValue]);

  useEffect(() => {
    setScannerRemountToken(Date.now());
  }, [selectedScanner]);

  useEffect(() => {
    setScannerRemountToken(Date.now());
  }, []);

  useEffect(() => {
    if (!qrData) return;

    authorizedRequester
      .get(`/receipt/item/${qrData}`)
      .then((res) => {
        if (!res.data.item_id) {
          // use confirm submit alert???????
          setConfirmSubmit({
            snackMessage: "Stock Not Found",
            alertSeverity: "error",
            showAlert: true,
          });
          return;
        }
        setQrData("");

        // Check supplier
        if (!selectedSupplier.id == res?.data?.supplier_id) {
          setConfirmSubmit({
            snackMessage: "Supplier Missmatch",
            alertSeverity: "error",
            showAlert: true,
          });
          return;
        }

        let existedIndex = -1;
        if (returnData.length) {
          existedIndex = _.findIndex(returnData, (x) => res.data.item_id === x.item_id);
        }
        if (existedIndex >= 0) {
          const newReturnData = [...returnData];
          newReturnData[existedIndex].qty_to_return += 1;
          setReturnData(newReturnData);
        } else {
          const newReturnData = [...returnData];
          newReturnData.push({ ...res.data, qty_to_return: 1 });
          setReturnData(newReturnData);
        }
      })
      .catch((err) => {
        setConfirmSubmit({
          snackMessage: err?.message,
          alertSeverity: "error",
          showAlert: true,
        });
      });
  }, [qrData, returnData, setReturnData, setQrData]);

  return (
    <DashboardLayout>
      {/* {receiptData} */}
      <div style={{ display: "none" }}>{ThingToPrint}</div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={confirmSubmit.showAlert}
        onClose={() => setConfirmSubmit({ ...confirmSubmit, showAlert: false })}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setConfirmSubmit({ ...confirmSubmit, showAlert: false })}
          severity={confirmSubmit.alertSeverity}
          sx={{ width: "100%" }}
        >
          {confirmSubmit.snackMessage}
        </Alert>
      </Snackbar>
      <FullScreenSpinner showOverlay={confirmSubmit.isSubmitting} />
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        // TransitionProps={{ onEntering: handleEntering }}
        open={actionConfirmation.showDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <MDTypography variant="button">
            {actionConfirmation.action == "delete"
              ? `Delete ${returnData[actionConfirmation.delIndex]?.product_name}: ${
                  returnData[actionConfirmation.delIndex]?.stock_tags
                }`
              : "Reset Data?"}
          </MDTypography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelAction}>
            Cancel
          </Button>
          <Button
            onClick={actionConfirmation.action == "delete" ? handleDoDelete : resetReturnData}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item xs={12} lg={9}>
                    <MDTypography variant="h6" color="white">
                      Return To Supplier
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox mb={2} mt={2}>
                  <MDTypography variant="text">Supplier Name</MDTypography>
                  <Autocomplete
                    freeSolo
                    id="combo-box-demo"
                    value={selectedSupplier}
                    onChange={(e, d) => setSelectedSupplier(d)}
                    options={fetchedSupplier}
                    getOptionLabel={(option) => option.supplier_name || ""}
                    filterSelectedOptions
                    filterOptions={(x) => x}
                    onInputChange={(event, newInputSupplierValue) => {
                      setInputSupplierValue(newInputSupplierValue);
                    }}
                    renderInput={(params) => <MDInput {...params} placeholder="supplier name" />}
                  />
                </MDBox>
                <MDBox>
                  <MDBox mb={2}>
                    <MDBox
                      mt={1}
                      component="form"
                      role="form"
                      sx={{ display: selectedScanner === "External Scanner" ? "block" : "none" }}
                      onSubmit={(e) => {
                        e.preventDefault();
                        setQrData(e.target.scannerInputField.value);
                        e.target.scannerInputField.value = "";
                        // setScannerInput("");
                      }}
                    >
                      <MDInput
                        type="input"
                        fullWidth
                        name="scannerInputField"
                        placeholder="Bluetooth QR and BarCode Scanner"
                        // value={scannerInput}
                        // onChange={(e) => setScannerInput(e.target.value)}
                      />
                    </MDBox>
                    <MDBox mt={1}>
                      <FormControl>
                        <FormLabel id="ScannerSelect">Select Scanner</FormLabel>
                        <RadioGroup
                          name="ScannerSelector"
                          value={selectedScanner}
                          row
                          onChange={(e) => setSelectedScanner(e.target.value)}
                        >
                          <FormControlLabel
                            value="External Scanner"
                            control={<Radio />}
                            label="External Scanner"
                          />
                          <FormControlLabel
                            value="Camera/Webcam"
                            control={<Radio />}
                            label="Camera/Webcam"
                          />
                        </RadioGroup>
                      </FormControl>
                      {selectedScanner === "External Scanner" ? (
                        <>
                          <MDBox mb={1}>
                            <Button
                              onClick={handleBluetoothClick}
                              variant="contained"
                              sx={{ mt: 2 }}
                              endIcon={<BluetoothConnectedIcon color="white" fontSize="large" />}
                            >
                              <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="white"
                                size="small"
                              >
                                Connect Bluetooth
                              </MDTypography>
                            </Button>
                          </MDBox>
                          <MDTypography
                            variant="caption"
                            fontWeight="light"
                            color="info"
                            sx={{ mt: 1, ml: 1 }}
                          >
                            {BTDevice ? `Connected: ${BTDevice}` : "Disconnected"}
                          </MDTypography>
                        </>
                      ) : null}
                    </MDBox>
                    {!showPrintButton && (
                      <>
                        <Button
                          onClick={proceedTransaction}
                          variant="contained"
                          sx={{ width: "100%", mt: 2 }}
                          endIcon={<SendIcon color="white" fontSize="large" />}
                        >
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="white"
                            size="small"
                          >
                            Proceed Transaction
                          </MDTypography>
                        </Button>
                      </>
                    )}
                    <MDButton
                      onClick={() => {
                        setActionConfirmation({
                          ...actionConfirmation,
                          action: "reset",
                          showDialog: true,
                        });
                      }}
                      variant="contained"
                      color="warning"
                      sx={{
                        width: "100%",
                        mt: 2,
                        backgroundColor: "#fb8c00",
                        "&:hover": { backgroundColor: "#rgb(175, 98, 0) !important" },
                      }}
                      endIcon={<ClearIcon color="white" fontSize="large" />}
                    >
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="white"
                        size="small"
                      >
                        Reset Form
                      </MDTypography>
                    </MDButton>
                    {showPrintButton && (
                      <>
                        <Button
                          onClick={printReceipt}
                          variant="contained"
                          sx={{ width: "100%", mt: 2 }}
                          endIcon={<PrintIcon color="white" fontSize="large" />}
                        >
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="white"
                            size="small"
                          >
                            Print Receipt
                          </MDTypography>
                        </Button>
                      </>
                    )}
                  </MDBox>
                  {selectedScanner === "Camera/Webcam" ? (
                    <MDBox mt={1} mb={1}>
                      <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs={12} md={6}>
                          <BarcodeScannerComponent
                            key={scannerRemountToken}
                            style={{ maxWidth: "100%" }}
                            delay={500}
                            onUpdate={handleQRScanResult}
                            stopStream={selectedScanner !== "Camera/Webcam"}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                  ) : null}
                  <MDBox mt={4} mb={1}>
                    <MDTypography variant="text" hidden>
                      {errorMessage}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>

              <MDBox>
                {ReceiptReturnTable({
                  returnData: returnData,
                  type: "to_supplier",
                  itemRemoveCallback: handleConfirmDelete,
                  returnQtyChangedCallback: handleQtyChange,
                  extraFooterSlot: returnData && (
                    <>
                      <MDBox
                        display="flex"
                        alignContent="flex-end"
                        flexDirection="row"
                        flexWrap="wrap"
                        alignItems="flex-end"
                        sx={{ paddingY: ".5em" }}
                      >
                        <MDTypography
                          sx={{ marginLeft: "80%", fontSize: "0.7em", flex: "0 0 100%" }}
                          display="flex"
                          variant="text"
                          fontWeight="regular"
                        >
                          Total Items To Return:{" "}
                          {returnData?.reduce((a, b) => a + b.qty_to_return, 0)} pcs
                        </MDTypography>
                      </MDBox>
                    </>
                  ),
                })}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export default ReturnToSupplier;

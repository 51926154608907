/* eslint-disable react/prop-types */

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import moment from "moment";

export const SupplierDataRow = ({
  id,
  supplier_name: supplierName,
  status: supplierStatus,
  updated_at: updatedAt,
  onRowDelete,
  onRowEdit,
}) => ({
  supplier_name: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        {supplierName}
      </MDTypography>
    </MDBox>
  ),
  status: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        {supplierStatus}
      </MDTypography>
    </MDBox>
  ),
  updated_at: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        {moment(updatedAt).format("DD-MM-YYYY HH:mm:ss")}
      </MDTypography>
    </MDBox>
  ),
  action: (
    <MDBox>
      <MDButton
        iconOnly
        color="info"
        onClick={onRowEdit && (() => onRowEdit(id))}
        variant="outlined"
      >
        <Icon fontSize="small" component="i" color="info">
          edit
        </Icon>
      </MDButton>
      <MDButton
        iconOnly
        color="error"
        onClick={onRowDelete && (() => onRowDelete(id, supplierName))}
        variant="outlined"
      >
        <Icon fontSize="small" component="i" color="error">
          delete
        </Icon>
      </MDButton>
    </MDBox>
  ),
});

export default function data() {
  const Item = ({ name }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Supplier name", accessor: "supplier_name", width: "50%", align: "left" },
      // { Header: "Supplier code", accessor: "supplier_code", width: "20%", align: "center" },
      { Header: "Status", accessor: "status", width: "20%", align: "center" },
      { Header: "Updated At", accessor: "updated_at", align: "center", width: "20%" },
      { Header: "Action", accessor: "action", width: "10%", align: "center" },
    ],

    rows: [
      {
        supplier_name: <Item name="Supplier1" />,
        supplier_code: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            SPL1
          </MDTypography>
        ),
        status: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            active
          </MDTypography>
        ),
        updated_at: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            11-01-2022 13:37:50
          </MDTypography>
        ),
        action: (
          <MDBox>
            <MDButton iconOnly color="info" href="./supplier/supplier-edit" variant="outlined">
              <Icon fontSize="small" component="a" color="info">
                edit
              </Icon>
            </MDButton>
            <MDButton iconOnly color="error" href="./supplier/supplier-edit" variant="outlined">
              <Icon fontSize="small" component="a" color="error">
                delete
              </Icon>
            </MDButton>
          </MDBox>
        ),
      },
      {
        supplier_name: <Item name="Supplier2" />,
        supplier_code: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            SPL2
          </MDTypography>
        ),
        status: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            active
          </MDTypography>
        ),
        updated_at: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            12-01-2022 09:11:03
          </MDTypography>
        ),
        action: (
          <MDBox>
            <MDButton iconOnly color="info" href="./supplier/supplier-edit" variant="outlined">
              <Icon fontSize="small" component="a" color="info">
                edit
              </Icon>
            </MDButton>
            <MDButton iconOnly color="error" href="./supplier/supplier-edit" variant="outlined">
              <Icon fontSize="small" component="a" color="error">
                delete
              </Icon>
            </MDButton>
          </MDBox>
        ),
      },
    ],
  };
}

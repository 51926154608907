/* eslint react/prop-types: 0 */
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    display: (props) => (props.showOverlay ? "flex" : "none"),
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(24, 24, 24, 0.5)",
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "0px",
    left: "0px",
    zIndex: "9999",
  },
});

const FullScreenSpinner = ({ ...props }) => {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <CircularProgress sx={{ color: "#2F88EC", display: "flex" }} />
    </div>
  );
};

export default FullScreenSpinner;

/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
// @mui material components
import { useState, forwardRef } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import moment from "moment/moment";

// snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FullScreenSpinner from "components/FullScreenSpinner";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import RemoteTable from "components/RemoteTable";

// Data
import usersTableData from "layouts/user-management/data/usersTableData";
import useAuthorizedRequest from "components/AuthorizedRequest";
// import projectsTableData from "layouts/tables/data/projectsTableData";

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const userDataColumns = [
  { Header: "Username", accessor: "name", align: "left", width: "30%" },
  { Header: "Email", accessor: "email", align: "center", width: "20%" },
  { Header: "Roles", accessor: "roles", align: "center", width: "20%" },
  { Header: "Created At", accessor: "created_at", align: "center", width: "20%" },
  { Header: "Updated At", accessor: "updated_at", align: "center", width: "20%" },
  { Header: "Action", accessor: "action", align: "center", width: "10%" },
];

function UserManagement() {
  const navigate = useNavigate();
  const [userToDelete, setUserToDelete] = useState(null);
  const [alertAndDialog, setAlertAndDialog] = useState(null);
  const [refetchToken, setRefetchToken] = useState(0);
  const authorizedRequest = useAuthorizedRequest();
  const userDataRow = ({
    id,
    name,
    email,
    roles,
    updated_at: updatedAt,
    created_at: createdAt,
    onRowDelete,
    onRowEdit,
  }) => ({
    name: (
      <MDBox display="flex" alignItems="center">
        <MDTypography display="block" variant="button" fontWeight="regular">
          {name}
        </MDTypography>
      </MDBox>
    ),
    email: (
      <MDBox display="flex" alignItems="center">
        <MDTypography display="block" variant="button" fontWeight="regular">
          {email}
        </MDTypography>
      </MDBox>
    ),
    roles: (
      <MDBox display="flex" alignItems="center">
        <MDTypography display="block" variant="button" fontWeight="regular">
          {roles
            ?.filter((x) => x.id !== 4)
            ?.map((x) => x.name)
            ?.join(", ")}
        </MDTypography>
      </MDBox>
    ),
    created_at: (
      <MDBox display="flex" alignItems="center">
        <MDTypography display="block" variant="button" fontWeight="regular">
          {moment(createdAt).format("DD-MM-YYYY HH:mm:ss")}
        </MDTypography>
      </MDBox>
    ),
    updated_at: (
      <MDBox display="flex" alignItems="center">
        <MDTypography display="block" variant="button" fontWeight="regular">
          {moment(updatedAt).format("DD-MM-YYYY HH:mm:ss")}
        </MDTypography>
      </MDBox>
    ),
    action: (
      <MDBox>
        <MDButton
          iconOnly
          color="info"
          onClick={onRowEdit && (() => onRowEdit(id))}
          variant="outlined"
        >
          <Icon fontSize="small" component="i" color="info">
            edit
          </Icon>
        </MDButton>
        <MDButton
          iconOnly
          color="error"
          onClick={onRowDelete && (() => onRowDelete(id, name))}
          variant="outlined"
        >
          <Icon fontSize="small" component="i" color="error">
            delete
          </Icon>
        </MDButton>
      </MDBox>
    ),
  });
  // const { columns: pColumns, rows: pRows } = projectsTableData();

  const handleDeleteWithConfirmation = (id, name) => {
    setUserToDelete({
      id,
      name,
    });
  };

  const handleRowDelete = async () => {
    setAlertAndDialog({
      ...alertAndDialog,
      isSubmitting: true,
    });
    await authorizedRequest.delete(`/usermanagement/user/${userToDelete?.id}`).catch((e) => {
      console.log(e);
      setAlertAndDialog({
        ...alertAndDialog,
        alertMessage: JSON.stringify(e.response.data.message),
        severity: "error",
        isSubmitting: false,
      });
      setUserToDelete(null);
      throw e;
    });

    setAlertAndDialog({
      ...alertAndDialog,
      alertMessage: "User Deleted",
      severity: "success",
      isSubmitting: false,
    });
    setUserToDelete(null);
    setRefetchToken(Date.now());
  };

  const handleRowEdit = (id) => {
    navigate(`/user-management/user-edit/${id}`);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FullScreenSpinner showOverlay={alertAndDialog?.Submitting} />
      <Dialog
        open={userToDelete ? true : false}
        onClose={() => setUserToDelete(null)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure want to delete {userToDelete?.name}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            autoFocus
            onClick={() => setUserToDelete(null)}
            variant="outlined"
            color="secondary"
          >
            No
          </MDButton>
          <MDButton onClick={handleRowDelete} autoFocus variant="outlined" color="error">
            Yes
          </MDButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={alertAndDialog?.message}
        autoHideDuration={5000}
        onClose={() => setAlertAndDialog({ ...alertAndDialog, message: "" })}
      >
        <Alert
          onClose={() => setAlertAndDialog({ ...alertAndDialog, message: "" })}
          severity={alertAndDialog?.severity}
          sx={{ width: "100%" }}
        >
          {alertAndDialog?.alertMessage}
        </Alert>
      </Snackbar>
      <MDBox pt={4} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item xs={8}>
                    <MDTypography variant="h6" color="white">
                      Users
                    </MDTypography>
                  </Grid>
                  <Grid item xs={4} align="right">
                    <MDButton component="a" href="./user-management/user-add">
                      Add User
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <RemoteTable
                  columnData={userDataColumns}
                  // isSorted
                  queryArgs={{
                    queryKey: "/usermanagement/user",
                    defaultSortKey: "id",
                    sortDir: "asc",
                  }}
                  refetchToken={refetchToken}
                  onRowDelete={handleDeleteWithConfirmation}
                  onRowEdit={handleRowEdit}
                  // onRowPrint={printQR}
                  pagination={{ variant: "contained", color: "primary" }}
                  rowComponent={userDataRow}
                  entriesPerPage={{ entries: [10, 20, 40] }}
                  showTotalEntries
                  noEndBorder
                  canSearch
                  // onRowClick={handleOpenStockDialog}
                  // exportData
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserManagement;

/* eslint-disable no-undef */
/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { NumericFormat } from "react-number-format";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Alert, Snackbar } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import useAuthorizedRequest from "components/AuthorizedRequest";
import _ from "lodash";

function NewPayment() {
  const abortController = new AbortController();
  const authorizedReq = useAuthorizedRequest();

  const [inputSupplierValue, setInputSupplierValue] = useState("");
  const [fetchedSupplier, setFetchedSupplier] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState({
    id: 0,
  });

  const [fromDateSelected, setFromDateSelected] = useState(null);
  const [toDateSelected, setToDateSelected] = useState(null);

  const [receiptData, setReceiptData] = useState([]);
  const [paymentNote, setPaymentNote] = useState("");

  const [paymentSubmit, setPaymentSubmit] = useState(null);
  const [receiptAmountTotal, setReceiptAmountTotal] = useState(0);

  const [snackProps, setSnackProps] = useState({
    message: "",
    severity: "info",
    isOpen: false,
  });

  const getSupplier = (input) => {
    authorizedReq
      .get(`/supplier?search=${input}`, {
        signal: abortController.signal,
      })
      .then((res) => {
        // let newOptions = [];
        setFetchedSupplier(
          res.data.data.map((x) => ({
            id: x.id,
            supplier_name: x.supplier_name,
          }))
        );
      })
      .catch((err) => {
        setSnackProps({
          message: err.cancel,
          severity: "error",
          isOpen: false,
        });
      });
  };

  const arrangePaymentData = () => {
    if (
      typeof selectedSupplier === "undefined" ||
      selectedSupplier?.id === 0 ||
      typeof selectedSupplier?.id === "undefined"
    ) {
      return;
    }
    setPaymentSubmit({
      note: paymentNote,
      receipt_ids: receiptData.map((x) => x.id),
      supplier_id: selectedSupplier.id,
    });
  };

  const handleSubmitPayment = () => {
    authorizedReq
      .post("/receipt/payment", paymentSubmit, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(() => {
        setSnackProps({
          isOpen: true,
          message: "New Payment Saved",
          severity: "success",
        });
        setInputSupplierValue("");
        setFetchedSupplier([]);
        setSelectedSupplier([]);
        setFromDateSelected(null);
        setToDateSelected(null);
        setReceiptData([]);
        setPaymentNote("");
      })
      .catch((err) => {
        setSnackProps({
          isOpen: true,
          message: `${err.response.data.message}`,
          severity: "error",
        });
      });
  };

  const handleSnackClose = () => {
    setSnackProps({ ...snackProps, isOpen: false });
  };

  const handleSearchReceipt = () => {
    authorizedReq
      .get(
        `/receipt/payment/prepare?from_date=${moment(fromDateSelected).format(
          "yyyy-MM-DD"
        )}&to_date=${moment(toDateSelected).format("yyyy-MM-DD")}&supplier=${selectedSupplier.id}`,
        {
          cancelToken: abortController.token,
        }
      )
      .then((res) => {
        setReceiptData(res.data);
      })
      .catch((err) => {
        setSnackProps({
          isOpen: true,
          message: `${err.response.data.message}`,
          severity: "error",
        });
      });
  };

  const getTotalAmount = () => {
    if (!receiptData) return;
    const totalAmount = receiptData.reduce(
      (dataPrev, dataNext) => dataPrev + Number(dataNext.payment_amount),
      0
    );
    setReceiptAmountTotal(totalAmount);
  };

  useEffect(() => {
    getTotalAmount();
  }, [receiptData]);

  useEffect(() => {
    if (inputSupplierValue === "") {
      setFetchedSupplier(selectedSupplier ? [selectedSupplier] : [{ id: 0 }]);
      return undefined;
    }
    _.debounce(() => {
      getSupplier(inputSupplierValue);
    }, 100)();

    return () => abortController.abort();
  }, [selectedSupplier, inputSupplierValue]);

  useEffect(() => {
    arrangePaymentData();
  }, [receiptData, paymentNote, selectedSupplier]);

  return (
    <DashboardLayout>
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackProps.isOpen}
          onClose={handleSnackClose}
          autoHideDuration={6000}
        >
          <Alert onClose={handleSnackClose} severity={snackProps.severity} sx={{ width: "100%" }}>
            {snackProps.message}
          </Alert>
        </Snackbar>
      </div>
      <DashboardNavbar />
      {/* This is hidden on purpose } */}
      {/* <div style={{ display: "none" }}>{ThingToPrint}</div> */}
      {/* {TODO this button is to be removed} */}
      {/* <MDButton onClick={() => printQR("test", "test1, test2")}>Print</MDButton> */}
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox
                  component="form"
                  role="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmitPayment();
                  }}
                >
                  <MDBox mb={2} mt={2}>
                    <MDTypography variant="text">Supplier Name</MDTypography>
                    <Autocomplete
                      freeSolo
                      id="combo-box-demo"
                      value={selectedSupplier}
                      onChange={(e, d) => setSelectedSupplier(d)}
                      options={fetchedSupplier}
                      getOptionLabel={(option) => option.supplier_name || ""}
                      filterSelectedOptions
                      filterOptions={(x) => x}
                      onInputChange={(event, newInputSupplierValue) => {
                        setInputSupplierValue(newInputSupplierValue);
                      }}
                      renderInput={(params) => <MDInput {...params} placeholder="supplier name" />}
                    />
                  </MDBox>
                  <Grid container>
                    <Grid item xs={12} lg={12}>
                      <MDBox mb={2}>
                        <MDTypography variant="text">From</MDTypography>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            mask="__-__-____"
                            inputFormat="DD-MM-yyyy"
                            value={fromDateSelected}
                            onChange={(newValue) => {
                              setFromDateSelected(newValue);
                            }}
                            disableFuture
                            renderInput={(params) => (
                              <MDInput fullWidth placeholder="select date" {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <MDBox mb={2}>
                        <MDTypography variant="text">To</MDTypography>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            mask="__-__-____"
                            inputFormat="DD-MM-yyyy"
                            value={toDateSelected}
                            onChange={(newValue) => {
                              setToDateSelected(newValue);
                            }}
                            disableFuture
                            renderInput={(params) => (
                              <MDInput fullWidth placeholder="select date" {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </MDBox>
                    </Grid>
                  </Grid>
                  <MDBox mb={2}>
                    <MDTypography variant="text">Payment Notes</MDTypography>
                    <MDInput
                      value={paymentNote}
                      onChange={(e) => setPaymentNote(e.target.value)}
                      type="text"
                      fullWidth
                      placeholder="payment notes"
                    />
                  </MDBox>
                  <MDBox mt={1} mb={2}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={handleSearchReceipt}
                    >
                      Search Receipts
                    </MDButton>
                  </MDBox>
                  <hr />
                  <MDBox mt={4} mb={1}>
                    <DataTable
                      table={{
                        columns: [
                          { Header: "Receipt ID", accessor: "receipt_id", width: "50%" },
                          { Header: "Items Count", accessor: "items", width: "20%" },
                          { Header: "Payment Amount", accessor: "payment_amount", width: "20%" },
                          { Header: "Receipt Date", accessor: "date", width: "10%" },
                        ],
                        // eslint-disable-next-line no-return-assign
                        rows: receiptData.map((x) => ({
                          receipt_id: (
                            <MDBox display="flex" alignItems="center">
                              <MDTypography display="block" variant="text" fontWeight="regular">
                                {x.receipt_id}
                              </MDTypography>
                            </MDBox>
                          ),
                          items: (
                            <MDBox display="flex" alignItems="center">
                              <MDTypography display="block" variant="text" fontWeight="regular">
                                <NumericFormat
                                  displayType="text"
                                  value={x.items}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  suffix=" pcs"
                                />
                              </MDTypography>
                            </MDBox>
                          ),
                          payment_amount: (
                            <MDBox display="flex" alignItems="center">
                              <MDTypography display="block" variant="text" fontWeight="regular">
                                <NumericFormat
                                  displayType="text"
                                  value={x.payment_amount}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix="Rp "
                                />
                              </MDTypography>
                            </MDBox>
                          ),
                          date: (
                            <MDBox display="flex" alignItems="center">
                              <MDTypography display="block" variant="text" fontWeight="regular">
                                {x.date}
                              </MDTypography>
                            </MDBox>
                          ),
                        })),
                      }}
                      isSorted={false}
                      showTotalAmount
                      amountTotal={receiptAmountTotal}
                    />
                  </MDBox>
                  <hr />
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="success"
                      fullWidth
                      onClick={handleSubmitPayment}
                    >
                      Submit Receipt
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewPayment;

import excel4node from "excel4node";
import jwtDecode from "jwt-decode";
import FileSaver from "file-saver";
import moment from "moment";
import Icon from "@mui/material/Icon";
import { useEffect, useState, useRef } from "react";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import useAuthorizedRequest from "components/AuthorizedRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function arrayRemove(arr, val) {
  return arr.filter((ele) => ele.Header !== val);
}

const wb = new excel4node.Workbook();
const ws = wb.addWorksheet("SampleSheet");
const regOnlyNumber = /^[0-9]+$/;
const tableHeadStyle = wb.createStyle({
  font: {
    color: "000000",
    size: 12,
    bold: true,
  },
  border: {
    left: {
      style: "medium",
    },
    right: {
      style: "medium",
    },
    top: {
      style: "medium",
    },
    bottom: {
      style: "medium",
    },
  },
});
const tableRowStyle = wb.createStyle({
  font: {
    color: "000000",
    size: 12,
  },
  border: {
    left: {
      style: "medium",
    },
    right: {
      style: "medium",
    },
    top: {
      style: "medium",
    },
    bottom: {
      style: "medium",
    },
  },
});
const headStyle = wb.createStyle({
  font: {
    color: "000000",
    size: 12,
    bold: true,
  },
});

// eslint-disable-next-line import/prefer-default-export, no-unused-vars
export function ExcelReport({ columnContent, fileName, urlExport }) {
  const abortController = useRef(new AbortController());
  const authorizedReq = useAuthorizedRequest();
  const [issuerName, setIssuerName] = useState("");
  const [issueDate, setIssueDate] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [columnName, setColumnName] = useState([]);
  const [rowName, setRowName] = useState([]);
  const [cachedUrl, setCachedUrl] = useState("");
  const [loadingButton, setLoadingButton] = useState(true);
  const [exportRequested, setExportRequested] = useState(false);
  const userToken = localStorage.getItem("access_token");

  const cellProps = {
    headerContent: {
      startRow: 1,
      startCol: 1,
      endRow: 3,
      endCol: 2,
    },
    tableHead: {
      startRow: 5,
      startCol: 1,
      endRow: 5,
      endCol: 6,
    },
    tableBody: {
      startRow: 6,
      startCol: 1,
    },
  };

  const getName = (usrToken) => {
    const tokenData = usrToken && jwtDecode(usrToken);
    const tokenValid = tokenData && tokenData.exp > Math.floor(new Date() / 1000);
    // Check if token expired
    if (!usrToken || !tokenValid) return;
    const userName = tokenData && tokenData.name;
    setIssuerName(userName);
  };

  const getIssueDate = (date) => {
    setIssueDate(moment(date).format("yyyy-MM-DD"));
  };

  const writeCell = (cell, data, type) => {
    if (cell.length === 4 && type === "string") {
      ws.cell(cell[0], cell[1], cell[2], cell[3], true).string(data);
      return;
    }
    switch (type) {
      case "string":
        ws.cell(cell[0], cell[1]).cells[0].string(data);
        break;
      case "number":
        ws.cell(cell[0], cell[1]).number(data);
        break;
      case "formula":
        ws.cell(cell[0], cell[1]).formula(data);
        break;
      case "date":
        ws.cell(cell[0], cell[1]).date(moment(data).format("yyyy-MM-DD"));
        break;
      case "link":
        ws.cell(cell[0], cell[1]).link(data);
        break;
      case "bool":
        ws.cell(cell[0], cell[1]).bool(data);
        break;
      default:
        ws.cell(cell[0], cell[1]).string("undefined");
        break;
    }
  };

  const setRowAndHeader = (header, row) => {
    if (!row.length || !header.length) return;
    const dataHeaders = arrayRemove(header, "Action");
    const headers = dataHeaders.map((x) => ({
      value: x.accessor,
      type: typeof x.accessor,
    }));
    // eslint-disable-next-line camelcase
    const newArray = row.map(({ item_id, stock_id, id, ...keepAttrs }) => keepAttrs);
    const rows = newArray.map((x) =>
      headers.map((y) => ({
        value: regOnlyNumber.test(x[y.value]) ? parseInt(x[y.value], 10) : x[y.value],
        type: regOnlyNumber.test(x[y.value]) ? "number" : "string",
      }))
    );
    const totalAmountArr = row.map((x) => Number(x.total_amount ? x.total_amount : 0));

    setTotalAmount(totalAmountArr?.reduce((x, y) => x + y, 0));

    setColumnName(headers);
    setRowName(rows);
  };

  const handleRecallApi = async (url, column, isMounted) => {
    if (!isMounted) return;
    const resp = await authorizedReq
      .get(`${process.env.REACT_APP_API_BASE_URL}${url}`, {
        signal: abortController.current.signal,
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e.message);
        // eslint-disable-next-line no-useless-return
        return;
      });
    if (isMounted && resp?.data) {
      setRowAndHeader(column, resp.data.data);
      setLoadingButton(false);
    }
  };

  const writeIssuer = (issuer) => {
    writeCell([1, 1], "Issuer Name:", "string");
    writeCell([1, 2], issuer, typeof issuer);
  };

  const writeIssueDate = (date) => {
    writeCell([2, 1], "Issue Date:", "string");
    writeCell([2, 2], date, typeof date);
  };

  const writeTotalAmount = (amount) => {
    if (!totalAmount) {
      writeCell([3, 1], "", "string");
      writeCell([3, 2], "", "string");
      return;
    }
    writeCell([3, 1], "Total Amount:", "string");
    writeCell([3, 2], amount, typeof amount);
  };

  const writeColumn = (column) => {
    column.map((x, i) => writeCell([5, i + 1], x.value, x.type));
  };

  const writeRow = (row) => {
    row.map((x, i) => x.map((y, j) => writeCell([i + 6, j + 1], y.value, y.type)));
  };

  const writeAll = (issueName, dateIssue, column, row) => {
    writeIssuer(issueName);
    writeIssueDate(dateIssue);
    writeTotalAmount(totalAmount);
    writeColumn(column);
    writeRow(row);
    /*      start row, start column, end row, end column */
    ws.cell(
      cellProps.headerContent.startRow,
      cellProps.headerContent.startCol,
      cellProps.headerContent.endRow,
      cellProps.headerContent.endCol
    ).style(headStyle);
    ws.cell(
      cellProps.tableHead.startRow,
      cellProps.tableHead.startCol,
      cellProps.tableHead.endRow,
      column.length
    ).style(tableHeadStyle);
    ws.cell(
      cellProps.tableBody.startRow,
      cellProps.tableBody.startCol,
      row.length + cellProps.tableHead.endRow,
      column.length
    ).style(tableRowStyle);
  };

  const handleExportButton = () => {
    setLoadingButton(true);
    writeAll(issuerName, issueDate, columnName, rowName);
    setExportRequested(true);
  };

  useEffect(() => {
    if (!exportRequested) return;

    wb.writeToBuffer().then((buffer) => {
      const fileToSave = new Blob([buffer.buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });

      FileSaver.saveAs(fileToSave, `${moment(Date.now()).format("yyyy-MM-DD")} Export ${fileName}`);
    });

    setExportRequested(false);
    setLoadingButton(false);
  }, [exportRequested, setExportRequested, wb]);

  useEffect(async () => {
    if (urlExport === cachedUrl) return;
    let isMounted = true;
    setCachedUrl(urlExport);

    await handleRecallApi(urlExport, columnContent, isMounted);

    // eslint-disable-next-line consistent-return, no-return-assign
    return () => {
      isMounted = false;
      abortController.current.abort();
    };
  }, [urlExport, columnContent, rowName, columnName, handleRecallApi]);

  useEffect(() => {
    getName(userToken);
    getIssueDate(Date.now());
  }, [userToken, getName, getIssueDate]);

  useEffect(() => () => abortController.current.abort(), []);

  return loadingButton ? (
    <MDButton variant="outlined" color="info" align="center" disabled>
      Wait.. <FontAwesomeIcon icon="circle-notch" spin />
    </MDButton>
  ) : (
    <MDButton
      variant="outlined"
      color="info"
      component="a"
      onClick={handleExportButton}
      align="center"
    >
      <Icon fontSize="small" color="info">
        file_download
      </Icon>{" "}
      Download
    </MDButton>
  );
}

ExcelReport.defaultProps = {
  columnContent: [],
  fileName: "Export",
};

ExcelReport.propTypes = {
  columnContent: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]))
  ),
  fileName: PropTypes.string,
  urlExport: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
};

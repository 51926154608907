/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// snackbar
import { Alert, Snackbar } from "@mui/material";

// Dialog/Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// Data table
import DataTable from "examples/Tables/DataTable";
import "../../../assets/styles/style.css";

// Data
import ReceiptData from "layouts/warehouse/receipt-payment/data/receiptPaymentData";
import RemoteTable from "components/RemoteTable";
import useAuthorizedRequest from "components/AuthorizedRequest";
import { ReceiptDataRow } from "./data/receiptPaymentData";

function ReceiptPayment() {
  const cancellationSource = axios.CancelToken.source();
  const navigate = useNavigate();
  const { columns: pColumns } = ReceiptData();
  const authorizedReq = useAuthorizedRequest();

  const [openPaymentDetailsDialog, setOpenPaymentDetailsDialog] = useState(false);
  const paymentDialogTheme = useTheme();
  const paymentDialogFullScreen = useMediaQuery(paymentDialogTheme.breakpoints.down("md"));

  const [openReceiptDetailsDialog, setOpenReceiptDetailsDialog] = useState(false);
  const receiptDialogTheme = useTheme();
  const receiptDialogFullScreen = useMediaQuery(receiptDialogTheme.breakpoints.down("md"));

  const [idReceiptChoosed, setIdReceiptChoosed] = useState(null);
  const [receiptChoosed, setReceiptChoosed] = useState({
    receipt_code: "",
    date: "",
    receipt_note: "",
    supplier_name: "",
    payment_status: "",
  });

  const [idPaymentChoosed, setIdPaymentChoosed] = useState(null);
  const [paymentChoosed, setPaymentChoosed] = useState({
    supplier_name: "",
    created_at: "",
    total_item_count: 0,
    total_payment: 0,
  });

  const [paymentData, setPaymentData] = useState([]);
  const [receiptData, setReceiptData] = useState([]);

  const handleClosePaymentDetailsDialog = () => {
    setOpenPaymentDetailsDialog(false);
  };

  const handleOpenPaymentDetailsDialog = (row) => {
    if (idPaymentChoosed !== row.original.id.value) {
      setPaymentChoosed([]);
      setPaymentData([]);
    }
    setOpenReceiptDetailsDialog(false);
    setOpenPaymentDetailsDialog(true);
    setIdPaymentChoosed(row.original.id.value);
  };

  const handleCloseReceiptDetailsDialog = () => {
    setOpenReceiptDetailsDialog(false);
    setOpenPaymentDetailsDialog(true);
  };

  const handleOpenReceiptDetailsDialog = (row) => {
    if (idReceiptChoosed !== row.original.id.value) {
      setReceiptChoosed([]);
      setReceiptData([]);
    }
    setOpenPaymentDetailsDialog(false);
    setOpenReceiptDetailsDialog(true);
    setIdReceiptChoosed(row.original.id.value);
  };

  const [stateSnackbar, setStateSnackbar] = useState({
    openSnackbar: false,
    vertical: "top",
    horizontal: "right",
    severity: "info",
    message: "message",
  });
  const { vertical, horizontal, openSnackbar, severity, message } = stateSnackbar;

  const handleCloseSnackbar = () => {
    setStateSnackbar({ ...stateSnackbar, openSnackbar: false });
  };

  const getReceipt = () => {
    if (idReceiptChoosed === null) {
      return;
    }
    authorizedReq
      .get(`${process.env.REACT_APP_API_BASE_URL}/receipt/${idReceiptChoosed}`, {
        cancelToken: cancellationSource.token,
      })
      .then((res) => {
        setReceiptData(res.data.items);
        setReceiptChoosed({
          supplier_name: res.data.supplier_name,
          date: res.data.date,
          payment_status: res.data.payment_status,
          receipt_code: res.data.receipt_code,
          receipt_note: res.data.receipt_note,
        });
      })
      .catch(() => {
        setStateSnackbar({
          ...stateSnackbar,
          openSnackbar: true,
          severity: "error",
          message: "Get Receipt Error",
        });
      });
  };

  const getPayment = () => {
    if (idPaymentChoosed === null) {
      return;
    }
    authorizedReq
      .get(`${process.env.REACT_APP_API_BASE_URL}/receipt/payment/${idPaymentChoosed}`, {
        cancelToken: cancellationSource.token,
      })
      .then((res) => {
        setPaymentData(res.data.receipts);
        setPaymentChoosed({
          supplier_name: res.data.supplier_name,
          created_at: res.data.created_at,
          total_item_count: res.data.total_item_count,
          total_payment: res.data.total_payment,
        });
      })
      .catch(() => {
        setStateSnackbar({
          ...stateSnackbar,
          openSnackbar: true,
          severity: "error",
          message: "Get Payment Error",
        });
      });
  };

  useEffect(() => {
    getPayment();
  }, [idPaymentChoosed]);

  useEffect(() => {
    getReceipt();
  }, [idReceiptChoosed]);

  return (
    <DashboardLayout>
      <Dialog
        fullScreen={paymentDialogFullScreen}
        open={openPaymentDetailsDialog}
        onClose={handleClosePaymentDetailsDialog}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
        fullWidth
      >
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12} align="center">
              <MDBox mt={4} mb={1}>
                <MDBox display="flex" alignItems="center">
                  <MDTypography display="block" variant="button" fontWeight="regular">
                    Supplier Name : {paymentChoosed.supplier_name}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItems="center">
                  <MDTypography display="block" variant="button" fontWeight="regular">
                    Payment Date : {paymentChoosed.created_at}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItems="center">
                  <MDTypography display="block" variant="button" fontWeight="regular">
                    Items Count :
                    <NumericFormat
                      displayType="text"
                      value={paymentChoosed.total_item_count}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix=" "
                      suffix=" pcs"
                    />
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItems="center">
                  <MDTypography display="block" variant="button" fontWeight="regular">
                    {/* eslint no-multi-spaces: "error" */}
                    Total Payment :
                    <NumericFormat
                      displayType="text"
                      value={paymentChoosed.total_payment}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix=" Rp "
                    />
                  </MDTypography>
                </MDBox>
                <br />
                <DataTable
                  table={{
                    columns: [
                      { Header: "Receipt ID", accessor: "receipt_no", width: "25%" },
                      { Header: "Receipt Date", accessor: "date", width: "25%" },
                      { Header: "Items Count", accessor: "item_count", width: "25%" },
                      { Header: "Payment Amount", accessor: "payment_amount", width: "25%" },
                    ],
                    rows: paymentData.map((x) => ({
                      id: {
                        value: x.receipt_id,
                        hidden: true,
                      },
                      receipt_id: {
                        value: x.receipt_no,
                        hidden: true,
                      },
                      receipt_no: (
                        <MDBox display="flex" alignItems="center">
                          <MDTypography display="block" variant="text" fontWeight="regular">
                            {x.receipt_no}
                          </MDTypography>
                        </MDBox>
                      ),
                      date: (
                        <MDBox display="flex" alignItems="center">
                          <MDTypography display="block" variant="text" fontWeight="regular">
                            {x.date}
                          </MDTypography>
                        </MDBox>
                      ),
                      item_count: (
                        <MDBox display="flex" alignItems="center">
                          <NumericFormat
                            displayType="text"
                            value={x.item_count}
                            thousandSeparator="."
                            decimalSeparator=","
                            suffix=" pcs"
                          />
                        </MDBox>
                      ),
                      payment_amount: (
                        <MDBox display="flex" alignItems="center">
                          <NumericFormat
                            displayType="text"
                            value={x.payment_amount}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="Rp "
                          />
                        </MDBox>
                      ),
                    })),
                  }}
                  isSorted={false}
                  onRowClick={handleOpenReceiptDetailsDialog}
                />
              </MDBox>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton
            autoFocus
            onClick={handleClosePaymentDetailsDialog}
            variant="outlined"
            color="error"
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={receiptDialogFullScreen}
        open={openReceiptDetailsDialog}
        onClose={handleCloseReceiptDetailsDialog}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
        fullWidth
      >
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12} align="center">
              <MDBox display="flex" alignItems="center">
                <MDTypography display="block" variant="button" fontWeight="regular">
                  Receipt Code : {receiptChoosed.receipt_code}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItems="center">
                <MDTypography display="block" variant="button" fontWeight="regular">
                  Supplier Name : {receiptChoosed.supplier_name}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItems="center">
                <MDTypography display="block" variant="button" fontWeight="regular">
                  Receipt Date : {receiptChoosed.date}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItems="center">
                <MDTypography display="block" variant="button" fontWeight="regular">
                  Payment Status : {receiptChoosed.payment_status}
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <DataTable
                  table={{
                    columns: [
                      { Header: "Stock Name", accessor: "product_name", width: "30%" },
                      { Header: "Stock Tags", accessor: "stock_tags", width: "20%" },
                      { Header: "Quantity Bought", accessor: "qty_bought", width: "20%" },
                      { Header: "Quantity Left", accessor: "qty_left", width: "20%" },
                      { Header: "Buy Price", accessor: "buy_price", width: "10%" },
                    ],
                    rows: receiptData.map((x) => ({
                      product_name: (
                        <MDBox display="flex" alignItems="center">
                          <MDTypography display="block" variant="text" fontWeight="regular">
                            {x.product_name}
                          </MDTypography>
                        </MDBox>
                      ),
                      stock_tags: (
                        <MDBox display="flex" alignItems="center">
                          <MDTypography display="block" variant="text" fontWeight="regular">
                            {x.stock_tags}
                          </MDTypography>
                        </MDBox>
                      ),
                      qty_bought: (
                        <MDBox display="flex" alignItems="center">
                          <NumericFormat
                            displayType="text"
                            value={x.qty_bought}
                            thousandSeparator="."
                            decimalSeparator=","
                            suffix=" pcs"
                          />
                        </MDBox>
                      ),
                      qty_left: (
                        <MDBox display="flex" alignItems="center">
                          <NumericFormat
                            displayType="text"
                            value={x.qty_left}
                            thousandSeparator="."
                            decimalSeparator=","
                            suffix=" pcs"
                          />
                        </MDBox>
                      ),
                      buy_price: (
                        <MDBox display="flex" alignItems="center">
                          <NumericFormat
                            displayType="text"
                            value={x.buy_price}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="Rp "
                          />
                        </MDBox>
                      ),
                    })),
                  }}
                  isSorted={false}
                />
              </MDBox>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton
            autoFocus
            onClick={handleCloseReceiptDetailsDialog}
            variant="outlined"
            color="error"
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item xs={12} lg={6} mt={2}>
                    <MDTypography variant="h6" color="white">
                      Receipt Payment
                    </MDTypography>
                  </Grid>
                  <Grid item lg={3} />
                  <Grid item xs={12} lg={2} mb={2} mt={2} align="right">
                    <MDButton
                      component="a"
                      // color="success"
                      onClick={() => navigate("/warehouse/receipt-payment/new-payment")}
                      sx={{ display: { xs: "block", lg: "inline" } }}
                    >
                      New Payment
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <RemoteTable
                  columnData={pColumns}
                  queryArgs={{
                    queryKey: "/receipt/payment",
                    defaultSortKey: "created_at",
                    sortDir: "desc",
                  }}
                  onRowDelete={handleOpenReceiptDetailsDialog}
                  onRowEdit={handleOpenReceiptDetailsDialog}
                  onRowClick={handleOpenPaymentDetailsDialog}
                  pagination={{ variant: "contained", color: "primary" }}
                  rowComponent={ReceiptDataRow}
                  entriesPerPage={{ entries: [10, 20, 40] }}
                  showTotalEntries
                  noEndBorder
                  canSearch
                  totalAmountColumn="payment_amount"
                  canFilterDate
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ReceiptPayment;

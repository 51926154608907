import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
// import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {
  Alert,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  Snackbar,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  Checkbox,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import BluetoothConnectedIcon from "@mui/icons-material/BluetoothConnected";
import beepAudio from "assets/sounds/beep.mp3";
import { Howl } from "howler";
// import useAuthorizedRequest from "components/AuthorizedRequest";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import FullScreenSpinner from "components/FullScreenSpinner";
import useAuthorizedRequest from "components/AuthorizedRequest";
import { useParams } from "react-router";
import SalesReturnTable from "./sales-return-table";

function Sales() {
  const navigate = useNavigate();
  const { transactionMedia } = useParams();
  const beep = new Howl({
    src: [beepAudio],
  });
  const authorizedRequester = useAuthorizedRequest();
  const [BTDevice, setBTDevice] = useState("");
  const [qrData, setQrData] = useState("");
  const [errorMessage] = useState("");
  const [selectedScanner, setSelectedScanner] = useState("External Scanner");
  const [scannerRemountToken, setScannerRemountToken] = useState(Date.now());
  const [submissionRequested, setSubmissionRequested] = useState(false);

  // const [scannerInput, setScannerInput] = useState("");
  const [salesReturnData, setSalesReturnData] = useState({
    sales_id: "",
    transaction_id: "",
    operator: "",
    created_at: "",
    items: [],
  });
  const [confirmSubmit, setConfirmSubmit] = useState({
    showDialog: false,
    isValidating: false,
    isSubmitting: false,
    snackMessage: "",
    alertSeverity: "info",
    showAlert: false,
  });
  const tempQRData = useRef();
const handleBluetoothClick = async () => {
    console.log("Requesting Bluetooth Device...");
    const gattServer = await navigator.bluetooth
      .requestDevice({
        filters: [
          {
            services: [
              "0000fff0-0000-1000-8000-00805f9b34fb",
              "0000feea-0000-1000-8000-00805f9b34fb",
            ],
          },
          { namePrefix: "BarCode" },
        ],
        optionalServices: [
          "0000fff0-0000-1000-8000-00805f9b34fb",
          "0000feea-0000-1000-8000-00805f9b34fb",
        ],
      })
      .then((device) => {
        console.log("Connecting to GATT Server...");
        setBTDevice(device.name);
        return device.gatt.connect();
      })
      .catch((error) => {
        setBTDevice("");
        console.log("Argh! ", error);
      });

    let targetCharacteristic = "";
    let primaryService = await gattServer
      .getPrimaryService("0000fff0-0000-1000-8000-00805f9b34fb")
      .catch((e) => console.log(e));
    targetCharacteristic = "0000fff1-0000-1000-8000-00805f9b34fb";
    if (!primaryService?.uuid) {
      primaryService = await gattServer.getPrimaryService("0000feea-0000-1000-8000-00805f9b34fb");
      targetCharacteristic = "00002aa1-0000-1000-8000-00805f9b34fb";
    }
    primaryService
      .getCharacteristic(targetCharacteristic)
      .then((characteristic) => {
        characteristic.addEventListener("gattserverdisconnected", () => setBTDevice(""));
        characteristic.addEventListener("characteristicvaluechanged", (e) => {
          const dec = new TextDecoder("utf-8");
          const decodedData = dec.decode(e.target.value.buffer);
          tempQRData.current = tempQRData.current
            ? (tempQRData.current += decodedData)
            : decodedData;
          if (/\r|\n/.exec(decodedData)) {
            // Do something, the string contains a line break
            setQrData(tempQRData.current.slice(0, -1));
            tempQRData.current = "";
          }
        });
        characteristic.startNotifications();
      })
      .catch((error) => {
        setBTDevice("");
        console.log("Argh! ", error);
      });
  };

  const handleQRScanResult = async (qrErr, qrRes) => {
    if (qrRes) {
      beep.play();
      setQrData(qrRes?.text);
    }

    if (qrErr) {
      // setIsError(true);
      // handleSetErrorMessage(qrErr);
      // console.info(qrErr);
      setQrData("");
    }
  };

  const proceedReturn = async (e, data) => {
    e.preventDefault();
    setConfirmSubmit({ ...confirmSubmit, isSubmitting: true });
    console.log(data);
    authorizedRequester
      .post(`${process.env.REACT_APP_API_BASE_URL}/sales/return`, {
        sales_id: data.sales_id,
        items: _.filter(data?.items, (item) => item.checked).map((checkedItem) => ({
          item_id: checkedItem.sales_item_id,
          qty: checkedItem.qty_to_return,
        })),
      })
      .then(() => {
        setConfirmSubmit({
          ...confirmSubmit,
          isSubmitting: false,
          showDialog: false,
          snackMessage: "Sales Returned OK",
          showAlert: true,
          alertSeverity: "success",
          salesId: 0,
        });
        setSalesReturnData({
          ...salesReturnData,
          items: [],
          transaction_id: "",
        });
      })
      .catch((err) => {
        console.log(err);
        setConfirmSubmit({
          ...confirmSubmit,
          isSubmitting: false,
          showDialog: false,
          snackMessage: "Failed to return sales",
          showAlert: true,
          alertSeverity: "error",
        });
      });
    setSalesReturnData({
      ...salesReturnData,
      transaction_id: "",
    });
    setQrData("");
  };

  const handleStatusChangeCallback = (salesItemId, checked) => {
    const returnDataIndex = _.findIndex(
      salesReturnData.items,
      (x) => x.sales_item_id === salesItemId
    );
    const newSalesItems = [...salesReturnData.items];
    newSalesItems[returnDataIndex].checked = checked;
    setSalesReturnData({ ...salesReturnData, items: newSalesItems });
  };

  const handleQtyToReturnChanged = (salesItemId, qty) => {
    const returnDataIndex = _.findIndex(
      salesReturnData.items,
      (x) => x.sales_item_id === salesItemId
    );
    const newSalesItems = [...salesReturnData.items];
    newSalesItems[returnDataIndex].qty_to_return = parseInt(qty, 10) || 0;
    setSalesReturnData({ ...salesReturnData, items: newSalesItems });
  };

  const handleReturnAllCheckbox = (checked) => {
    salesReturnData.isReturnAll = checked;

    if (checked) {
      setSalesReturnData({
        ...salesReturnData,
        items: salesReturnData.items.map((x) => ({
          ...x,
          checked: true,
          qty_to_return: x.qty - x.returned_qty,
        })),
      });
    } else {
      setSalesReturnData({
        ...salesReturnData,
        items: salesReturnData.items.map((x) => ({
          ...x,
          checked: false,
          qty_to_return: 0,
        })),
      });
    }
  };

  const handleSubmit = async (data) => {
    setConfirmSubmit({ ...confirmSubmit, isValidating: true });
    setSubmissionRequested(false);
    // validate returned data
    const returnQtyExceed = _.filter(data.items, (x) => x.qty_to_return + x.returned_qty > x.qty);

    if (returnQtyExceed.length) {
      setConfirmSubmit({
        ...confirmSubmit,
        isValidating: false,
        showDialog: false,
        showAlert: true,
        snackMessage: "Return Qty Exceed, Check Data!",
        alertSeverity: "error",
      });
      return;
    }

    setConfirmSubmit({
      ...confirmSubmit,
      isValidating: false,
      showDialog: true,
      salesId: data.transaction_id,
    });
  };

  const loadTransactionData = async (transactionId) => {
    setSalesReturnData({
      sales_id: "",
      transaction_id: "",
      operator: "",
      created_at: "",
      items: [],
    });
    authorizedRequester
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/sales/${transactionId}?use_transaction_id=true&transaction_media=O${transactionMedia.substring(
          1,
          10
        )}`
      )
      .then((res) => {
        if (res.data?.transaction_id) {
          setSalesReturnData({
            sales_id: res.data?.sales_id,
            transaction_id: res.data?.transaction_id,
            operator: res.data?.operator,
            created_at: res.data?.created_at,
            items: res.data?.items?.map((x) => ({
              ...x,
              qty_to_return: 0,
              checked: false,
            })),
          });
        } else {
          throw new Error("Not Found Error");
        }
      })
      .catch((e) => {
        console.log(e);
        setConfirmSubmit({
          ...confirmSubmit,
          isValidating: false,
          showAlert: true,
          alertSeverity: "error",
          snackMessage: "Transaction ID Not Found!",
        });
      });
  };

  useEffect(() => {
    if (!submissionRequested) return;
    handleSubmit(salesReturnData);
  }, [submissionRequested, salesReturnData]);

  useEffect(() => {
    setScannerRemountToken(Date.now());
  }, [selectedScanner]);

  useEffect(() => {
    setScannerRemountToken(Date.now());
  }, []);

  useEffect(async () => {
    if (!qrData) return;
    setSalesReturnData({
      ...salesReturnData,
      transaction_id: qrData,
    });

    await loadTransactionData(qrData);
  }, [qrData, setQrData, setSalesReturnData]);

  return (
    <DashboardLayout>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={confirmSubmit.showAlert}
        onClose={() => setConfirmSubmit({ ...confirmSubmit, showAlert: false })}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setConfirmSubmit({ ...confirmSubmit, showAlert: false })}
          severity={confirmSubmit.alertSeverity}
          sx={{ width: "100%" }}
        >
          {confirmSubmit.snackMessage}
        </Alert>
      </Snackbar>
      <FullScreenSpinner showOverlay={confirmSubmit.isSubmitting} />
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={confirmSubmit.showDialog}
      >
        <DialogTitle>Confirm Return</DialogTitle>
        <DialogContent>
          <MDTypography variant="button">
            {confirmSubmit.showDialog ? `Return Receipt: ${salesReturnData.transaction_id}` : ""}
          </MDTypography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setConfirmSubmit({ ...confirmSubmit, showDialog: false });
            }}
          >
            Cancel
          </Button>
          <Button onClick={(e) => proceedReturn(e, salesReturnData)}>Ok</Button>
        </DialogActions>
      </Dialog>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item xs={12} lg={10} mt={2}>
                    <MDTypography variant="h6" color="white">
                      {`O${transactionMedia.substring(1, 10)}`} Sales Return
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} lg={2} align="right">
                    <MDButton
                      component="a"
                      onClick={() => navigate(`/sales/return-history/${transactionMedia}`)}
                      sx={{ display: { xs: "block", lg: "inline" }, mt: { xs: 2 } }}
                    >
                      Sales Return History
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="text">Transaction ID</MDTypography>
                    <MDInput
                      type="input"
                      fullWidth
                      placeholder="input text"
                      value={qrData}
                      // onChange={setQrData(data)}
                      readOnly
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="medium"
                              onClick={(e) => {
                                e.preventDefault();
                                setQrData("");
                                setSalesReturnData({
                                  sales_id: "",
                                  transaction_id: "",
                                  operator: "",
                                  created_at: "",
                                  items: [],
                                });
                              }}
                              sx={{ zIndex: 999 }}
                            >
                              <Icon fontSize="small" component="i">
                                close
                              </Icon>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <MDBox
                      mt={1}
                      component="form"
                      role="form"
                      sx={{ display: selectedScanner === "External Scanner" ? "block" : "none" }}
                      onSubmit={(e) => {
                        e.preventDefault();
                        // console.log(e.target.scannerInputField.value);
                        setQrData(e.target.scannerInputField.value);
                        e.target.scannerInputField.value = "";
                        // setScannerInput("");
                      }}
                    >
                      <MDInput
                        type="input"
                        fullWidth
                        name="scannerInputField"
                        placeholder="Bluetooth QR and BarCode Scanner"
                        // value={scannerInput}
                        // onChange={(e) => setScannerInput(e.target.value)}
                      />
                    </MDBox>
                    <MDBox mt={1}>
                      <FormControl>
                        <FormLabel id="ScannerSelect">Select Scanner</FormLabel>
                        <RadioGroup
                          name="ScannerSelector"
                          value={selectedScanner}
                          row
                          onChange={(e) => setSelectedScanner(e.target.value)}
                        >
                          <FormControlLabel
                            value="External Scanner"
                            control={<Radio />}
                            label="External Scanner"
                          />
                          <FormControlLabel
                            value="Camera/Webcam"
                            control={<Radio />}
                            label="Camera/Webcam"
                          />
                        </RadioGroup>
                      </FormControl>
                      {selectedScanner === "External Scanner" ? (
                        <>
                          <MDBox mb={1}>
                            <Button
                              onClick={handleBluetoothClick}
                              variant="contained"
                              sx={{ mt: 2 }}
                              endIcon={<BluetoothConnectedIcon color="white" fontSize="large" />}
                            >
                              <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="white"
                                size="small"
                              >
                                Connect Bluetooth
                              </MDTypography>
                            </Button>
                          </MDBox>
                          <MDTypography
                            variant="caption"
                            fontWeight="light"
                            color="info"
                            sx={{ mt: 1, ml: 1 }}
                          >
                            {BTDevice ? `Connected: ${BTDevice}` : "Disconnected"}
                          </MDTypography>
                        </>
                      ) : null}
                    </MDBox>
                    <MDBox>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={salesReturnData.isReturnAll}
                            onChange={(e) => handleReturnAllCheckbox(e.target.checked)}
                          />
                        }
                        label="Return All"
                      />
                    </MDBox>
                    <LoadingButton
                      loadingPosition="center"
                      loading={confirmSubmit.isValidating}
                      loadingIndicator={
                        <CircularProgress
                          sx={{ color: "#0000CE" }}
                          role="progressbar"
                          thickness={10}
                          color="info"
                          size={20}
                        />
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setSubmissionRequested(true);
                      }}
                      variant="contained"
                      sx={{ width: "100%", mt: 2 }}
                      endIcon={<SendIcon color="white" fontSize="large" />}
                    >
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="white"
                        size="small"
                      >
                        Proceed Return
                      </MDTypography>
                    </LoadingButton>
                  </MDBox>
                  {selectedScanner === "Camera/Webcam" ? (
                    <MDBox mt={1} mb={1}>
                      <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs={12} md={6}>
                          <BarcodeScannerComponent
                            key={scannerRemountToken}
                            style={{ maxWidth: "100%" }}
                            delay={500}
                            onUpdate={handleQRScanResult}
                            stopStream={selectedScanner !== "Camera/Webcam"}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                  ) : null}
                  <MDBox mt={4} mb={1}>
                    <MDTypography variant="text" hidden>
                      {errorMessage}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        {SalesReturnTable({
          returnData: salesReturnData.items,
          checkedStatusChangeCallback: handleStatusChangeCallback,
          returnQtyChangedCallback: handleQtyToReturnChanged,
        })}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export default Sales;

/* eslint-disable react/prop-types */

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import moment from "moment";
import { NumericFormat } from "react-number-format";

export const CategoryDataRow = ({
  id,
  category_name: categoryName,
  products_count: productsCount,
  updated_at: updatedAt,
  status: categoryStatus,
  onRowDelete,
  onRowEdit,
}) => ({
  category_name: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        {categoryName}
      </MDTypography>
    </MDBox>
  ),
  products_count: (
    <MDBox display="flex" alignItems="center">
      <NumericFormat
        value={productsCount}
        displayType="text"
        thousandSeparator="."
        decimalSeparator=","
        suffix=" pcs"
      />
    </MDBox>
  ),
  status: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        {categoryStatus}
      </MDTypography>
    </MDBox>
  ),
  updated_at: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        {moment(updatedAt).format("DD-MM-YYYY HH:mm:ss")}
      </MDTypography>
    </MDBox>
  ),
  action: (
    <MDBox>
      <MDButton
        iconOnly
        color="info"
        onClick={onRowEdit && (() => onRowEdit(id))}
        variant="outlined"
      >
        <Icon fontSize="small" component="i" color="info">
          edit
        </Icon>
      </MDButton>
      <MDButton
        iconOnly
        color="error"
        onClick={onRowDelete && (() => onRowDelete(id, categoryName))}
        variant="outlined"
      >
        <Icon fontSize="small" component="i" color="error">
          delete
        </Icon>
      </MDButton>
    </MDBox>
  ),
});

export default function data() {
  const Item = ({ name }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Category name", accessor: "category_name", align: "left", width: "30%" },
      { Header: "Products Count", accessor: "products_count", align: "center", width: "20%" },
      { Header: "Status", accessor: "status", align: "center", width: "20%" },
      { Header: "Updated At", accessor: "updated_at", align: "center", width: "20%" },
      { Header: "Action", accessor: "action", align: "center", width: "10%" },
    ],

    rows: [
      {
        category_name: <Item name="Category1" />,
        status: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            active
          </MDTypography>
        ),
        updated_at: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            11-01-2022 13:37:50
          </MDTypography>
        ),
        action: (
          <MDBox>
            <MDButton iconOnly color="info" href="./category/category-edit" variant="outlined">
              <Icon fontSize="small" component="a" color="info">
                edit
              </Icon>
            </MDButton>
            <MDButton iconOnly color="error" href="./category/category-edit" variant="outlined">
              <Icon fontSize="small" component="a" color="error">
                delete
              </Icon>
            </MDButton>
          </MDBox>
        ),
      },
      {
        category_name: <Item name="Category2" />,
        status: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            active
          </MDTypography>
        ),
        updated_at: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            12-01-2022 09:11:03
          </MDTypography>
        ),
        action: (
          <MDBox>
            <MDButton iconOnly color="info" href="./category/category-edit" variant="outlined">
              <Icon fontSize="small" component="a" color="info">
                edit
              </Icon>
            </MDButton>
            <MDButton iconOnly color="error" href="./category/category-edit" variant="outlined">
              <Icon fontSize="small" component="a" color="error">
                delete
              </Icon>
            </MDButton>
          </MDBox>
        ),
      },
    ],
  };
}

/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";

// Date Pickers
import moment from "moment";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import { ExcelReport } from "components/ExcelReport";

// Material Dashboard 2 React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { useQuery } from "react-query";
import useAuthorizedRequest from "components/AuthorizedRequest";
import { NumericFormat } from "react-number-format";

const queryString = require("query-string");

function RemoteTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  columnData,
  pagination,
  isSorted,
  noEndBorder,
  queryArgs,
  rowComponent,
  onRowDelete,
  onRowEdit,
  onRowPrint,
  onRowClick,
  refetchToken,
  totalAmountColumn,
  canFilterDate,
  exportData,
  extraFilter,
  extraComponent,
}) {
  const axiosClient = useAuthorizedRequest();
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const [receiptAmountTotal, setReceiptAmountTotal] = useState(0);
  // const [entryRefreshToken, setEntryRefreshToken] = useState(refetchToken);
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  // const [ fetchedData, setFetchedData ] = useState([]);
  // const _axiosInstance = axiosInstance || new axios.client()
  // isError, isLoading, isSuccess
  const [tableRowData, setTableRowData] = useState([]);
  const [fetchedPageCount, setFetchedPageCount] = useState(0);
  const [remoteTotalEntries, setRemoteTotalEntries] = useState(0);
  const [urlParam, setUrlParam] = useState("");
  const [dateFilter, setDateFilter] = useState({
    to: null,
    from: null,
  });
  const [dateFilterClicked, setDateFilterClicked] = useState(0);
  const columns = useMemo(() => columnData, [columnData]);
  const rowData = useMemo(() => tableRowData, [tableRowData]);
  const tableInstance = useTable(
    {
      columns,
      data: rowData,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      manualGlobalFilter: true,
      manualSortBy: true,
      pageCount: fetchedPageCount,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  const { data: queryData } = useQuery(
    [
      queryArgs,
      globalFilter,
      pageIndex,
      headerGroups,
      refetchToken,
      pageSize,
      dateFilter.from,
      dateFilter.to,
      dateFilterClicked,
      extraFilter.params,
    ],
    async ({ signal }) => {
      const sorted = headerGroups[0].headers.filter((x) => x.isSorted === true);
      const sortBy = sorted && sorted.map((x) => x.id).join(",");
      const sortDir = sorted && sorted.map((x) => (x.isSortedDesc ? "desc" : "asc")).join(",");
      console.log(extraFilter.params);
      const extraSearchParams = extraFilter.params
        ? Object.keys(extraFilter?.params)
            .filter((x) => x !== null && x !== "" && extraFilter.params[x].length)
            .map(
              (filterKey) =>
                `${`${queryString.stringify(
                  {
                    [filterKey]: extraFilter.params[filterKey],
                  },
                  Array.isArray(extraFilter.params[filterKey]) && { arrayFormat: "bracket" }
                )}`}`
            )
            .join("&")
        : "";
      let urlWithParams = `${queryArgs.queryKey}?page=${pageIndex + 1}${
        typeof globalFilter !== "undefined" ? "&search=" : ""
      }${typeof globalFilter !== "undefined" ? globalFilter : ""}${
        sorted.length || queryArgs.defaultSortKey ? "&sort_by=" : ""
      }${sorted.length ? sortBy : queryArgs.defaultSortKey || ""}${
        sorted.length || queryArgs.defaultSortKey ? "&sort_dir=" : ""
      }${sorted.length ? sortDir : queryArgs.sortDir || ""}${
        canFilterDate && dateFilterClicked && dateFilter.from !== null ? "&from_date=" : ""
      }${canFilterDate && dateFilterClicked && dateFilter.from !== null ? dateFilter.from : ""}${
        canFilterDate && dateFilterClicked && dateFilter.to !== null ? "&to_date=" : ""
      }${canFilterDate && dateFilterClicked && dateFilter.to !== null ? dateFilter.to : ""}${
        typeof pageSize !== "undefined" ? "&page_size=" : ""
      }${typeof pageSize !== "undefined" ? pageSize : ""}${
        extraSearchParams ? `&${extraSearchParams}` : ""
      }`;
      if (queryArgs.extraParams) {
        queryArgs.extraParams.forEach((x) => {
          console.log(x);
          urlWithParams += `&${queryString.stringify(
            {
              [x.key]: x.value,
            },
            { arrayFormat: "bracket" }
          )}`;
        });
      }
      const axiosResp = await axiosClient.request({
        method: "GET",
        url: urlWithParams,
        signal,
      });
      setUrlParam(
        `${queryArgs.queryKey}?page=1&page_size=1000${
          dateFilter.from !== null ? "&from_date=" : ""
        }${dateFilter.from !== null ? dateFilter.from : ""}${
          dateFilter.to !== null ? "&to_date=" : ""
        }${dateFilter.to !== null ? dateFilter.to : ""}`
      );
      if (axiosResp) {
        setTableRowData(
          axiosResp.data.data.map((x) => rowComponent({ ...x, onRowDelete, onRowEdit, onRowPrint }))
        );
        if (totalAmountColumn !== "") {
          const totalAmount = axiosResp.data.data.reduce(
            (dataPrev, dataNext) => dataPrev + Number(dataNext[totalAmountColumn]),
            0
          );
          setReceiptAmountTotal(totalAmount);
        }
        setFetchedPageCount(Math.ceil(axiosResp.data.total / pageSize));
        setRemoteTotalEntries(axiosResp.data.total);
      }
      return axiosResp.data;
    },
    {
      retry: 2,
      enabled: true,
    }
  );

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => {
    if (pageSize !== value) gotoPage(0);
    setPageSize(value);
    // setEntryRefreshToken(Date.now());
  };

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;
    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length + pageIndex * pageSize;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  // refetch when date filter button is clicked
  const handleReceiptFilter = () => {
    if (dateFilter.to === null && dateFilter.from === null) {
      return;
    }
    setDateFilterClicked(dateFilterClicked + 1);
    gotoPage(0);
  };

  // refetch when clear filter is clicked
  const handleClearFilter = () => {
    setDateFilter({
      to: null,
      from: null,
    });
    setDateFilterClicked(0);
    gotoPage(0);
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {extraFilter && extraFilter.node}
      {canFilterDate && (
        <Grid container>
          <Grid item xs={12} lg={4} md={4}>
            <MDBox m={2}>
              <MDTypography variant="text" fontSize="medium">
                From
              </MDTypography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  mask="__-__-____"
                  inputFormat="DD-MM-yyyy"
                  // disableMaskedInput
                  value={dateFilter.from}
                  onChange={(newValue) => {
                    setDateFilter({
                      ...dateFilter,
                      from: moment(newValue).format("yyyy-MM-DD"),
                    });
                  }}
                  disableFuture
                  renderInput={(params) => (
                    <MDInput fullWidth placeholder="select date" {...params} />
                  )}
                />
              </LocalizationProvider>
            </MDBox>
          </Grid>
          {/* <Grid item xs={12} lg={2} md={2} /> */}
          <Grid item xs={12} lg={4} md={4}>
            <MDBox m={2}>
              <MDTypography variant="text" fontSize="medium">
                To
              </MDTypography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  mask="__-__-____"
                  inputFormat="DD-MM-yyyy"
                  value={dateFilter.to}
                  onChange={(newValue) => {
                    setDateFilter({
                      ...dateFilter,
                      to: moment(newValue).format("yyyy-MM-DD"),
                    });
                  }}
                  disableFuture
                  renderInput={(params) => (
                    <MDInput fullWidth placeholder="select date" {...params} />
                  )}
                />
              </LocalizationProvider>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={2} md={2} align="center" alignContent="center">
            <MDBox sx={{ my: { xs: 1, lg: 6, md: 6 }, mx: { xs: 2, md: 2, lg: 2 } }}>
              <MDButton
                component="a"
                color="info"
                variant="outlined"
                onClick={handleReceiptFilter}
                // sx={{ display: { xs: "block", lg: "inline" } }}
                fullWidth
                align="center"
              >
                <MDTypography variant="buton" fontSize="small" color="info">
                  <Icon fontSize="small" color="info">
                    filter_alt
                  </Icon>{" "}
                  Filter
                </MDTypography>
              </MDButton>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={2} md={2} align="center" alignContent="center">
            <MDBox sx={{ my: { xs: 1, lg: 6, md: 6 }, mx: { xs: 2, md: 2, lg: 2 } }}>
              <MDButton
                component="a"
                color="error"
                variant="outlined"
                onClick={handleClearFilter}
                // sx={{ display: { xs: "block", lg: "inline" } }}
                fullWidth
                align="center"
              >
                <MDTypography variant="buton" fontSize="small" color="error">
                  <Icon fontSize="small" color="error">
                    backspace
                  </Icon>{" "}
                  Clear
                </MDTypography>
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      )}
      {extraComponent ? <MDBox px={3}>{extraComponent({ queryData })}</MDBox> : null}
      {entriesPerPage || canSearch ? (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mt={3} px={3}>
          <Grid container>
            {entriesPerPage && (
              <Grid item xs={12} lg={2} md={3}>
                <MDBox display="flex" alignItems="center">
                  <Autocomplete
                    disableClearable
                    value={pageSize.toString()}
                    options={entries}
                    onChange={(event, newValue) => {
                      setEntriesPerPage(parseInt(newValue, 10));
                    }}
                    size="small"
                    sx={{ width: "5rem" }}
                    renderInput={(params) => <MDInput {...params} />}
                  />
                  <MDTypography variant="caption" color="secondary">
                    &nbsp;&nbsp;entries per page
                  </MDTypography>
                </MDBox>
              </Grid>
            )}
            {exportData && (
              <Grid item xs={12} lg={7} md={5}>
                <MDBox display="block" ml="auto">
                  <ExcelReport
                    columnContent={columns}
                    fileName={
                      // dateFilter.from === undefined ? "sample" : `${dateFilter.from} - ${dateFilter.to}`
                      dateFilter.from === null
                        ? `${queryArgs.queryKey.replace("/", "").replaceAll("/", "-")}`
                        : `${queryArgs.queryKey.replace("/", "").replaceAll("/", "-")} from ${
                            dateFilter.from
                          } to ${dateFilter.to}`
                    }
                    urlExport={urlParam}
                  />
                </MDBox>
              </Grid>
            )}
            {canSearch && (
              <Grid item xs={12} lg={3} md={4}>
                <MDBox ml="auto">
                  <MDInput
                    placeholder="Search..."
                    value={search}
                    size="small"
                    fullWidth
                    onChange={({ currentTarget }) => {
                      setSearch(search);
                      onSearchChange(currentTarget.value);
                    }}
                  />
                </MDBox>
              </Grid>
            )}
          </Grid>
        </MDBox>
      ) : null}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            const r = row;
            prepareRow(row);
            return (
              <TableRow onClick={onRowClick ? () => onRowClick(r) : null} {...row.getRowProps()}>
                {row.cells.map((cell) =>
                  cell?.hidden ? null : (
                    <DataTableBodyCell
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  )
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {totalAmountColumn && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="text" fontWeight="bold">
              Total Amount:
              <NumericFormat
                displayType="text"
                value={receiptAmountTotal}
                thousandSeparator="."
                decimalSeparator=","
                prefix=" Rp "
              />
            </MDTypography>
          </MDBox>
        )}
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Showing {entriesStart} to{" "}
              {entriesEnd <= remoteTotalEntries ? entriesEnd : remoteTotalEntries} of{" "}
              {remoteTotalEntries} entries
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
RemoteTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  extraHeaders: [],
  onRowDelete: false,
  onRowEdit: false,
  onRowPrint: false,
  onRowClick: null,
  refetchToken: 0,
  totalAmountColumn: "",
  canFilterDate: false,
  exportData: false,
  extraComponent: false,
  extraFilter: false,
};

// Typechecking props for the DataTable
RemoteTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  columnData: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.func, PropTypes.bool])
    )
  ).isRequired,
  queryArgs: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string])
  ).isRequired,
  extraHeaders: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  rowComponent: PropTypes.func.isRequired,
  // table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  onRowDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onRowEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onRowPrint: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onRowClick: PropTypes.func,
  refetchToken: PropTypes.number,
  totalAmountColumn: PropTypes.string,
  canFilterDate: PropTypes.bool,
  exportData: PropTypes.bool,
  extraComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.node]),
  extraFilter: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      node: PropTypes.node,
      params: PropTypes.oneOfType([
        PropTypes.objectOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.func, PropTypes.bool])
        ),
        PropTypes.object,
      ]),
    }),
  ]),
};

export default RemoteTable;

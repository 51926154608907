import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import Grid from "@mui/material/Grid";
// import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const printingPageStyle = `
  @page {
    size: 4in 1in;
    margin: 0.n;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }
  
  @media print {
    .pagebreak {
      page-break-before: always;
    }

    @page {
      size: 4in 3in;
      margin: 0in;
    }
  }
`;

const SXLabelItem = ({ qrContent }) => (
  <>
    <div
      style={{
        aspectRatio: "16/9",
        backgroundColor: "rgba(24,24,24,0.1)",
        width: "300px",
        display: "flex",
        alignSelf: "flex-start",
        height: "150px",
        marginRight: "50px",
        transform: "rotate(180deg)",
        // justifyContent: "center",
        // textAlign: "center",
      }}
    >
      <MDBox>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          columnSpacing={1}
          sx={{
            height: "100%",
            width: "320px",
            marginLeft: "0px !important",
          }}
        >
          <Grid item xs={3} sx={{ padding: "10px, 5px" }}>
            <QRCode size={65} value={qrContent?.item_id ? qrContent?.item_id.toString() : ""} />
          </Grid>
          <Grid item xs={9}>
            <Grid item xs={12} sx={{ height: "1rem" }}>
              <MDTypography
                variant="span"
                sx={{
                  fontSize: "1rem",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  fontWeight: 900,
                }}
              >
                {qrContent?.product_name ? qrContent?.product_name.slice(0, 40) : "n/a"}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sx={{ height: "1rem" }}>
              <MDTypography variant="span" sx={{ fontSize: "1rem", fontWeight: 900 }}>
                {qrContent?.tags ? qrContent?.tags : "n/a"}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sx={{ height: "1rem" }}>
              <MDTypography variant="span" sx={{ fontSize: "1rem", fontWeight: 900 }}>
                {qrContent?.receipt_code ? qrContent?.receipt_code : "n/a"}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  </>
);

const transformProductName = (content) => {
  if (!content) return "n/a";
  return (
    <>
      {content?.slice(0, 21)}
      {content?.length >= 22 && <br />}
      {content?.length >= 22 && content?.slice(21, 40)}
    </>
  );
};

const QrAndStockToPrint = React.forwardRef(({ qrContent }, ref) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      flexWrap: "no-wrap",
      // transform: "scale(0.45)",
      justifyContent: "flex-start",
      transformOrigin: "top left",
      alignContent: "center",
      alignItems: "center",
      paddingTop: "15px",
    }}
    ref={ref}
  >
    <div
      style={{
        aspectRatio: "16/9",
        backgroundColor: "rgba(24,24,24,0.1)",
        width: "300px",
        display: "flex",
        alignSelf: "flex-start",
        height: "150px",
        marginRight: "50px",
        transform: "rotate(180deg)",
        // justifyContent: "center",
        // textAlign: "center",
      }}
    >
      <MDBox>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          columnSpacing={1}
          sx={{
            height: "100%",
            width: "320px",
            marginLeft: "0px !important",
          }}
        >
          <Grid item xs={3} sx={{ padding: "10px, 5px" }}>
            <QRCode size={65} value={qrContent?.item_id ? qrContent?.item_id.toString() : ""} />
          </Grid>
          <Grid item xs={9}>
            <Grid item xs={12} sx={{ lineHeight: "1rem" }}>
              <MDTypography
                variant="span"
                sx={{
                  fontSize: "1rem",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  fontWeight: 900,
                }}
              >
                {transformProductName(qrContent?.product_name)}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sx={{ lineHeight: "1rem" }}>
              <MDTypography variant="span" sx={{ fontSize: "1rem", fontWeight: 900 }}>
                {qrContent?.tags ? qrContent?.tags : "n/a"}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sx={{ lineHeight: "1rem" }}>
              <MDTypography variant="span" sx={{ fontSize: "1rem", fontWeight: 900 }}>
                {qrContent?.receipt_id ? qrContent?.receipt_id : "n/a"}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </div>
    <div
      style={{
        aspectRatio: "16/9",
        backgroundColor: "rgba(24,24,24,0.1)",
        width: "300px",
        display: "flex",
        alignSelf: "flex-start",
        height: "150px",
        marginRight: "50px",
        transform: "rotate(180deg)",
        // justifyContent: "center",
        // textAlign: "center",
      }}
    >
      <MDBox>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          columnSpacing={1}
          sx={{
            height: "100%",
            width: "320px",
            marginLeft: "0px !important",
          }}
        >
          <Grid item xs={3} sx={{ padding: "10px, 5px" }}>
            <QRCode size={65} value={qrContent?.item_id ? qrContent?.item_id.toString() : ""} />
          </Grid>
          <Grid item xs={9}>
            <Grid item xs={12} sx={{ lineHeight: "1rem" }}>
              <MDTypography
                variant="span"
                sx={{
                  fontSize: "1rem",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  fontWeight: 900,
                }}
              >
                {transformProductName(qrContent?.product_name)}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sx={{ lineHeight: "1rem" }}>
              <MDTypography variant="span" sx={{ fontSize: "1rem", fontWeight: 900 }}>
                {qrContent?.tags ? qrContent?.tags : "n/a"}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sx={{ lineHeight: "1rem" }}>
              <MDTypography variant="span" sx={{ fontSize: "1rem", fontWeight: 900 }}>
                {qrContent?.receipt_id ? qrContent?.receipt_id : "n/a"}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </div>
    <div
      style={{
        aspectRatio: "16/9",
        backgroundColor: "rgba(24,24,24,0.1)",
        width: "300px",
        display: "flex",
        alignSelf: "flex-start",
        height: "150px",
        marginRight: "50px",
        transform: "rotate(180deg)",
        // justifyContent: "center",
        // textAlign: "center",
      }}
    >
      <MDBox>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          columnSpacing={1}
          sx={{
            height: "100%",
            width: "320px",
            marginLeft: "0px !important",
          }}
        >
          <Grid item xs={3} sx={{ padding: "10px, 5px" }}>
            <QRCode size={65} value={qrContent?.item_id ? qrContent?.item_id.toString() : ""} />
          </Grid>
          <Grid item xs={9}>
            <Grid item xs={12} sx={{ lineHeight: "1rem" }}>
              <MDTypography
                variant="span"
                sx={{
                  fontSize: "1rem",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  fontWeight: 900,
                }}
              >
                {transformProductName(qrContent?.product_name)}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sx={{ lineHeight: "1rem" }}>
              <MDTypography variant="span" sx={{ fontSize: "1rem", fontWeight: 900 }}>
                {qrContent?.tags ? qrContent?.tags : "n/a"}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sx={{ lineHeight: "1rem" }}>
              <MDTypography variant="span" sx={{ fontSize: "1rem", fontWeight: 900 }}>
                {qrContent?.receipt_id ? qrContent?.receipt_id : "n/a"}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  </div>
));

const useStockQRPrint = (qrContent) => {
  const toBePrintedRef = useRef();
  const handlePrint = useReactToPrint({
    pageStyle: printingPageStyle,
    content: () => toBePrintedRef.current,
  });
  const ThingToPrint = <QrAndStockToPrint qrContent={qrContent} ref={toBePrintedRef} />;
  // handlePrint();
  return {
    ThingToPrint,
    handlePrint,
  };
  // return (
  //   <div>
  //     {ThingToPrint}
  //     {/* <QrAndStockToPrint style={{ display: "none" }} qrContent={qrContent} ref={toBePrintedRef} /> */}
  //     <MDButton onClick={handlePrint}>Print</MDButton>
  //   </div>
  // );
};

useStockQRPrint.propTypes = {
  qrContent: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
    .isRequired,
};

QrAndStockToPrint.propTypes = {
  qrContent: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
    .isRequired,
};

SXLabelItem.propTypes = {
  qrContent: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
    .isRequired,
};

export default useStockQRPrint;

/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/* eslint-disable */
import { useNavigate } from "react-router";
import { useState, useEffect, forwardRef } from "react";
import { NumericFormat } from "react-number-format";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// Dialog/Modal
import { useTheme } from "@mui/material/styles";

// Datepickers
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

// Data
import RemoteTable from "components/RemoteTable";
import useAuthorizedRequest from "components/AuthorizedRequest";

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const rowComponent = ({
  id,
  transaction_id: transactionId,
  supplier_name: supplierName,
  created_at: date,
  status,
  total_items: totalItems,
}) => ({
  id: {
    value: id,
    hidden: true,
  },
  receipt_id: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="text" fontWeight="regular">
        {transactionId}
      </MDTypography>
    </MDBox>
  ),
  supplier_name: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="text" fontWeight="regular">
        {supplierName}
      </MDTypography>
    </MDBox>
  ),
  date: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="text" fontWeight="regular">
        {date}
      </MDTypography>
    </MDBox>
  ),

  status: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="text" fontWeight="medium">
        {status}
      </MDTypography>
    </MDBox>
  ),
  total_items: (
    <MDBox display="flex" alignItems="center">
      {/* <MDTypography display="block" variant="text" fontWeight="medium">
               {x.qty}
             </MDTypography> */}
      <NumericFormat
        value={totalItems}
        displayType="text"
        thousandSeparator="."
        decimalSeparator=","
        suffix=" pcs"
      />
    </MDBox>
  ),
});

function ReturnFromSupplierList() {
  const navigate = useNavigate();
  const authorizedReq = useAuthorizedRequest();
  const [fetchedSuppliers, setFetchedSuppliers] = useState([]);
  const [searchParams, setSearchParams] = useState([]);
  const [refetchToken, setRefetchToken] = useState(0);
  const [stateSnackbar, setStateSnackbar] = useState({
    openSnackbar: false,
    vertical: "top",
    horizontal: "right",
    severity: "info",
    message: "message",
  });
  const { vertical, horizontal, openSnackbar, severity, message } = stateSnackbar;

  const handleCloseSnackbar = () => {
    setStateSnackbar({ ...stateSnackbar, openSnackbar: false });
  };

  const handleParamChange = (param, data) => {
    const currSearchParams = searchParams;

    if (data) {
      if (param.includes("date")) {
        currSearchParams[`${param}_client`] = data;
        const newData = moment(data, "DD-MM-yyyy").format("yyyy-MM-DD");
        currSearchParams[param] = newData;
      } else {
        currSearchParams[param] = data;
      }
      setSearchParams(currSearchParams);
    } else {
      delete currSearchParams[param];
      setSearchParams(currSearchParams);
    }

    setRefetchToken(Date.now());
  };

  const handleRowClick = (i) => {
    navigate(`/warehouse/receipt/return/from-supplier/${i?.original?.id?.value}`);
  };

  useEffect(async () => {
    authorizedReq
      .get(`/supplier`, {})
      .then((res) => {
        // let newOptions = [];
        setFetchedSuppliers(
          res?.data?.data?.map((x) => ({
            id: x.id,
            supplier_name: x.supplier_name,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <DashboardLayout>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <DashboardNavbar />

      <MDBox pt={4} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              />
              <MDBox p={3}>
                <MDBox mb={2}>
                  <MDTypography variant="text">From Date</MDTypography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      // label="Responsive"
                      mask="__-__-____"
                      // inputFormat={moment().format("dd-MM-yyyy")}
                      inputFormat="DD-MM-yyyy"
                      // disableMaskedInput
                      value={searchParams?.from_date_client || null}
                      onChange={(newValue) => {
                        // setDateSelected(moment(newValue).format("DD-MM-yyyy"));
                        handleParamChange(
                          "from_date",
                          newValue
                          //   moment(newValue, "DD-MM-yyyy").format("yyyy-MM-DD")
                        );
                      }}
                      disableFuture
                      renderInput={(params) => (
                        <MDInput fullWidth placeholder="select date" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="text">To Date</MDTypography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      // label="Responsive"
                      mask="__-__-____"
                      // inputFormat={moment().format("dd-MM-yyyy")}
                      inputFormat="DD-MM-yyyy"
                      // disableMaskedInput
                      value={searchParams.to_date_client || null}
                      onChange={(newValue) => {
                        // setDateSelected(moment(newValue).format("DD-MM-yyyy"));
                        handleParamChange(
                          "to_date",
                          newValue
                          //   moment(newValue, "DD-MM-yyyy").format("yyyy-MM-DD")
                        );
                      }}
                      disableFuture
                      renderInput={(params) => (
                        <MDInput fullWidth placeholder="select date" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="text">Receipt Status</MDTypography>
                  <Select
                    labelId="status"
                    id="status"
                    // value={receiptStatus}
                    label="status"
                    variant="outlined"
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        size="large"
                        sx={{ paddingY: "0.8em" }}
                      />
                    }
                    defaultValue=""
                    onChange={(e) =>
                      handleParamChange("status", e.target.value === "all" ? "" : e.target.value)
                    }
                    fullWidth
                  >
                    <MenuItem value="all">ALL</MenuItem>
                    <MenuItem value="pending">PENDING</MenuItem>
                    <MenuItem value="done">DONE</MenuItem>
                  </Select>
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="text">Supplier</MDTypography>
                  <Select
                    labelId="supplier"
                    id="supplier"
                    // value={receiptStatus}
                    label="supplier"
                    variant="outlined"
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        size="large"
                        sx={{ paddingY: "0.8em" }}
                      />
                    }
                    defaultValue=""
                    onChange={(e) =>
                      handleParamChange(
                        "supplier",
                        e.target.value === "all" ? "" : parseInt(e.target.value)
                      )
                    }
                    fullWidth
                  >
                    <MenuItem value="all">ALL</MenuItem>
                    {fetchedSuppliers.map((x) => (
                      <MenuItem key={`s-${x.id}`} value={x.id}>
                        {x.supplier_name}
                      </MenuItem>
                    ))}
                  </Select>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <RemoteTable
                  columnData={[
                    { Header: "Receipt ID", accessor: "receipt_id", width: "20%" },
                    { Header: "Receipt Date", accessor: "date", width: "20%" },
                    { Header: "Supplier Name", accessor: "supplier_name", width: "20%" },
                    { Header: "Total Items", accessor: "total_items", width: "20%" },
                    { Header: "Status", accessor: "status", width: "20%" },
                  ]}
                  // isSorted
                  queryArgs={{
                    queryKey: "/receipt-return",
                    defaultSortKey: "created_at",
                    sortDir: "desc",
                  }}
                  refetchToken={refetchToken}
                  extraFilter={{ params: searchParams }}
                  onRowClick={handleRowClick}
                  pagination={{ variant: "contained", color: "primary" }}
                  rowComponent={rowComponent}
                  entriesPerPage={{ entries: [10, 20, 40] }}
                  showTotalEntries
                  noEndBorder
                  canSearch
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ReturnFromSupplierList;

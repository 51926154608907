/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useNavigate } from "react-router";
import { useState, forwardRef } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";

// snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// Dialog/Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
import categoryData from "layouts/category/data/categoryData";
import RemoteTable from "components/RemoteTable";
import useAuthorizedRequest from "components/AuthorizedRequest";
import { CategoryDataRow } from "./data/categoryData";

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

function Category() {
  const navigate = useNavigate();
  const { columns: pColumns } = categoryData();
  const authorizedReq = useAuthorizedRequest();
  const [refetchToken, setRefetchToken] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [idChoosed, setIdChoosed] = useState(null);
  const [categoryChoosed, setCategoryChoosed] = useState(null);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenDialog = (i, j) => {
    setOpenDialog(true);
    setIdChoosed(i);
    setCategoryChoosed(j);
  };

  const [stateSnackbar, setStateSnackbar] = useState({
    openSnackbar: false,
    vertical: "top",
    horizontal: "right",
    severity: "info",
    message: "message",
  });
  const { vertical, horizontal, openSnackbar, severity, message } = stateSnackbar;

  const handleCloseSnackbar = () => {
    setStateSnackbar({ ...stateSnackbar, openSnackbar: false });
  };

  const handleRowDelete = () => {
    handleCloseDialog();
    authorizedReq
      .delete(`${process.env.REACT_APP_API_BASE_URL}/category/${idChoosed}`)
      .then(() => {
        setStateSnackbar({
          ...stateSnackbar,
          openSnackbar: true,
          severity: "success",
          message: `${categoryChoosed} Deleted!`,
        });
        // console.log(stateSnackbar);
        setRefetchToken(Date.now());
      })
      .catch(() => {
        setStateSnackbar({
          ...stateSnackbar,
          openSnackbar: true,
          severity: "error",
          message: "Delete Error",
        });
      });
  };

  const handleRowEdit = (i) => {
    navigate(`/category/category-edit?id=${i}`);
  };
  return (
    <DashboardLayout>
      <Dialog
        fullScreen={fullScreen}
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Delete {categoryChoosed}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure want to delete {categoryChoosed}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton autoFocus onClick={handleCloseDialog} variant="outlined" color="secondary">
            No
          </MDButton>
          <MDButton onClick={handleRowDelete} autoFocus variant="outlined" color="error">
            Yes
          </MDButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item xs={12} lg={9}>
                    <MDTypography variant="h6" color="white">
                      Category
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} lg={3} align="right">
                    <MDButton
                      component="a"
                      onClick={() => navigate("/category/category-add")}
                      sx={{ display: { xs: "block", lg: "inline" }, mt: { xs: 2 } }}
                    >
                      Add Category
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <RemoteTable
                  columnData={pColumns}
                  // isSorted
                  queryArgs={{
                    queryKey: "/category",
                    defaultSortKey: "updated_at",
                    sortDir: "desc",
                  }}
                  refetchToken={refetchToken}
                  onRowDelete={handleOpenDialog}
                  onRowEdit={handleRowEdit}
                  pagination={{ variant: "contained", color: "primary" }}
                  rowComponent={CategoryDataRow}
                  entriesPerPage={{ entries: [10, 20, 40] }}
                  showTotalEntries
                  noEndBorder
                  canSearch
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Category;

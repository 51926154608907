/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Alert, Autocomplete, Snackbar, TextField } from "@mui/material";
import useAuthorizedRequest from "components/AuthorizedRequest";
import { DropzoneArea } from "react-mui-dropzone";
import _ from "lodash";

function ProductAdd() {
  const abortController = new AbortController();
  const [inputCategoryValue, setInputCategoryValue] = useState("");
  const [fetchedCategories, setFetchedCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [productName, setProductName] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [dzKey, setDzKey] = useState(0);
  const [snackProps, setSnackProps] = useState({
    message: "",
    severity: "info",
    isOpen: false,
  });
  const authorizedReq = useAuthorizedRequest();

  useEffect(() => {
    let active = true;
    let errMessage = "";
    if (inputCategoryValue === "") {
      setFetchedCategories([]);
      return undefined;
    }
    _.debounce(() => {
      authorizedReq
        .get(`/category?search=${inputCategoryValue}`, {
          signal: abortController.signal,
        })
        .then((res) => {
          if (active) {
            setFetchedCategories(
              res.data.data.map((x) => ({
                id: x.id,
                category_name: x.category_name,
              }))
            );
          }
        })
        .catch((err) => {
          errMessage = err.cancel;
          if (errMessage);
        });
    }, 100)();
    return () => {
      abortController.abort();
      active = false;
    };
  }, [selectedCategories, inputCategoryValue]);

  const handleSubmitProduct = () => {
    const productFormData = new FormData();
    productFormData.append("product_name", productName);
    productFormData.append("product_description", productDesc);
    selectedCategories.forEach((x) => {
      productFormData.append("categories[]", x.id);
    });
    filesToUpload.forEach((x) => {
      productFormData.append("files[]", x);
    });
    productFormData.append("status", "active");

    authorizedReq
      .post("/product", productFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        setSnackProps({
          isOpen: true,
          message: "New Product Saved",
          severity: "success",
        });
        setSelectedCategories([]);
        setProductName("");
        setProductDesc("");
        setFilesToUpload([]);
        setDzKey(Date.now());
      })
      .catch((err) => {
        setSnackProps({
          isOpen: true,
          message: `${err.response.data.message}, ${
            err.response.data.errors[Object.keys(err.response.data.errors)[0]]
          }`,
          severity: "error",
        });
      });
  };

  const handleSnackClose = () => {
    setSnackProps({ ...snackProps, isOpen: false });
  };

  return (
    <DashboardLayout>
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackProps.isOpen}
          onClose={handleSnackClose}
          autoHideDuration={6000}
        >
          <Alert onClose={handleSnackClose} severity={snackProps.severity} sx={{ width: "100%" }}>
            {snackProps.message}
          </Alert>
        </Snackbar>
      </div>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox
                  component="form"
                  role="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmitProduct();
                  }}
                >
                  <MDBox mb={2}>
                    <MDTypography>Product Name</MDTypography>
                    <MDInput
                      value={productName}
                      onChange={(e) => setProductName(e.target.value)}
                      type="input"
                      fullWidth
                      placeholder="Product Name"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography>Product Description</MDTypography>
                    <MDInput
                      value={productDesc}
                      onChange={(e) => setProductDesc(e.target.value)}
                      type="input"
                      multiline
                      rows={3}
                      fullWidth
                      placeholder="Product Description"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography>Product Categories</MDTypography>
                    <Autocomplete
                      multiple
                      id="categories-select"
                      value={selectedCategories}
                      onChange={(e, d) => setSelectedCategories(d)}
                      options={fetchedCategories}
                      getOptionLabel={(option) => option.category_name || ""}
                      filterSelectedOptions
                      filterOptions={(x) => x}
                      onInputChange={(event, newInputCategoryValue) => {
                        setInputCategoryValue(newInputCategoryValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="category name" />
                      )}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography>Pictures</MDTypography>
                    <div key={dzKey}>
                      <DropzoneArea onChange={(f) => setFilesToUpload(f)} />
                    </div>
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="success"
                      fullWidth
                      onClick={handleSubmitProduct}
                    >
                      Save
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProductAdd;

/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useNavigate } from "react-router";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function Warehouse() {
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item xs={12} lg={6} mb={2}>
                    <MDTypography variant="h6" color="white">
                      Warehouse
                    </MDTypography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={2}
                    mb={2}
                    align="right"
                    // sx={{ display: { xs: "block", lg: "inline" } }}
                  >
                    <MDButton
                      component="a"
                      // color="success"
                      onClick={() => navigate("/warehouse/restock")}
                      sx={{ display: { xs: "block", lg: "inline" } }}
                    >
                      Restock
                    </MDButton>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={2}
                    mb={2}
                    align="right"
                    // sx={{ display: { xs: "block", lg: "inline" } }}
                  >
                    <MDButton
                      component="a"
                      // color="success"
                      onClick={() => navigate("/warehouse/receipt")}
                      sx={{ display: { xs: "block", lg: "inline" } }}
                    >
                      Receipt
                    </MDButton>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={2}
                    mb={2}
                    align="right"
                    // sx={{ display: { xs: "block", lg: "inline" } }}
                  >
                    <MDButton
                      component="a"
                      // color="warning"
                      onClick={() => navigate("/warehouse/receipt-payment")}
                      sx={{ display: { xs: "block", lg: "inline" } }}
                    >
                      Receipt Payment
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Warehouse;

/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
// import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// import FilterSales from "components/FilterSales";

// Data
import RemoteTable from "components/RemoteTable";
import restockData from "layouts/warehouse/need-restock/data/restockData";
import { RestockDataRow } from "./data/restockData";
import "../../../assets/styles/style.css";

function Restock() {
  const { columns: pColumns } = restockData(); // create columns var for stock list
  // const [refetchToken, setRefetchToken] = useState(null);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item xs={12} lg={9}>
                    <MDTypography variant="h6" color="white">
                      Need Restock
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <RemoteTable
                  columnData={pColumns}
                  // isSorted
                  queryArgs={{
                    queryKey: "/restock/search",
                  }}
                  // refetchToken={refetchToken}
                  pagination={{ variant: "contained", color: "primary" }}
                  rowComponent={RestockDataRow}
                  entriesPerPage={{ entries: [10, 20, 40] }}
                  showTotalEntries
                  noEndBorder
                  canSearch
                  exportData
                  // onRowClick={() => console.log("")}
                  // extraFilter={FilterSales(() => setRefetchToken(Date.now()))}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Restock;

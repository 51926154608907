/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
// import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Alert, Snackbar, OutlinedInput } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import useAuthorizedRequest from "components/AuthorizedRequest";
import _ from "lodash";
// import { DropzoneArea } from "react-mui-dropzone";

function StockAdd() {
  const abortController = new AbortController();
  const authorizedReq = useAuthorizedRequest();
  const [inputProductValue, setInputProductValue] = useState("");
  const [inputTagValue, setInputTagValue] = useState("");
  const [fetchedProduct, setFetchedProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [fetchedTags, setFetchedTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [sellPrice, setSellPrice] = useState("");
  const [snackProps, setSnackProps] = useState({
    message: "",
    severity: "info",
    isOpen: false,
  });

  useEffect(() => {
    let active = true;
    let errMessage = "";
    if (inputTagValue === "") {
      setFetchedTags([]);
      return undefined;
    }
    _.debounce(() => {
      authorizedReq
        .get(`/tag?search=${inputTagValue}`, {
          signal: abortController.signal,
        })
        .then((res) => {
          if (active) {
            setFetchedTags(
              res.data.data.map((x) => ({
                id: x.id,
                tag_name: x.tag_name,
              }))
            );
          }
        })
        .catch((err) => {
          errMessage = err.cancel;
          if (errMessage);
        });
    }, 100)();
    return () => {
      abortController.abort();
      active = false;
    };
  }, [selectedTags, inputTagValue]);

  useEffect(() => {
    let active = true;
    let errMessage = "";
    if (inputProductValue === "") {
      setFetchedProduct(selectedProduct ? [selectedProduct] : []);
      return undefined;
    }
    _.debounce(() => {
      authorizedReq
        .get(`/product?search=${inputProductValue}`, {
          signal: abortController.signal,
        })
        .then((res) => {
          if (active) {
            setFetchedProduct(
              res.data.data.map((x) => ({
                id: x.id,
                product_name: x.product_name,
              }))
            );
          }
        })
        .catch((err) => {
          errMessage = err.cancel;
          if (errMessage);
        });
    }, 100)();
    return () => {
      abortController.abort();
      active = false;
    };
  }, [selectedProduct, inputProductValue]);

  const handleSubmitStock = () => {
    const stockFormData = new FormData();
    stockFormData.append("product_id", selectedProduct.id);
    stockFormData.append("sell_price", sellPrice);
    stockFormData.append("qty", 1);
    selectedTags.forEach((x) => {
      stockFormData.append("tags[]", x.id);
    });
    stockFormData.append("status", "active");
    stockFormData.append("supplier", 1);

    authorizedReq
      .post("/stock", stockFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then(() => {
        setSnackProps({
          isOpen: true,
          message: "New Stock Saved",
          severity: "success",
        });
        setInputProductValue("");
        setInputTagValue("");
        setSelectedProduct([]);
        setSelectedTags([]);
        setSellPrice("");
      })
      .catch((err) => {
        setSnackProps({
          isOpen: true,
          message: `${err.response.data.message}`,
          severity: "error",
        });
      });
  };

  const handleSnackClose = () => {
    setSnackProps({ ...snackProps, isOpen: false });
  };

  const handleChangeSellPrice = ({ value }) => {
    setSellPrice(value);
  };

  return (
    <DashboardLayout>
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackProps.isOpen}
          onClose={handleSnackClose}
          autoHideDuration={6000}
        >
          <Alert onClose={handleSnackClose} severity={snackProps.severity} sx={{ width: "100%" }}>
            {snackProps.message}
          </Alert>
        </Snackbar>
      </div>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox
                  component="form"
                  role="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmitStock();
                  }}
                >
                  <MDBox mb={2}>
                    <MDTypography variant="text">Product Name</MDTypography>
                    <Autocomplete
                      freeSolo
                      id="combo-box-demo"
                      value={selectedProduct}
                      onChange={(e, d) => setSelectedProduct(d)}
                      options={fetchedProduct}
                      getOptionLabel={(option) => option.product_name || ""}
                      filterSelectedOptions
                      filterOptions={(x) => x}
                      onInputChange={(event, newInputProductValue) => {
                        setInputProductValue(newInputProductValue);
                      }}
                      renderInput={(params) => <MDInput {...params} placeholder="product name" />}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="text">Sell Price</MDTypography>
                    <NumericFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      onValueChange={handleChangeSellPrice}
                      customInput={OutlinedInput}
                      fullWidth
                      startAdornment={<InputAdornment position="start">Rp</InputAdornment>}
                      inputProps={{
                        maxLength: 15,
                        step: "500",
                      }}
                      placeholder="sell price"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="text">Tags</MDTypography>
                    <Autocomplete
                      multiple
                      id="tags-select"
                      value={selectedTags}
                      onChange={(event, value) => setSelectedTags(value)}
                      options={fetchedTags}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionLabel={(option) => option.tag_name || ""}
                      filterOptions={(x) => x}
                      onInputChange={(event, newInputTagValue) => {
                        setInputTagValue(newInputTagValue);
                      }}
                      renderInput={(params) => <MDInput {...params} placeholder="tags" />}
                    />
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="success"
                      fullWidth
                      onClick={handleSubmitStock}
                    >
                      Save
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default StockAdd;

/* eslint-disable react/prop-types */

// @mui material components
import Icon from "@mui/material/Icon";
import { NumericFormat } from "react-number-format";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import moment from "moment";

export const ReceiptDataRow = ({
  // eslint-disable-next-line no-unused-vars
  id,
  items: itemCount,
  payment_amount: paymentAmount,
  note: receiptNote,
  created_at: createdAt,
  supplier_name: supplierName,
}) => ({
  id: {
    value: id,
    hidden: true,
  },
  payment_id: {
    value: createdAt,
    hidden: true,
  },
  supplier_name: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        {supplierName}
      </MDTypography>
    </MDBox>
  ),
  items: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        <NumericFormat
          displayType="text"
          value={itemCount}
          thousandSeparator="."
          decimalSeparator=","
          suffix=" pcs"
        />
      </MDTypography>
    </MDBox>
  ),
  payment_amount: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        <NumericFormat
          displayType="text"
          value={paymentAmount}
          thousandSeparator="."
          decimalSeparator=","
          prefix="Rp "
        />
      </MDTypography>
    </MDBox>
  ),
  note: (
    <MDTypography display="block" variant="button" fontWeight="regular">
      {receiptNote || "default notes"}
    </MDTypography>
  ),
  created_at: (
    <MDTypography display="block" variant="button" fontWeight="regular">
      {moment(createdAt).format("DD-MM-YYYY HH:mm:ss")}
    </MDTypography>
  ),
});

export default function data() {
  const Item = ({ name }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        {name}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      {
        Header: "Supplier",
        accessor: "supplier_name",
        align: "left",
        width: "25%",
        disableSortBy: true,
      },
      { Header: "Payment Date", accessor: "created_at", align: "left", width: "20%" },
      { Header: "Receipt Count", accessor: "items", align: "left", width: "15%" },
      { Header: "Payment Amount", accessor: "payment_amount", align: "left", width: "20%" },
      {
        Header: "Notes",
        accessor: "note",
        align: "left",
        width: "20%",
        disableSortBy: true,
      },
      // { Header: "Status", accessor: "status", align: "left" },
      // { Header: "Action", accessor: "action", align: "left" },
    ],

    rows: [
      {
        item_name: <Item name="Item1" />,
        category: (
          <MDTypography variant="caption" color="text" fontWeight="regular">
            Category1
          </MDTypography>
        ),
        supplier_code: (
          <MDTypography variant="caption" color="text" fontWeight="regular">
            SPL1
          </MDTypography>
        ),
        sku_code: (
          <MDTypography variant="caption" color="text" fontWeight="regular">
            SKU1
          </MDTypography>
        ),
        items: (
          <MDTypography variant="caption" color="text" fontWeight="regular">
            100000
          </MDTypography>
        ),
        date: (
          <MDTypography variant="caption" color="text" fontWeight="regular">
            100000
          </MDTypography>
        ),
        status: (
          <MDTypography variant="caption" color="text" fontWeight="regular">
            active
          </MDTypography>
        ),
        updated_at: (
          <MDTypography variant="caption" color="text" fontWeight="regular">
            11-01-2022 13:37:50
          </MDTypography>
        ),
        action: (
          <MDBox>
            <MDButton iconOnly color="info" href="./warehouse/warehouse-edit" variant="outlined">
              <Icon fontSize="small" component="a" color="info">
                edit
              </Icon>
            </MDButton>
            <MDButton iconOnly color="error" href="./warehouse/warehouse-edit" variant="outlined">
              <Icon fontSize="small" component="a" color="error">
                delete
              </Icon>
            </MDButton>
          </MDBox>
        ),
      },
      {
        item_name: <Item name="Item2" />,
        category: (
          <MDTypography variant="caption" color="text" fontWeight="regular">
            Category2
          </MDTypography>
        ),
        supplier_code: (
          <MDTypography variant="caption" color="text" fontWeight="regular">
            SPL2
          </MDTypography>
        ),
        sku_code: (
          <MDTypography variant="caption" color="text" fontWeight="regular">
            SKU2
          </MDTypography>
        ),
        items: (
          <MDTypography variant="caption" color="text" fontWeight="regular">
            120000
          </MDTypography>
        ),
        date: (
          <MDTypography variant="caption" color="text" fontWeight="regular">
            120000
          </MDTypography>
        ),
        status: (
          <MDTypography variant="caption" color="text" fontWeight="regular">
            active
          </MDTypography>
        ),
        updated_at: (
          <MDTypography variant="caption" color="text" fontWeight="regular">
            12-01-2022 09:11:03
          </MDTypography>
        ),
        action: (
          <MDBox>
            <MDButton iconOnly color="info" href="./warehouse/warehouse-edit" variant="outlined">
              <Icon fontSize="small" component="a" color="info">
                edit
              </Icon>
            </MDButton>
            <MDButton iconOnly color="error" href="./warehouse/warehouse-edit" variant="outlined">
              <Icon fontSize="small" component="a" color="error">
                delete
              </Icon>
            </MDButton>
          </MDBox>
        ),
      },
    ],
  };
}

/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState, forwardRef } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import { OutlinedInput } from "@mui/material";
// import InputAdornment from "@mui/material/InputAdornment";
import Snackbar from "@mui/material/Snackbar";
import MenuItem from "@mui/material/MenuItem";
import FullScreenSpinner from "components/FullScreenSpinner";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import MuiAlert from "@mui/material/Alert";

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
// import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import useAuthorizedRequest from "components/AuthorizedRequest";

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

function UserAdd() {
  const authorizedRequest = useAuthorizedRequest();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [fetchedRoles, setFetchedRoles] = useState([]);
  const [userData, setUserData] = useState({});
  const [confirmSubmit, setConfirmSubmit] = useState({
    isSubmiting: false,
  });

  useEffect(async () => {
    const resp = await authorizedRequest.get("usermanagement/roles");
    setFetchedRoles(resp?.data?.filter((x) => x.id !== 4));
  }, []);

  const handleRoleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRoles(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setConfirmSubmit({
      ...confirmSubmit,
      isSubmiting: true,
    });

    // const resp =
    await authorizedRequest
      .post("usermanagement/user", {
        name: userData.name,
        email: userData.email,
        password: userData.password,
        roles: selectedRoles,
        status: "active",
      })
      .catch((err) => {
        console.log(err);
        setConfirmSubmit({
          ...confirmSubmit,
          isSubmiting: false,
          showAlert: true,
          alertMessage: JSON.stringify(err.response),
          alertSeverity: "error",
        });
        throw err;
      });

    setConfirmSubmit({
      ...confirmSubmit,
      isSubmiting: false,
      showAlert: true,
      alertMessage: "New User Created",
      alertSeverity: "success",
    });
    setUserData({});
    setSelectedRoles([]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={confirmSubmit.showAlert}
        autoHideDuration={5000}
        onClose={() =>
          setConfirmSubmit({
            ...confirmSubmit,
            showAlert: false,
            alertMessage: "",
          })
        }
      >
        <Alert
          onClose={() =>
            setConfirmSubmit({
              ...confirmSubmit,
              showAlert: false,
              alertMessage: "",
            })
          }
          severity={confirmSubmit.alertSeverity}
          sx={{ width: "100%" }}
        >
          {confirmSubmit.alertMessage}
        </Alert>
      </Snackbar>
      <FullScreenSpinner showOverlay={confirmSubmit.isSubmitting} />
      <MDBox mt={6} mb={3}>
        <Grid justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDTypography>Name</MDTypography>
                    <MDInput
                      value={userData.name || ""}
                      onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                      type="text"
                      fullWidth
                      placeholder="name"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography>Email</MDTypography>
                    <MDInput
                      value={userData.email || ""}
                      onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                      type="email"
                      fullWidth
                      placeholder="email"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <FormControl variant="filled" size="large" fullWidth>
                      <MDTypography variant="text">Roles</MDTypography>
                      {/* <InputLabel id="roles-label">Roles</InputLabel> */}
                      <Select
                        fullWidth
                        size="medium"
                        // labelId="roles-label"
                        variant="standard"
                        id="roles-select"
                        multiple
                        value={selectedRoles}
                        onChange={handleRoleChange}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            size="large"
                            sx={{ paddingY: "0.8em" }}
                          />
                        }
                        renderValue={(selected) => (
                          <MDBox sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip
                                key={`C-${value}`}
                                label={fetchedRoles?.find((role) => role.id === value).name}
                              />
                            ))}
                          </MDBox>
                        )}
                        // MenuProps={MenuProps}
                      >
                        {fetchedRoles?.map((role) => (
                          <MenuItem key={`M-${role.id}`} value={role.id}>
                            {role.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography>Password</MDTypography>
                    <MDInput
                      value={userData.password || ""}
                      onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                      type="password"
                      fullWidth
                      placeholder="Password"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography>Confirm Password</MDTypography>
                    <MDInput
                      value={userData.confirmPassword || ""}
                      onChange={(e) =>
                        setUserData({ ...userData, confirmPassword: e.target.value })
                      }
                      type="password"
                      fullWidth
                      placeholder="confirm password"
                    />
                  </MDBox>
                  <MDBox>
                    <MDTypography
                      sx={{
                        display:
                          userData?.password &&
                          !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(userData?.password)
                            ? "block"
                            : "none",
                      }}
                      variant="text"
                      color="error"
                    >
                      Password must have at least 8 characters and contain number.
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDTypography
                      sx={{
                        display:
                          userData.password &&
                          userData.confirmPassword &&
                          userData.password !== userData.confirmPassword
                            ? "block"
                            : "none",
                      }}
                      variant="text"
                      color="error"
                    >
                      Password and Confirmation Missmatch!
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      disabled={
                        !userData.name ||
                        !userData.email ||
                        !selectedRoles ||
                        !userData.password ||
                        !userData.confirmPassword ||
                        userData.password !== userData.confirmPassword ||
                        !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(userData?.password)
                      }
                      onClick={handleSubmit}
                      variant="gradient"
                      color="success"
                      fullWidth
                    >
                      Save
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserAdd;

/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { NumericFormat } from "react-number-format";
import moment from "moment";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
// import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {
  Alert,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  Snackbar,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  Checkbox,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import BluetoothConnectedIcon from "@mui/icons-material/BluetoothConnected";
import beepAudio from "assets/sounds/beep.mp3";
import { Howl } from "howler";
// import useAuthorizedRequest from "components/AuthorizedRequest";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import FullScreenSpinner from "components/FullScreenSpinner";
import useAuthorizedRequest from "components/AuthorizedRequest";
import { useParams } from "react-router";
import RemoteTable from "components/RemoteTable";

import DataTable from "examples/Tables/DataTable";

const salesReturnColumn = [
  { Header: "Sales ID", accessor: "transaction_id", width: "20%" },
  { Header: "Cashier", accessor: "operator", width: "20%" },
  { Header: "Total Items", accessor: "total_item_count", width: "20%" },
  { Header: "Types", accessor: "total_item_types", width: "10%" },
  { Header: "Payment Channel", accessor: "payment_channel", width: "10%" },
  { Header: "Date", accessor: "created_at", width: "10%" },
];

const salesReturnDetailColumn = [
  { Header: "Product Name", accessor: "product_name", width: "60%" },
  { Header: "Stock Tags", accessor: "tags", width: "20%" },
  { Header: "Qty", accessor: "qty", width: "20%" },
];

function SalesReturnHistory() {
  const { transactionMedia } = useParams();
  const [salesReturnDetails, setSalesReturnDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const authorizedRequester = useAuthorizedRequest();

  const salesReturnDataRow = ({
    id,
    transaction_id: transactionId,
    operator,
    total_item_count: totalItems,
    total_item_types: totalTypes,
    payment_channel: paymentChannel,
    created_at: date,
  }) => ({
    id: {
      value: id,
      hidden: true,
    },
    transaction_id: (
      <MDBox display="flex" alignItems="center">
        <MDTypography display="block" variant="text" fontWeight="regular">
          {transactionId}
        </MDTypography>
      </MDBox>
    ),
    payment_channel: (
      <MDBox display="flex" alignItems="center">
        <MDTypography display="block" variant="text" fontWeight="regular">
          {paymentChannel}
        </MDTypography>
      </MDBox>
    ),
    operator: (
      <MDBox display="flex" alignItems="center">
        <MDTypography display="block" variant="text" fontWeight="regular">
          {operator}
        </MDTypography>
      </MDBox>
    ),
    total_item_count: (
      <MDBox display="flex" alignItems="center">
        <NumericFormat
          value={totalItems}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          // prefix="Rp"
          suffix=" pcs"
        />
      </MDBox>
    ),
    total_item_types: (
      <MDBox display="flex" alignItems="center">
        <NumericFormat
          value={totalTypes}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          // prefix="Rp"
          suffix=" types"
        />
      </MDBox>
    ),
    created_at: (
      <MDBox display="flex" alignItems="center">
        <MDTypography display="block" variant="text" fontWeight="regular">
          {moment(date).format("DD-MM-YYYY HH:mm:ss")}
        </MDTypography>
      </MDBox>
    ),
  });

  const handleSelectSalesReturn = async (salesReturnId) => {
    setIsLoading(true);
    const salesReturnDetailData = await authorizedRequester
      .get(`/sales/return/${salesReturnId}`)
      .catch((e) => {
        setIsLoading(false);
        throw "No Data";
      });

    setSalesReturnDetails(salesReturnDetailData?.data);
    setIsLoading(false);
  };

  return (
    <DashboardLayout>
      <FullScreenSpinner showOverlay={isLoading} />
      <Dialog
        sx={{ "& .MuiDialog-paper": { minWidth: "80%", maxHeight: 600 } }}
        maxWidth="xs"
        open={salesReturnDetails !== null}
        onClose={() => setSalesReturnDetails(null)}
      >
        <DialogTitle>Sales Return Data</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12} align="center">
              <MDBox mt={1} mb={1}>
                <MDBox px={2}>
                  <MDBox display="flex" alignItems="center">
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      Transaction ID : {salesReturnDetails?.transaction_id}
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex" alignItems="center">
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      Operator : {salesReturnDetails?.operator}
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex" alignItems="center">
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      Date : {moment(salesReturnDetails?.created_at).format("DD-MM-YYYY HH:mm:ss")}
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex" alignItems="center">
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      Returned Amount : {salesReturnDetails?.items?.reduce((a, b) => a + b.qty, 0)}{" "}
                      pcs
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex" alignItems="center">
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      Returned Types : {salesReturnDetails?.items?.length} types
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <br />
                <DataTable
                  table={{
                    columns: salesReturnDetailColumn,
                    rows:
                      salesReturnDetails?.items?.length > 0
                        ? salesReturnDetails?.items?.map((x) => ({
                            product_name: (
                              <MDBox display="flex" alignItems="center">
                                <MDTypography display="block" variant="text" fontWeight="regular">
                                  {x.product_name}
                                </MDTypography>
                              </MDBox>
                            ),
                            stock_tags: (
                              <MDBox display="flex" alignItems="center">
                                <MDTypography display="block" variant="text" fontWeight="regular">
                                  {x.tags}
                                </MDTypography>
                              </MDBox>
                            ),
                            qty: (
                              <MDBox display="flex" alignItems="center">
                                <NumericFormat
                                  displayType="text"
                                  value={x.qty}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  suffix=" pcs"
                                />
                              </MDBox>
                            ),
                          }))
                        : [],
                  }}
                  // showTotalAmount
                  // amountTotal={receiptAmountTotal}
                  isSorted={false}
                />
              </MDBox>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setSalesReturnDetails(null);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item xs={12} lg={8} mt={2}>
                    <MDTypography variant="h6" color="white">
                      {`O${transactionMedia.substring(1, 10)}`} Sales Return History
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox>
                <RemoteTable
                  columnData={salesReturnColumn}
                  extraFilter={{
                    params: {
                      transaction_media: `O${transactionMedia.substring(1, 10)}`,
                    },
                  }}
                  // isSorted
                  queryArgs={{
                    queryKey: "/sales/return",
                    defaultSortKey: "created_at",
                    sortDir: "desc",
                  }}
                  pagination={{ variant: "contained", color: "primary" }}
                  rowComponent={salesReturnDataRow}
                  entriesPerPage={{ entries: [10, 20, 40] }}
                  onRowClick={(r) => handleSelectSalesReturn(r?.original?.id?.value)}
                  showTotalEntries
                  noEndBorder
                  canSearch
                  canFilterDate
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}
export default SalesReturnHistory;

/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { parseUrl } from "query-string";

import useAuthorizedRequest from "components/AuthorizedRequest";
import { Alert, Snackbar } from "@mui/material";

function TagEdit() {
  const authorizedReq = useAuthorizedRequest();
  const urlPage = parseUrl(window.location.href);
  const tagId = urlPage.query.id;
  const navigate = useNavigate();

  // const [status, setStatus] = useState(1);
  // const [supplierName, setSupplierName] = useState("");
  const [snackProps, setSnackProps] = useState({
    message: "",
    severity: "info",
    isOpen: false,
  });
  const [tagData, setTagData] = useState({
    tag_name: "",
    tag_desc: "",
    status: "",
  });

  // const handleStatusChange = (event) => {
  //   setStatus(event.target.value);
  // };

  const handleSnackClose = () => {
    setSnackProps({ ...snackProps, isOpen: false });
  };

  useEffect(() => {
    authorizedReq
      .get(`${process.env.REACT_APP_API_BASE_URL}/tag/${tagId}`)
      .then((res) => {
        setTagData({
          tag_name: res.data.tag_name,
          tag_desc: res.data.tag_desc,
          status: res.data.status,
        });
      })
      .catch((err) => {
        setSnackProps({
          isOpen: true,
          message: err.response.data.message,
          severity: "error",
        });
      });
  }, []);

  const handleUpdateTag = () => {
    authorizedReq
      .put(`${process.env.REACT_APP_API_BASE_URL}/tag/${tagId}`, tagData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(() => {
        setSnackProps({
          isOpen: true,
          message: "Tag Updated Successfully",
          severity: "success",
        });
        navigate(-1);
      })
      .catch((err) => {
        setSnackProps({
          isOpen: true,
          message: err.response.data.message,
          severity: "error",
        });
      });
  };

  return (
    <DashboardLayout>
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackProps.isOpen}
          onClose={handleSnackClose}
          autoHideDuration={6000}
        >
          <Alert onClose={handleSnackClose} severity={snackProps.severity} sx={{ width: "100%" }}>
            {snackProps.message}
          </Alert>
        </Snackbar>
      </div>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox
                  component="form"
                  role="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleUpdateTag();
                  }}
                >
                  <MDBox mb={2}>
                    <MDTypography variant="text">Tag Name</MDTypography>
                    <MDInput
                      type="input"
                      fullWidth
                      placeholder="Tag Name"
                      value={tagData.tag_name}
                      disabled
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="text">Tag Description</MDTypography>
                    <MDInput
                      type="input"
                      fullWidth
                      placeholder="Tag Description"
                      value={tagData.tag_desc}
                      onChange={(e) =>
                        setTagData({
                          tag_name: tagData.tag_name,
                          tag_desc: e.target.value,
                          status: tagData.status,
                        })
                      }
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="text">Status</MDTypography>
                    <Select
                      labelId="status"
                      id="status"
                      value={tagData.status}
                      label="status"
                      variant="standard"
                      defaultValue=""
                      onChange={(e) =>
                        setTagData({
                          tag_name: tagData.tag_name,
                          tag_desc: tagData.tag_desc,
                          status: e.target.value,
                        })
                      }
                      fullWidth
                    >
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="inactive">Inactive</MenuItem>
                    </Select>
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="info" fullWidth onClick={handleUpdateTag}>
                      Update
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TagEdit;

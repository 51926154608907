/* eslint-disable react/prop-types */

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import moment from "moment";
// import InputAdornment from "@mui/material/InputAdornment";
import { NumericFormat } from "react-number-format";

export const RestockDataRow = ({
  id,
  product_name: stockProductName,
  // buy_price: buyPrice,
  variant,
  qty_left: qtyLeft,
  monthly_sales: monthlySales,
  // onRowPrint,
}) => ({
  id: {
    value: id,
    hidden: true,
  },
  stock_name: {
    value: stockProductName,
    hidden: true,
  },
  product_name: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="text" fontWeight="regular">
        {stockProductName}
      </MDTypography>
    </MDBox>
  ),
  variant: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="text" fontWeight="regular">
        {variant}
      </MDTypography>
    </MDBox>
  ),
  qty_left: (
    <MDBox display="flex" alignItems="center">
      <NumericFormat
        value={qtyLeft}
        displayType="text"
        thousandSeparator="."
        decimalSeparator=","
        // prefix="Rp"
        suffix=" pcs"
      />
    </MDBox>
  ),
  monthly_sales: (
    <MDBox display="flex" alignItems="center">
      <NumericFormat
        value={monthlySales}
        displayType="text"
        thousandSeparator="."
        decimalSeparator=","
        // prefix="Rp"
        suffix=" pcs"
      />
    </MDBox>
  ),
});

export default function data() {
  const Item = ({ name }) => (
    <>
      <MDBox display="flex" alignItems="center">
        <MDTypography display="block" variant="button" fontWeight="regular">
          {name}
        </MDTypography>
      </MDBox>
    </>
  );

  return {
    columns: [
      {
        Header: "Product Name",
        accessor: "product_name",
        align: "left",
        width: "40%",
        disableSortBy: true,
      },
      {
        Header: "Variant",
        accessor: "variant",
        align: "left",
        width: "15%",
        disableSortBy: true,
      },
      {
        Header: "Qty Left",
        accessor: "qty_left",
        align: "left",
        width: "15%",
        disableSortBy: true,
      },
      {
        Header: "Monthly Sales",
        accessor: "monthly_sales",
        align: "left",
        width: "15%",
        disableSortBy: true,
      },
    ],

    rows: [
      {
        item_name: <Item name="Item1" />,
        category: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            Category1
          </MDTypography>
        ),
        supplier_code: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            SPL1
          </MDTypography>
        ),
        sku_code: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            SKU1
          </MDTypography>
        ),
        buy_price: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            100000
          </MDTypography>
        ),
        sell_price: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            100000
          </MDTypography>
        ),
        status: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            active
          </MDTypography>
        ),
        updated_at: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            11-01-2022 13:37:50
          </MDTypography>
        ),
      },
      {
        item_name: <Item name="Item2" />,
        category: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            Category2
          </MDTypography>
        ),
        supplier_code: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            SPL2
          </MDTypography>
        ),
        sku_code: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            SKU2
          </MDTypography>
        ),
        buy_price: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            120000
          </MDTypography>
        ),
        sell_price: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            120000
          </MDTypography>
        ),
        status: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            active
          </MDTypography>
        ),
        updated_at: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            12-01-2022 09:11:03
          </MDTypography>
        ),
      },
    ],
  };
}

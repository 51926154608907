/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import { parseUrl } from "query-string";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import useAuthorizedRequest from "components/AuthorizedRequest";
import { DropzoneArea } from "react-mui-dropzone";
import { Alert, Snackbar } from "@mui/material";

function ProductEdit() {
  const authorizedReq = useAuthorizedRequest();
  const urlPage = parseUrl(window.location.href);
  const productId = urlPage.query.id;
  const navigate = useNavigate();

  // const trueState = true;
  const [productName, setProductName] = useState("");
  const [fetchedCategories, setFetchedCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [productStatus, setProductStatus] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [productData, setProductData] = useState({
    status: "",
    product_description: "",
    categories: [],
  });

  useEffect(() => {
    setProductData({
      status: productStatus,
      product_description: productDesc,
      categories: selectedCategories.map((x) => x.id),
    });
  }, [selectedCategories, productStatus, productDesc]);

  const [snackProps, setSnackProps] = useState({
    message: "",
    severity: "info",
    isOpen: false,
  });
  useEffect(() => {
    if (fetchedCategories.length) {
      authorizedReq
        .get(`${process.env.REACT_APP_API_BASE_URL}/product/${productId}`)
        .then((resProduct) => {
          setProductName(resProduct.data.product_name);
          setProductDesc(resProduct.data.product_description);
          setProductStatus(resProduct.data.status);
          setSelectedCategories(
            resProduct.data.categories.map((x) => ({
              id: x.id,
              label: x.category_name,
            }))
          );
          setFilesToUpload(resProduct.data.images);
        })
        .catch((errProduct) => {
          setSnackProps({
            isOpen: true,
            message: errProduct.response.data.message,
            severity: "error",
          });
        });
    }
  }, [fetchedCategories]);

  useEffect(() => {
    authorizedReq
      .get(`${process.env.REACT_APP_API_BASE_URL}/category`)
      .then((resCategory) => {
        setFetchedCategories(
          resCategory.data.data.map((x) => ({
            id: x.id,
            label: x.category_name,
          }))
        );
      })
      .catch((errCategory) => {
        setSnackProps({
          isOpen: true,
          message: errCategory.message,
          severity: "error",
        });
      });
    // console.log("blah");
  }, []);

  const handleStatusChange = (event) => {
    setProductStatus(event.target.value);
  };

  const handleSnackClose = () => {
    setSnackProps({ ...snackProps, isOpen: false });
  };

  const handleUpdateProduct = () => {
    // const productFormData = new FormData();
    // productFormData.append("product_name", productName);
    // productFormData.append("product_description", productDesc);
    // selectedCategories.forEach((x) => {
    //   productFormData.append("categories[]", x.id);
    // });
    // filesToUpload.forEach((x) => {
    //   productFormData.append("files[]", x);
    // });
    // productFormData.append("status", 1);

    authorizedReq
      .put(`${process.env.REACT_APP_API_BASE_URL}/product/${productId}`, productData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(() => {
        setSnackProps({
          isOpen: true,
          message: "Product Updated Successfully",
          severity: "success",
        });
        navigate(-1);
      })
      .catch((err) => {
        setSnackProps({
          isOpen: true,
          message: err.message,
          severity: "error",
        });
        // console.log(productFormData);
      });
  };

  // console.log(selectedCategories);

  return (
    <DashboardLayout>
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackProps.isOpen}
          onClose={handleSnackClose}
          autoHideDuration={6000}
        >
          <Alert onClose={handleSnackClose} severity={snackProps.severity} sx={{ width: "100%" }}>
            {snackProps.message}
          </Alert>
        </Snackbar>
      </div>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox
                  component="form"
                  role="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleUpdateProduct();
                  }}
                >
                  <MDBox mb={2}>
                    <MDTypography>Product Name</MDTypography>
                    <MDInput
                      type="input"
                      fullWidth
                      placeholder="input product name"
                      disabled
                      value={productName}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography>Product Description</MDTypography>
                    <MDInput
                      value={productDesc}
                      onChange={(e) => setProductDesc(e.target.value)}
                      type="input"
                      multiline
                      rows={3}
                      fullWidth
                      placeholder="Product Description"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography>Category Name</MDTypography>
                    <Autocomplete
                      multiple
                      id="categories-select"
                      options={fetchedCategories}
                      isOptionEqualToValue={(a, b) => a.id === b.id}
                      // getOptionLabel={(option) => option.label}
                      onChange={(e, d) => setSelectedCategories(d)}
                      value={selectedCategories}
                      filterSelectedOptions
                      renderInput={(params) => <MDInput {...params} placeholder="Category Name" />}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="text">Status</MDTypography>
                    <Select
                      labelId="status"
                      id="status"
                      value={productStatus}
                      label="status"
                      variant="standard"
                      // defaultValue={status}
                      onChange={handleStatusChange}
                      fullWidth
                    >
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="inactive">Inactive</MenuItem>
                    </Select>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography>Pictures</MDTypography>
                    {/* <DropzoneArea /> */}
                    <DropzoneArea
                      value={filesToUpload}
                      // showFileNames={trueState}
                      // showPreviews={trueState}
                      onChange={(f) => setFilesToUpload(f)}
                    />
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={handleUpdateProduct}
                    >
                      Update
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProductEdit;

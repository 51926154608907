/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { TableContainer, TextField, Icon } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

import InputAdornment from "@mui/material/InputAdornment";

// Material Dashboard 2 React example components
import DataTable from "examples/Tables/DataTable";

import { NumericFormat } from "react-number-format";

const tableHeaderByType = {
  to_supplier: [
    { Header: "Receipt", accessor: "receipt_id", width: "10%", disableSortBy: true },
    { Header: "Product Name", accessor: "product_name", width: "30%", disableSortBy: true },
    { Header: "Tags", accessor: "stock_tags", width: "15%", disableSortBy: true },
    { Header: "Qty Left", accessor: "qty_left", width: "5%", disableSortBy: true },
    { Header: "Qty to Return", accessor: "qty_to_return", width: "5%", disableSortBy: true },
    { Header: "Delete", accessor: "delete", width: "5%", disableSortBy: true },
  ],
  from_supplier: [
    { Header: "Receipt", accessor: "receipt_id", width: "10%", disableSortBy: true },
    { Header: "Product Name", accessor: "product_name", width: "30%", disableSortBy: true },
    { Header: "Tags", accessor: "stock_tags", width: "15%", disableSortBy: true },
    { Header: "Qty Returned", accessor: "returned_qty", width: "5%", disableSortBy: true },
    { Header: "Replace Qty", accessor: "replace_qty", width: "5%", disableSortBy: true },
    { Header: "Refund Qty", accessor: "refund_qty", width: "5%", disableSortBy: true },
    { Header: "", accessor: "qr_print", width: "5%", disableSortBy: true },
  ],
};

const simpleNumberFormat = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      valueIsNumericString
    />
  );
});

const ReceiptReturnTable = ({
  returnData,
  type,
  itemRemoveCallback,
  returnQtyChangedCallback,
  replaceRefundQtyCallback,
  qrPrintCallback,
  enableRowClick,
  extraFooterSlot,
}) => {
  const [selectedReturnItem, setSelectedReturnItem] = React.useState(null);
  const [replaceAndRefundValue, setReplaceAndRefundValue] = React.useState({
    replaceQty: 0,
    refundQty: 0,
  });
  return (
    <>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        // TransitionProps={{ onEntering: handleEntering }}
        open={selectedReturnItem !== null}
      >
        <DialogTitle>Update Return</DialogTitle>
        <DialogContent>
          <MDTypography variant="p">
            {`${"Product".padEnd(20)}: ${selectedReturnItem?.product_name}: ${
              selectedReturnItem?.stock_tags
            }
            `}
          </MDTypography>
          <br />
          <MDTypography variant="p">
            {`${"Receipt".padEnd(20)}: ${selectedReturnItem?.receipt_id}
            `}
          </MDTypography>
          <MDInput
            sx={{ marginTop: "1em" }}
            label="Returned Qty"
            fullWidth
            defaultValue={`${parseInt(selectedReturnItem?.returned_qty?.replace(" pcs", ""))}`}
            InputProps={{
              endAdornment: <InputAdornment position="end">pcs</InputAdornment>,
            }}
            disabled
          />
          <MDInput
            sx={{ marginTop: "1em" }}
            label="Replace Qty"
            fullWidth
            value={replaceAndRefundValue.replaceQty}
            onFocus={(e) => e.target.select()}
            onChange={(e) =>
              setReplaceAndRefundValue({
                ...replaceAndRefundValue,
                replaceQty: parseInt(e.target.value) || 0,
              })
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">pcs</InputAdornment>,
            }}
          />
          <MDInput
            sx={{ marginTop: "1em" }}
            label="Refund Qty"
            fullWidth
            value={replaceAndRefundValue.refundQty}
            onFocus={(e) => e.target.select()}
            onChange={(e) =>
              setReplaceAndRefundValue({
                ...replaceAndRefundValue,
                refundQty: parseInt(e.target.value) || 0,
              })
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">pcs</InputAdornment>,
            }}
          />
          <br />
          {parseInt(selectedReturnItem?.returned_qty?.replace(" pcs", "")) <
            replaceAndRefundValue.replaceQty + replaceAndRefundValue.refundQty && (
            <MDTypography
              sx={{ marginBotton: "1em" }}
              fontWeight="regular"
              color="error"
              variant="p"
            >
              Exceed Returned Qty!
            </MDTypography>
          )}
        </DialogContent>
        <DialogActions sx={{ marginTop: "-1em" }}>
          <MDButton
            autoFocus
            onClick={() => {
              setReplaceAndRefundValue({ replaceQty: 0, refundQty: 0 });
              setSelectedReturnItem(null);
            }}
          >
            Cancel
          </MDButton>
          <MDButton
            disabled={
              parseInt(selectedReturnItem?.returned_qty?.replace(" pcs", "")) <
              replaceAndRefundValue.replaceQty + replaceAndRefundValue.refundQty
            }
            onClick={() => {
              replaceRefundQtyCallback(
                selectedReturnItem.itemId,
                replaceAndRefundValue.replaceQty,
                replaceAndRefundValue.refundQty
              );
              setReplaceAndRefundValue({ replaceQty: 0, refundQty: 0 });
              setSelectedReturnItem(null);
            }}
          >
            Ok
          </MDButton>
        </DialogActions>
      </Dialog>
      <TableContainer sx={{ boxShadow: "none", paddingTop: "1rem" }}>
        <DataTable
          extraFooterSlot={extraFooterSlot}
          onRowClick={
            type === "from_supplier" && enableRowClick
              ? (r) => {
                  setReplaceAndRefundValue({
                    replaceQty: parseInt(r?.original?.replace_qty?.replace(" pcs", "")) || 0,
                    refundQty: parseInt(r?.original?.refund_qty?.replace(" pcs", "")) || 0,
                  });
                  setSelectedReturnItem(r.original);
                }
              : false
          }
          customStyles={{
            fontWeight: "400",
            color: "black !important",
          }}
          canSearch={type === "from_supplier"}
          table={{
            columns: tableHeaderByType[type],
            rows: returnData?.length
              ? returnData?.map((x) => ({
                  id: x.id || -1,
                  itemId: x.item_id || undefined,
                  receipt_id: x.receipt_id,
                  product_name: x.product_name,
                  stock_tags: x.stock_tags,

                  qty_left: (
                    <MDBox display="flex" alignItems="center">
                      <NumericFormat
                        value={x.qty_left || 0}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix=" pcs"
                      />
                    </MDBox>
                  ),
                  returned_qty: `${x.returned_qty || 0} pcs`,
                  // <MDBox display="flex" alignItems="center">
                  // <NumericFormat
                  //   value={x.returned_qty || 0}
                  //   displayType="text"
                  //   thousandSeparator="."
                  //   decimalSeparator=","
                  //   suffix=" pcs"
                  // />
                  // </MDBox>
                  qty_to_return: (
                    <MDBox display="flex" alignItems="center">
                      <TextField
                        variant="outlined"
                        size="small"
                        value={x.qty_to_return || 0}
                        fullWidth={false}
                        onFocus={(e) => e.target.select()}
                        onChange={
                          returnQtyChangedCallback &&
                          ((e) => returnQtyChangedCallback(x.item_id, e.target.value))
                        }
                        InputProps={{
                          inputComponent: simpleNumberFormat,
                        }}
                        error={x.qty_to_return > x.qty_left}
                        helperText={x.qty_to_return > x.qty_left ? "Qty Exceed!" : ""}
                      />
                    </MDBox>
                  ),
                  replace_qty: `${x.replace_qty || 0} pcs`,
                  refund_qty: `${x.refund_qty || 0} pcs`,
                  delete: (
                    <MDBox display="flex" alignItems="center">
                      <MDButton
                        iconOnly
                        color="error"
                        onClick={itemRemoveCallback && (() => itemRemoveCallback(x.item_id))}
                        variant="outlined"
                        sx={{ margin: "0.15em" }}
                      >
                        <Icon fontSize="medium" component="i" color="error">
                          delete
                        </Icon>
                      </MDButton>
                    </MDBox>
                  ),
                  qr_print: (
                    <MDButton
                      iconOnly
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        qrPrintCallback({
                          item_id: x.id,
                          product_name: x.product_name,
                          tags: x.stock_tags,
                          receipt_id: x.receipt_id,
                        });
                      }}
                      variant="outlined"
                    >
                      <Icon fontSize="small" component="i" color="error">
                        print
                      </Icon>
                    </MDButton>
                  ),
                }))
              : [],
          }}
          sx={{ paddingTop: "1rem", marginTop: "1rem" }}
        />
      </TableContainer>
    </>
  );
};

ReceiptReturnTable.defaultProps = {
  itemRemoveCallback: null,
  returnQtyChangedCallback: null,
  refundQtyChangedCallback: null,
  replaceQtyChangedCallback: null,
  isUpdating: null,
  enableRowClick: false,
  qrPrintCallback: false,
  extraFooterSlot: null,
};

ReceiptReturnTable.propTypes = {
  returnData: PropTypes.arrayOf(
    PropTypes.shape({
      receipt_id: PropTypes.string.isRequired,
      sales_item_id: PropTypes.number.isRequired,
      product_name: PropTypes.string.isRequired,
      tags: PropTypes.string.isRequired,
      qty: PropTypes.number.isRequired,
      returned_qty: PropTypes.number.isRequired,
      sell_price: PropTypes.number.isRequired,
      discount: PropTypes.number.isRequired,
      qty_to_return: PropTypes.number.isRequired,
    })
  ).isRequired,
  checkedStatusChangeCallback: PropTypes.func.isRequired,
  returnQtyChangedCallback: PropTypes.func,
  replaceQtyChangedCallback: PropTypes.func,
  refundQtyChangedCallback: PropTypes.func,
  itemRemoveCallback: PropTypes.func,
  qrPrintCallback: PropTypes.func,
  isUpdating: PropTypes.bool,
  enableRowClick: PropTypes.bool,
  extraFooterSlot: PropTypes.node,
};

export default ReceiptReturnTable;

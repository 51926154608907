/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import axios from "axios";

// react-router-dom components

/* @mui material components */
import Card from "@mui/material/Card";
import MDAlert from "components/MDAlert";

/* @mui icons */
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

/* Material Dashboard 2 React components */
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

/* Authentication layout components */
import BasicLayout from "layouts/authentication/components/BasicLayout";

/* Images */
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

/* Font Awesome */
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";

/* add icon prefix and icon name */
library.add(fas, faCircleNotch);

function Basic() {
  const navigate = useNavigate();
  /* alert content */
  const alertContent = (name) => (
    <MDTypography variant="body2" color="white">
      {name}
    </MDTypography>
  );
  /* this used for change state of Remember Me Checkbox */
  const [rememberMe, setRememberMe] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  /* this used for change state of button animation */
  const [loadingButton, setLoadingButton] = useState(false);
  /* loadingButton keeps true while triggered to keep the animation running */
  const handleSetLoadingButton = () => setLoadingButton(true);

  /* this used for change input type with checkbox show password */
  const [type, setType] = useState("password");
  const handleSetType = () => {
    /* change type to text if type is password before handleSetType active */
    if (type === "password") {
      setType("text");
      // setIcon(Visibility);
    } else {
      /* do the opposite */
      setType("password");
      // setIcon(VisibilityOff);
    }
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  // const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  // const [data, setData] = useState(null);
  const handleSubmit = (e) => {
    // setLoading(true);
    e.preventDefault();
    if (!email || !password) {
      setIsError(true);
      setErrorMessage("please fill email and password");
      return;
    }
    if (!email.includes("@")) {
      setIsError(true);
      setErrorMessage("email invalid");
      return;
    }
    handleSetLoadingButton();
    setIsError(false);
    const loginData = {
      email,
      password,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, loginData)
      .then((res) => {
        setEmail("");
        setPassword("");
        localStorage.setItem("access_token", res.data.access_token);
        setLoadingButton(false);
        navigate("/dashboard");
      })
      .catch(() => {
        setIsError(true);
        setErrorMessage("Sign in failed! Make sure the username and password is correct");
        setLoadingButton(false);
      });
  };

  return (
    <BasicLayout image={bgImage} display="flex">
      {isError && (
        <MDAlert color="error" dismissible mb={-1} align="center">
          {alertContent(errorMessage)}
        </MDAlert>
      )}
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={1} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                required
                type="email"
                label="Email"
                name="email"
                id="email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                required
                type={type}
                label="Password"
                name="password"
                id="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormControlLabel
                value={type}
                control={<Checkbox onClick={handleSetType} />}
                label="Peek Password"
                labelPlacement="end"
                onChange={handleSetType}
                sx={{ '"& .MuiSvgIcon-root"': { fontSize: 16 } }}
              />
              <FormControlLabel
                value={rememberMe}
                control={<Checkbox checked={rememberMe} onClick={handleSetRememberMe} />}
                label="Remember Me"
                labelPlacement="end"
                onChange={handleSetRememberMe}
                sx={{ '"& .MuiSvgIcon-root"': { fontSize: 16 } }}
              />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}></MDBox> */}
            <MDBox mt={1} mb={1}>
              {
                /* because of loadingButton initial value is false, below block of is rendered 
              start of block */
                !loadingButton && (
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleSetLoadingButton && handleSubmit}
                    onSubmit={(handleSetLoadingButton, handleSubmit)}
                    fullWidth
                    // href="./dashboard"
                    type="submit"
                  >
                    sign in
                  </MDButton>
                )
                /* end of block */
              }
              {
                /* this block of code is rendered while loadingButton value is true cause
              handleSetLoadingButton() is triggered 
              start of code */
                loadingButton && (
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleSetLoadingButton && handleSubmit}
                    onSubmit={handleSetLoadingButton && handleSubmit}
                    fullWidth
                    disabled
                  >
                    {/* spin is for animating icon to rotate */}
                    <FontAwesomeIcon icon="circle-notch" spin />
                  </MDButton>
                )
                /* end of code */
              }
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;

/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/id";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Alert, Snackbar, Autocomplete } from "@mui/material";
import _ from "lodash";
import useAuthorizedRequest from "components/AuthorizedRequest";
import FullScreenSpinner from "components/FullScreenSpinner";
import ReceiptReturnTable from "../../receipt-return-table";
import useStockQRPrint from "../../../../../stock/qr-print";

function ReturnFromSupplier() {
  const { receiptId: receiptIdUri } = useParams();
  const abortController = new AbortController();
  const authorizedReq = useAuthorizedRequest();
  moment.locale("id");
  const authorizedRequester = useAuthorizedRequest();
  const [returnData, setReturnData] = useState([]);
  const [transactionId, setTransactionId] = useState("");
  const [receiptId, setReceiptId] = useState("");

  const [qrPrintData, setQrPrintData] = useState({});
  const { ThingToPrint, handlePrint } = useStockQRPrint(qrPrintData);

  const [alertData, setAlertData] = useState({
    snackMessage: "",
    alertSeverity: "info",
    showAlert: false,
  });

  // const [fetchedTransactions, setFetchedTransactions] = useState([]);
  const [inputTransactionIdValue, setInputTransactionIdValue] = useState("");
  const [refetchToken, setRefetchToken] = useState(Date.now());

  const refetchTransactionData = async (transactionId) => {
    await authorizedRequester.get(`receipt-return/${transactionId}`).then((resp) => {
      setReturnData(resp?.data);
    });
  };
  const handleReplaceRefundQtyChange = (itemId, replaceQty, refundQty) => {
    setAlertData({ ...alertData, isSubmitting: true, showDialog: true });

    authorizedRequester
      .put(`receipt-return-item/${itemId}`, {
        replace_amount: replaceQty,
        refund_amount: refundQty,
      })
      .then((resp) => {
        setAlertData({
          ...alertData,
          showAlert: true,
          snackMessage: "Transaction Saved",
          alertSeverity: "success",
        });
      })
      .catch((err) => {
        setAlertData({
          ...alertData,
          showAlert: true,
          snackMessage: JSON.stringify(err.response.data),
          alertSeverity: "error",
        });
      });
    // TODO set alerts
    setAlertData({ ...alertData, isSubmitting: false, showDialog: false });
    setRefetchToken(Date.now());
  };

  const qrPrintCallback = (data) => {
    setQrPrintData(data);
  };

  /*   const getTransactions = (input) => {
    if (!input) return;
    authorizedReq
      .get(`/receipt-return?search=${encodeURIComponent(input)}`, {
        signal: abortController.signal,
      })
      .then((res) => {
        setFetchedTransactions(
          res.data.data.map((x) => ({
            id: x.id,
            transaction_id: x.transaction_id,
          }))
        );
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;
        setAlertData({
          ...alertData,
          showAlert: true,
          snackMessage: "Failed to get transactions",
          alertSeverity: "error",
        });
      });
  }; */

  /*   useEffect(async () => {
    if (transactionId) {
      await refetchTransactionData(transactionId?.id);
    }
  }, [transactionId, refetchToken]);

  useEffect(() => {
    if (inputTransactionIdValue === "") {
      setFetchedTransactions([]);
      return undefined;
    }

    _.debounce(() => {
      getTransactions(inputTransactionIdValue);
    }, 100)();

    return () => abortController.abort();
  }, [inputTransactionIdValue]); */

  const handleStatusChange = async (e) => {
    setAlertData({ ...alertData, isSubmitting: true, showAlert: false });
    const res = await authorizedReq
      .put(`receipt-return/${receiptIdUri}`, {
        status: "done",
      })
      .catch((e) => {
        setAlertData({
          ...alertData,
          showAlert: true,
          isSubmitting: false,
          alertSeverity: "error",
          snackMessage: JSON.stringify(e?.response?.data?.Message),
        });
      });

    if (res) {
      setAlertData({
        ...alertData,
        isSubmitting: false,
        showAlert: true,
        alertSeverity: "success",
        snackMessage: "Receipt Updated!",
      });
    }
    setRefetchToken(Date.now());
  };

  useEffect(() => {
    if (qrPrintData.product_name) {
      handlePrint();
      setQrPrintData({});
    }
  }, [qrPrintData, ThingToPrint]);

  useEffect(async () => {
    await refetchTransactionData(receiptIdUri);
  }, [refetchToken]);

  return (
    <DashboardLayout>
      {/* This is hidden on purpose } */}
      <div style={{ display: "none" }}>{ThingToPrint}</div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={alertData.showAlert}
        onClose={() => setAlertData({ ...alertData, showAlert: false })}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setAlertData({ ...alertData, showAlert: false })}
          severity={alertData.alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertData.snackMessage}
        </Alert>
      </Snackbar>
      <FullScreenSpinner showOverlay={alertData.isSubmitting} />
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item xs={12} lg={9}>
                    <MDTypography variant="h6" color="white">
                      Return From Supplier
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox display="flex" mb={2} mt={2}>
                  <MDBox sx={{ width: "40%" }}>
                    <MDTypography variant="text" sx={{ width: "50%" }}>
                      Receipt Code
                    </MDTypography>
                  </MDBox>
                  <MDBox sx={{ width: "40%" }}>
                    : <MDTypography variant="text">{returnData?.transaction_id || ""}</MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox display="flex" mb={2} mt={2}>
                  <MDBox sx={{ width: "40%" }}>
                    <MDTypography variant="text">Receipt Date</MDTypography>
                  </MDBox>
                  <MDBox sx={{ width: "40%" }}>
                    :{" "}
                    <MDTypography variant="text">
                      {moment(returnData?.created_at).format("DD-MM-yyyy HH:mm:ss") || ""}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox display="flex" mb={2} mt={2}>
                  <MDBox sx={{ width: "40%" }}>
                    <MDTypography variant="text">Total Items</MDTypography>
                  </MDBox>
                  <MDBox sx={{ width: "40%" }}>
                    <MDTypography variant="text">
                      {`: ${returnData?.items?.reduce((a, b) => a + b.returned_qty, 0)} pcs` || ""}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox display="flex" mb={2} mt={2}>
                  <MDBox sx={{ width: "40%" }}>
                    <MDTypography variant="text">Status</MDTypography>
                  </MDBox>
                  <MDBox sx={{ width: "40%" }}>
                    : <MDTypography variant="text">{returnData?.status || ""}</MDTypography>
                  </MDBox>
                </MDBox>
                {returnData?.status === "pending" ? (
                  <MDBox display="flex" mb={2} mt={2}>
                    <MDBox sx={{ width: "40%" }}>
                      <MDTypography variant="text">Update</MDTypography>
                    </MDBox>
                    <MDBox sx={{ width: "60%" }}>
                      <MDButton
                        disabled={returnData?.status === "done"}
                        onClick={handleStatusChange}
                        variant="contained"
                        color="info"
                      >
                        <MDTypography variant="text" color="white">
                          {" "}
                          Mark as done.
                        </MDTypography>
                      </MDButton>
                    </MDBox>
                  </MDBox>
                ) : (
                  <MDBox display="flex" mb={2} mt={2}>
                    <MDBox sx={{ width: "40%" }}>
                      <MDTypography variant="text">Done at</MDTypography>
                    </MDBox>
                    <MDBox sx={{ width: "60%" }}>
                      :{" "}
                      <MDTypography variant="text">
                        {moment(returnData?.updated_at).format("DD-MM-yyyy HH:mm:ss") || ""}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                )}
              </MDBox>

              <MDBox>
                {ReceiptReturnTable({
                  returnData: returnData?.items,
                  type: "from_supplier",
                  replaceRefundQtyCallback: handleReplaceRefundQtyChange,
                  enableRowClick: returnData.status === "pending",
                  qrPrintCallback,
                  extraFooterSlot: returnData?.items && (
                    <>
                      <MDBox
                        display="flex"
                        alignContent="flex-end"
                        flexDirection="row"
                        flexWrap="wrap"
                        alignItems="flex-end"
                        sx={{ paddingY: ".5em" }}
                      >
                        <MDTypography
                          sx={{ marginLeft: "80%", fontSize: "0.7em", flex: "0 0 100%" }}
                          display="flex"
                          variant="text"
                          fontWeight="regular"
                        >
                          Total Returned:{" "}
                          {returnData?.items?.reduce((a, b) => a + b.returned_qty, 0)} pcs
                        </MDTypography>
                        <MDTypography
                          sx={{ marginLeft: "80%", fontSize: "0.7em", flex: "0 0 100%" }}
                          display="flex"
                          variant="text"
                          fontWeight="regular"
                        >
                          Total Replaced:{" "}
                          {returnData?.items?.reduce((a, b) => a + b.replace_qty, 0)} pcs
                        </MDTypography>
                        <MDTypography
                          sx={{ marginLeft: "80%", fontSize: "0.7em", flex: "0 0 100%" }}
                          display="flex"
                          variant="text"
                          fontWeight="regular"
                        >
                          Total Refund: {returnData?.items?.reduce((a, b) => a + b.refund_qty, 0)}{" "}
                          pcs
                        </MDTypography>
                      </MDBox>
                    </>
                  ),
                })}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export default ReturnFromSupplier;

/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
/* eslint-disable */
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Checkbox, TableContainer, TextField } from "@mui/material";

// Material Dashboard 2 React example components
import DataTable from "examples/Tables/DataTable";

import { NumericFormat } from "react-number-format";

function SalesReturnTable({ returnData, checkedStatusChangeCallback, returnQtyChangedCallback }) {
  return (
    <TableContainer sx={{ boxShadow: "none", paddingTop: "1rem" }}>
      <DataTable
        table={{
          columns: [
            { Header: "", accessor: "checked", width: "3%", disableSortBy: true },
            { Header: "Product Name", accessor: "product_name", width: "30%", disableSortBy: true },
            { Header: "Tags", accessor: "tags", width: "15%", disableSortBy: true },
            { Header: "Bought Qty", accessor: "bought_qty", width: "5%", disableSortBy: true },
            { Header: "Returned Qty", accessor: "returned_qty", width: "5%", disableSortBy: true },
            { Header: "Sell Price", accessor: "sell_price", width: "15%", disableSortBy: true },
            { Header: "Discount", accessor: "discount", width: "10%", disableSortBy: true },
            {
              Header: "Qty to Return",
              accessor: "qty_to_return",
              width: "5%",
              disableSortBy: true,
            },
          ],
          rows: returnData?.map((x) => ({
            checked: (
              <MDBox display="flex" alignItems="center">
                <Checkbox
                  checked={x.checked}
                  onChange={(e) => checkedStatusChangeCallback(x.sales_item_id, e.target.checked)}
                  //   color="primary"
                />
              </MDBox>
            ),
            product_name: (
              <MDBox display="flex" alignItems="center">
                <MDTypography display="block" variant="text" fontWeight="regular">
                  {x.product_name}
                </MDTypography>
              </MDBox>
            ),
            tags: (
              <MDBox display="flex" alignItems="center">
                <MDTypography display="block" variant="text" fontWeight="regular">
                  {x.tags}
                </MDTypography>
              </MDBox>
            ),
            bought_qty: (
              <MDBox display="flex" alignItems="center">
                <NumericFormat
                  value={x.qty}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" pcs"
                />
              </MDBox>
            ),
            returned_qty: (
              <MDBox display="flex" alignItems="center">
                <NumericFormat
                  value={x.returned_qty}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" pcs"
                />
              </MDBox>
            ),
            sell_price: (
              <MDBox display="flex" alignItems="center">
                <NumericFormat
                  value={x.sell_price}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="Rp. "
                />
              </MDBox>
            ),
            discount: (
              <MDBox display="flex" alignItems="center">
                <NumericFormat
                  value={x.discount}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="Rp. "
                />
              </MDBox>
            ),
            qty_to_return: (
              <MDBox display="flex" alignItems="center">
                <TextField
                  variant="outlined"
                  size="small"
                  value={x.qty_to_return}
                  fullWidth={false}
                  onChange={(e) => returnQtyChangedCallback(x.sales_item_id, e.target.value)}
                  error={x.qty_to_return + x.returned_qty > x.qty}
                  helperText={(x.qty_to_return + x.returned_qty > x.qty) ? "Qty Exceed!" : ""}
                />
              </MDBox>
            ),
          })),
        }}
        sx={{ paddingTop: "1rem", marginTop: "1rem" }}
      />
    </TableContainer>
  );
}

SalesReturnTable.propTypes = {
  returnData: PropTypes.arrayOf(
    PropTypes.shape({
      sales_item_id: PropTypes.number.isRequired,
      product_name: PropTypes.string.isRequired,
      tags: PropTypes.string.isRequired,
      qty: PropTypes.number.isRequired,
      returned_qty: PropTypes.number.isRequired,
      sell_price: PropTypes.number.isRequired,
      discount: PropTypes.number.isRequired,
      qty_to_return: PropTypes.number.isRequired,
    })
  ).isRequired,
  checkedStatusChangeCallback: PropTypes.func.isRequired,
  returnQtyChangedCallback: PropTypes.func.isRequired,
};

export default SalesReturnTable;

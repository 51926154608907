/* eslint-disable react/prop-types */

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import moment from "moment";
// import InputAdornment from "@mui/material/InputAdornment";
import { NumericFormat } from "react-number-format";

export const stockReceiptRow = ({
  // eslint-disable-next-line no-unused-vars
  id,
  stock_qty: stockQty,
  receipt_date: receiptDate,
  receipt_name: receiptName,
}) => ({
  id: {
    value: id,
    hidden: true,
  },
  receipt_name: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        {receiptName}
      </MDTypography>
    </MDBox>
  ),
  receipt_date: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        {receiptDate}
      </MDTypography>
    </MDBox>
  ),
  stock_qty: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        {stockQty}
      </MDTypography>
    </MDBox>
  ),
});

export const StockDataRow = ({
  id,
  product_name: stockProductName,
  // buy_price: buyPrice,
  sell_price: sellPrice,
  qty: stockQty,
  tags: stockTags,
  status: stockStatus,
  updated_at: updatedAt,
  onRowDelete,
  onRowEdit,
  // onRowPrint,
}) => ({
  id: {
    value: id,
    hidden: true,
  },
  stock_name: {
    value: stockProductName,
    hidden: true,
  },
  product_name: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="text" fontWeight="regular">
        {stockProductName}
      </MDTypography>
    </MDBox>
  ),
  sell_price: (
    <MDBox display="flex" alignItems="center">
      <NumericFormat
        value={sellPrice}
        displayType="text"
        thousandSeparator="."
        decimalSeparator=","
        prefix="Rp "
      />
    </MDBox>
  ),
  qty: (
    <MDBox display="flex" alignItems="center">
      <NumericFormat
        value={stockQty}
        displayType="text"
        thousandSeparator="."
        decimalSeparator=","
        // prefix="Rp"
        suffix=" pcs"
      />
    </MDBox>
  ),
  tags: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="text" fontWeight="regular">
        {stockTags}
      </MDTypography>
    </MDBox>
  ),
  status: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="text" fontWeight="regular">
        {stockStatus}
      </MDTypography>
    </MDBox>
  ),
  updated_at: (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="text" fontWeight="regular">
        {moment(updatedAt).format("DD-MM-YYYY HH:mm:ss")}
      </MDTypography>
    </MDBox>
  ),
  action: (
    <MDBox>
      <MDButton
        iconOnly
        color="info"
        onClick={
          onRowEdit &&
          ((e) => {
            e.stopPropagation();
            onRowEdit(id);
          })
        }
        variant="outlined"
      >
        <Icon fontSize="small" component="i" color="info">
          edit
        </Icon>
      </MDButton>
      <MDButton
        iconOnly
        color="error"
        onClick={
          onRowDelete &&
          ((e) => {
            e.stopPropagation();
            onRowDelete(id, stockProductName);
          })
        }
        variant="outlined"
      >
        <Icon fontSize="small" component="i" color="error">
          delete
        </Icon>
      </MDButton>
    </MDBox>
  ),
});

export default function data() {
  const Item = ({ name }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography display="block" variant="button" fontWeight="regular">
        {name}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Item name", accessor: "product_name", align: "left", width: "30%" },
      // { Header: "Buy Price", accessor: "buy_price", align: "left", width: "10%" },
      { Header: "Sell Price", accessor: "sell_price", align: "left", width: "20%" },
      { Header: "Quantity", accessor: "qty", align: "left", width: "10%" },
      { Header: "Tags", accessor: "tags", align: "left", width: "10%" },
      { Header: "Status", accessor: "status", align: "left", width: "10%" },
      { Header: "Updated At", accessor: "updated_at", align: "left", width: "10%" },
      { Header: "Action", accessor: "action", align: "left", width: "10%" },
    ],

    rows: [
      {
        item_name: <Item name="Item1" />,
        category: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            Category1
          </MDTypography>
        ),
        supplier_code: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            SPL1
          </MDTypography>
        ),
        sku_code: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            SKU1
          </MDTypography>
        ),
        buy_price: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            100000
          </MDTypography>
        ),
        sell_price: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            100000
          </MDTypography>
        ),
        status: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            active
          </MDTypography>
        ),
        updated_at: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            11-01-2022 13:37:50
          </MDTypography>
        ),
        action: (
          <MDBox>
            <MDButton iconOnly color="info" href="./stock/stock-edit" variant="outlined">
              <Icon fontSize="small" component="i" color="info">
                edit
              </Icon>
            </MDButton>
            <MDButton iconOnly color="error" href="./stock/stock-edit" variant="outlined">
              <Icon fontSize="small" component="i" color="error">
                delete
              </Icon>
            </MDButton>
          </MDBox>
        ),
      },
      {
        item_name: <Item name="Item2" />,
        category: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            Category2
          </MDTypography>
        ),
        supplier_code: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            SPL2
          </MDTypography>
        ),
        sku_code: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            SKU2
          </MDTypography>
        ),
        buy_price: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            120000
          </MDTypography>
        ),
        sell_price: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            120000
          </MDTypography>
        ),
        status: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            active
          </MDTypography>
        ),
        updated_at: (
          <MDTypography variant="text" color="text" fontWeight="regular">
            12-01-2022 09:11:03
          </MDTypography>
        ),
        action: (
          <MDBox>
            <MDButton iconOnly color="info" href="./stock/stock-edit" variant="outlined">
              <Icon fontSize="small" component="i" color="info">
                edit
              </Icon>
            </MDButton>
            <MDButton iconOnly color="error" href="./stock/stock-edit" variant="outlined">
              <Icon fontSize="small" component="i" color="error">
                delete
              </Icon>
            </MDButton>
          </MDBox>
        ),
      },
    ],
  };
}
